import { Eye, Pencil } from 'lucide-react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROLES } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { APP_ROUTES, EPC_CONTRACTORS_ROUTES } from 'src/navigations/modules'
import ToolTip from 'src/shadcn/components/custom/ToolTip'
import { Button } from 'src/shadcn/components/ui'

type Props = {
    row: any
}

const Actions = ({ row }: Props) => {

    const navigate = useNavigate()
    const { currentUser } = useAuth()


    const editPath = useMemo(() => {
        if (currentUser?.role === ROLES.EPC_CONTRACTOR) {
            return "/" + EPC_CONTRACTORS_ROUTES.projects.subRoutes.edit.fullRoute + "/" + row?._id
        }
        else {
            return "/" + APP_ROUTES.projects.subRoutes.edit.fullRoute+ "/" + row?._id
        }
    }, [currentUser])



    const viewPath = useMemo(() => {
        if (currentUser?.role === ROLES.EPC_CONTRACTOR) {
            return "/" +EPC_CONTRACTORS_ROUTES.projects.subRoutes.view.fullRoute  + "/" + row?._id
        }
        else {
            return "/" + APP_ROUTES.projects.subRoutes.view.fullRoute+ "/" + row?._id
        }
    }, [currentUser])


    return (
        <div className='flex space-x-2'>
            {row.status === "New" ? <ToolTip tooltipContent='Edit project'>
                <Button size={"sm"} variant={'primaryIconBtn'} onClick={() => navigate("/" + EPC_CONTRACTORS_ROUTES.projects.subRoutes.edit.fullRoute + "/" + row._id)} >
                    <Pencil size="16" />
                </Button>
            </ToolTip> : null}
            <ToolTip tooltipContent='View project'>
                <Button size={"sm"} variant={'primaryIconBtn'} onClick={() => navigate("/" + EPC_CONTRACTORS_ROUTES.projects.subRoutes.view.fullRoute + "/" + row._id)} >
                    <Eye size="16" />
                </Button>
            </ToolTip>
        </div>
    )
}

export default Actions