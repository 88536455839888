import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import WalletDetailPage from './WalletDetailPage'
import WalletMain from './WalletsMain/WalletMain'


type Props = {}

const WalletsRoutePage = (props: Props) => {
  const PROJECTS_ROUTES = APP_ROUTES.wallets.subRoutes

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<WalletMain/>} />
        {/* <Route path={PROJECTS_ROUTES.create.route} element={<ManufacturerCreateMain />} /> */}
        <Route path={PROJECTS_ROUTES.view.route + "/:id"} element={<WalletDetailPage/>} />
        {/* <Route path={PROJECTS_ROUTES.view.route + "/:id"} element={<ManufacturerViewMain />} /> */}
        <Route path={"*"} element={<Navigate to={"/" + PROJECTS_ROUTES.all.fullRoute} />} />
      </Route>
    </Routes>
  )
}

export default WalletsRoutePage