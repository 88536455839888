import { Link, Outlet, useLocation } from 'react-router-dom'
import { cn, ICON_PNG_OCT_LOGO } from 'src/helpers'
import { MAIN_ROUTES } from 'src/navigations/modules'

type Props = {}

const AuthLayoutMain = (props: Props) => {

    const { pathname } = useLocation()

    console.log({ pathname });


    return (
        <div className='relative'>
            <div className='w-100 h-[100vh] bg-[#FFF7E8] '></div>
            <div className='absolute left-0 right-0 top-[10vh] m-auto  w-[40vw]  bg-white  rounded-xl shadow-lg '>
                <div className='flex flex-col w-100  justify-center p-4 '>
                    <div className='py-4 flex justify-center '> <img src={ICON_PNG_OCT_LOGO} style={{ height: "50px", width: "160px" }} /></div>
                    {
                        pathname === MAIN_ROUTES.auth_login || pathname === MAIN_ROUTES.auth_signup ?
                            <div className='flex mb-4 '>
                                <div className={cn('flex-1 cursor-pointer text-center py-2 border-t-0 border-l-0 border-r-0 text-sm  ', { "border-b-2  border-primary text-primary": pathname.includes(MAIN_ROUTES.auth_login) })}>
                                    <Link to={MAIN_ROUTES.login}><div className='w-100 h-100 '>Login</div> </Link>
                                </div>
                                <div className={cn('flex-1 cursor-pointer text-center py-2  border-t-0 border-l-0 border-r-0 text-sm ', { "border-b-2  border-primary text-primary": pathname.includes(MAIN_ROUTES.auth_signup) })}>
                                    <Link to={MAIN_ROUTES.auth_signup}><div className='w-100 h-100'>Sign Up</div></Link>
                                </div>
                            </div>
                            : null
                    }

                    <div className='mb-4'><Outlet /></div>
                </div>
            </div>
        </div>
    )
}

export default AuthLayoutMain