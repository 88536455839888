import { useFormik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { catchAsync, ROLES, successTheme } from 'src/helpers'
import { MAIN_ROUTES } from 'src/navigations/modules'
import { loginRequestApi } from 'src/services/requests/Auth'
import { ILoginResponse } from 'src/services/requests/Auth/modules'
import { epcViewProfileByIDApiRequest } from 'src/services/requests/EPC/profile'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { useAuth } from './Core'
import { LOGIN_FORM_FIELDS, login_initialValues, loginFormValidationSchema } from './modules'

type Props = {}

const Login = (props: Props) => {

    const navigate = useNavigate()
    const { setCurrentUser, saveAuth, currentUser } = useAuth()
    const [isLoading, setisLoading] = useState(false)
    const [currentUserDetails, setcurrentUserDetails] = useState<any>()

    const formik = useFormik({
        initialValues: login_initialValues,
        validationSchema: loginFormValidationSchema,
        onSubmit: (values, { setSubmitting }) => {
            // Handle form submission here
            handleLoginForm()
        },
    });

    console.log({ values: formik.values, error: formik.errors })

    const handleLoginForm = catchAsync(
        async () => {
            setisLoading(true)
            return await loginRequestApi(formik.values);
        },
        async (result: ILoginResponse) => {
            if (result.data.accessToken) {
                const { accessToken, _id, email, role } = result.data

                saveAuth({
                    api_token: accessToken,
                    user_id: _id
                })


                toast.success("Login Successfully", { style: successTheme })
                setisLoading(false)
                if (role === ROLES.EPC_CONTRACTOR) {
                    const profileDetails = await epcViewProfileByIDApiRequest(_id)
                    if(profileDetails?.status === 200){
                        console.log({profileDetails});
                        
                        setCurrentUser({
                            email: email,
                            id: _id,
                            name: "",
                            role: role,
                            access_token: accessToken,
                            profileDetails: profileDetails?.data
                        })
                    }

                    navigate("/")
                }
                else {
                    setCurrentUser({
                        email: email,
                        id: _id,
                        name: "",
                        role: role,
                        access_token: accessToken,
                        profileDetails: null
                    })

                    navigate("/")
                }

            }

            console.log('Login successful:', result);
        },
        () => {
            setisLoading(false)
        }
    );



    return (
        <div className='mx-16 space-y-4'>
            <div className='space-y-2'>
                <div className='uppercase text-xl font-bold text-center text-primary'>Admin Login </div>
                <div className=' text-center text-sm'>If you already have an account, please sign in. </div>
            </div>
            <form className='space-y-2' onSubmit={formik.handleSubmit}>
                <div className='space-y-2'>
                    {
                        LOGIN_FORM_FIELDS.map((field: any) => {
                            return <RenderFormComponents key={`login-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} />
                        })
                    }
                </div>
                <div className='text-sm text-end text-blue-600'><span className='cursor-pointer' onClick={() => navigate(MAIN_ROUTES.auth_forgot_password)}>Forgot Password</span> </div>
                <div><RoundedButton type='submit' className='w-full' isLoading={isLoading} >Login</RoundedButton></div>
            </form>
        </div>
    )
}

export default Login