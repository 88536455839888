import { useEffect, useMemo, useState } from 'react'
import AnalysisTiles from 'src/modules/Dashboard/AnalysisTiles'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponse } from './core/QueryResponseProvider'
import { ANALYSIS_DATA, SCHEDULE_CALL_REQUESTS_ANALYSIS_DATA } from './modules'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { fetchProjectInterestSummaryApiRequest, fetchProjectServicesSummaryApiRequest, fetchScheduleCallRequestSummaryApiRequest } from 'src/services/requests/ProjectEnquiry'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import { Button, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/shadcn/components/ui'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES, MAIN_ROUTES } from 'src/navigations/modules'


type Props = {}

const Main = () => {
  const { response }: any = useQueryResponse()
  const [scheduleCallRequestsSummary, setscheduleCallRequestsSummary] = useState<any>(SCHEDULE_CALL_REQUESTS_ANALYSIS_DATA)
  const [projectData, setProjectData] = useState([])
  const [servicesData, setservicesData] = useState([])
  const counts = useMemo(() => response && response?.count, [response])
  const navigate = useNavigate()

  useEffect(() => {
    handleFetchSchedleCallRequestsSummary()
    handleFetchProjectAnalysisRequestsSummary()
    handleFetchServiceAnalysisRequestsSummary()
    return () => { }
  }, [])


  const analysisData = useMemo(() => counts && Object.keys(counts).length > 0 ? [
    {
      title: "Total Enquiry Requests",
      value: Object.values(counts).reduce((prev: any, curr: any) => prev + curr, 0),
      dataKey: "",
      prefix: "#",
      icon: null
    },
    ...ANALYSIS_DATA.map((d: any) => {
      return {
        ...d,
        value: counts[d.dataKey as keyof object] || 0
      }
    })
  ] : ANALYSIS_DATA, [ANALYSIS_DATA, counts])

  const handleFetchSchedleCallRequestsSummary = catchAsync(async () => {
    return fetchScheduleCallRequestSummaryApiRequest()
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      let data = {
        PENDING: result?.data?.find((d: any) => d?._id == 'PENDING')?.count,
        COMPLETED: result?.data?.find((d: any) => d?._id == 'COMPLETED')?.count,
        REJECTED: result?.data?.find((d: any) => d?._id == 'REJECTED')?.count
      }
      setscheduleCallRequestsSummary([
        ...SCHEDULE_CALL_REQUESTS_ANALYSIS_DATA.map((d: any) => {
          return {
            ...d,
            value: data[d.dataKey as keyof object] || 0
          }
        })
      ])
    }
  }, () => { })


  const handleFetchProjectAnalysisRequestsSummary = catchAsync(async () => {
    return fetchProjectInterestSummaryApiRequest()
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      setProjectData(result?.data && result.data?.length > 0 ?
        result?.data?.map((d: any, indx: any) => {
          return {
            srNo: (indx + 1),
            projectId: d?._id,
            count: d?.count,
          }
        })
        : [])
    }
  }, () => { })


  const handleFetchServiceAnalysisRequestsSummary = catchAsync(async () => {
    return fetchProjectServicesSummaryApiRequest()
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      setservicesData(result?.data && result.data?.length > 0 ?
        result?.data?.map((d: any, indx: any) => {
          return {
            title: d?._id,
            value: d?.count,
            dataKey: "",
            prefix: "#",
            icon: null
          }
        })
        : [])
    }
  }, () => { })



  return (
    <ScrollArea className='h-[90vh]'>
      <div className='p-4'>
        {/* <div className={`mt-4 grid gap-4 md:grid-cols-3 lg:grid-cols-3`}>
          {
            analysisData.map((d: any) => {
              return <AnalysisTiles {...d} />
            })
          }
        </div> */}
        <div className='my-2 flex justify-between'>
          <div className='font-bold text-gray-500'>Schedule Call Request Analysis</div>
          <Button onClick={() => navigate("/" + APP_ROUTES.projectEnquiry.subRoutes.scheduleCallsRequests.fullRoute)} variant={'iconBtn'}>Show Details</Button>
        </div>
        <div className={`mt-4 grid gap-4 md:grid-cols-4 lg:grid-cols-3`}>
          {
            scheduleCallRequestsSummary.map((d: any) => {
              return <AnalysisTiles {...d} />
            })
          }
        </div>
        <div className='my-2 flex justify-between'>
          <div className='font-bold text-gray-500'>Project Services Analysis</div>
          <Button onClick={() => navigate("/" + APP_ROUTES.projectEnquiry.subRoutes.servicesRequest.fullRoute)} variant={'iconBtn'}>Show Details</Button>
        </div>
        <div className={`mt-4 grid gap-4 md:grid-cols-4 lg:grid-cols-3`}>
          {
            servicesData && servicesData?.length > 0 ? servicesData.map((d: any) => {
              return <AnalysisTiles {...d} />
            }) : <div>No Services Found</div>
          }
        </div>

        {/* <div className='my-2 font-bold text-gray-500'>Project Interest Analysis</div>
        <div className={`mt-4 grid gap-4 md:grid-cols-3 lg:grid-cols-3`}>
          <ScrollArea className='h-[400px] w-full'>
            <Table className='w-full'>
              <TableHeader>
                <TableRow>
                  <TableHead>Sr.No.</TableHead>
                  <TableHead>PROJECT ID</TableHead>
                  <TableHead>Counts</TableHead>
                  <TableHead>Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {
                  projectData && projectData.length > 0 ? projectData.map((d: any) => {
                    return <TableRow>
                      <TableCell>{d?.srNo}</TableCell>
                      <TableCell>{d?.projectId}</TableCell>
                      <TableCell>{d?.count}</TableCell>
                      <TableCell>
                        <Button variant={'outlinePrimary'} size={'xs'} onClick={() => navigate("/" + APP_ROUTES.projectEnquiry.subRoutes.projectInterestRequests.fullRoute + "/" + d?.projectId)} >Details</Button>
                      </TableCell>
                    </TableRow>
                  }) : <></>
                }
              </TableBody>
            </Table>
          </ScrollArea>
        </div> */}
      </div>
    </ScrollArea>
  )
}

const ProjectEnquiryDashboard = (props: Props) => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <Main />
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}


export default ProjectEnquiryDashboard