
import { useEffect, useMemo } from 'react'
import { columns } from 'src/modules/ProjectEnquiry/ProjectInterestsRequests/components/columns'
import { DataTable } from 'src/modules/ProjectEnquiry/ProjectInterestsRequests/components/data-table'
import { QueryRequestProvider, useQueryRequest } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponseData } from './core/QueryResponseProvider'
import { useParams } from 'react-router-dom'

type Props = {

}

const Main = () => {
    const apiResp = useQueryResponseData()
    const { state, updateState } = useQueryRequest()
    const { id } = useParams()

    useEffect(() => {
        if (id) {
            updateState({
                filter: {
                    project_id: id
                }
            })

        }
        return () => { }
    }, [id])


    const tableData = useMemo(() => apiResp.map((d: any, indx: number) => {
        return {
            srNo: indx + 1,
            ...d,
        }
    }), [apiResp])

    return (
        <div className='p-4'>
            <DataTable columns={columns} data={tableData || []} />
        </div>
    )
}

const ProjectInterestRequestMain = (props: Props) => {
    return (
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    )
}

export default ProjectInterestRequestMain