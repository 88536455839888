import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/Orders";

export function allOrdersRequest(query: string): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.ALL_ORDERS}`).then((d: AxiosResponse) => d.data)
}


export async function getOrderDetailByIDApiRequest(id: any): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.GET_DETAILS_ORDER_BY_ID}/${id}`).then((d: AxiosResponse) => d.data);
}


export async function getOrderSummaryApiRequest(query: any): Promise<AxiosResponse> {
    query = query ? `?${query}` : ""
    return axiosInstance.get(`${API.ORDERS_SUMMARY}${query}`).then((d: AxiosResponse) => d.data);
}


export async function fetchOrderDetailByIDApiRequest(id: any): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.ORDER_DETAILS}/${id}`).then((d: AxiosResponse) => d.data);
}

