import { ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import ProjectStatus from "src/modules/Projects/AllProjects/Table/components/ProjectStatus";
import { Task } from "../data/schema";
import Actions from "./Actions";
import { DataTableColumnHeader } from "./data-table-column-header";


export const columns: ColumnDef<Task>[] = [
  {
    accessorKey: "srNo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sr.No. (#)" />
    ),
  },
  {
    accessorKey: "salesRepresentativeName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sales Executive Name" />
    ),
  },
  {
    accessorKey: "project_id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Project ID(#)" />
    ),
  },
  {
    accessorKey: "projectType",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Project Type" />
    ),
  },
  {
    accessorKey: "systemType",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="System Type" />
    ),
  },
  {
    accessorKey: "unitRequiredPerMonth",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Unit Required (per Month)" />
    ),
  },
  {
    accessorKey: "unitConsumptionPerDay",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Unit Consumption (per Day)" />
    ),
  },
  {
    accessorKey: "tariffRate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Tariff Rate (%)" />
    ),
  },
  {
    accessorKey: "availableSpaceSQ",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Available Space" />
    ),
  },
  {
    accessorKey: "contactPersonName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Contact Person Name" />
    ),
  },
  {
    accessorKey: "contactPersonNumber",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Contact Person Number" />
    ),
  },
  {
    accessorKey: "country",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Country" />
    ),
  },
  {
    accessorKey: "city",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="City" />
    ),
  },
  {
    accessorKey: "state",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="State" />
    ),
  },
  // {
  //   accessorKey: "address",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Address" />
  //   ),
  // },
  {
    accessorKey: "pinCode",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Pin Code" />
    ),
  },
  {
    id: "agreement",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Agreement" />
    ),
    cell({ row: { original : {agreementUrl} } }) {
      return agreementUrl && typeof agreementUrl === "string" ? <Link to={agreementUrl} className="text-blue-600 underline underline-offset-2" target="_blank" >View</Link> : null
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell({ row: { original } }) {
      return <ProjectStatus row={original} />
    },
  },
  {
    id: "Actions",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actions" />
    ),
    cell({ row: { original } }) {
      return <Actions row={original} />
    },
  },

];
