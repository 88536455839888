import { ColumnDef } from "@tanstack/react-table";
import { Task } from "../data/schema";
import Actions from "./Actions";
import { DataTableColumnHeader } from "./data-table-column-header";

export const columns: ColumnDef<Task>[] = [
  {
    accessorKey: "srNo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sr.No. (#)" />
    ),
  },
  {
    accessorKey: "category",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Category" />
    ),
  },
  {
    accessorKey: "productType",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Product Type" />
    ),
  },
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Product Name" />
    ),
  },
  {
    accessorKey: "price",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Price (₹)" />
    ),
  },
  {
    accessorKey: "total_quantity",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Total Quantity" />
    ),
  },
  {
    accessorKey: "current_quantity",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Current Quantity" />
    ),
  },
  {
    id: "Actions",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actions" />
    ),
    cell({ row: { original } }) {
      return <Actions row={original} />
    },
  },
];
