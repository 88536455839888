import { ColumnDef } from "@tanstack/react-table";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";

import { Rating } from '@smastrom/react-rating';


export const columns: ColumnDef<Task>[] = [
  {
    accessorKey: "srNo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sr.No. (#)" />
    ),
  },
  {
    accessorKey: "contractorName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="EPC Contractor Name" />
    ),
  },
  {
    accessorKey: "companyLogo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Contractor Logo" />
    ),
    cell: ({ row }) => (
      <img src={row.original.companyLogo} style={{ height: "40px" }} alt="Contractor Logo" />
    ),
  },
  {
    accessorKey: "contractorEmail",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Email" />
    ),
  },
  {
    accessorKey: "contractorNumber",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Phone Number" />
    ),
  },

  {
    accessorKey: "salesrepresentativeCount",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sales Representative (#)" />
    ),
  },
  {
    accessorKey: "projectCount",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Total Projects" />
    ),
  },
  {
    accessorKey: "sumOfProjectSize",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Total Projects Size (kW)" />
    ),
  },
  {
    accessorKey: "services",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Number of Projects" />
    ),
    cell({ row: { original: { services } } }) {
      return <div>{services && services?.length}</div>
    },
  },
  {
    accessorKey: "noOfYearsExperiance",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Years Since In Business" />
    ),
  },
  {
    accessorKey: "noOfReview",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Rating" />
    ),
    cell({ row: { original: { noOfReview } } })   {
      return <Rating value={Number(noOfReview) || 0} readOnly style={{height:20}} />
    },
  },

  // {
  //   id: "Actions",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Actions" />
  //   ),
  //   cell({ row: { original } }) {
  //     return <Actions row={original} />
  //   },
  // },

];
