import { useState } from 'react'
import { toast } from 'sonner'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import CreateManufacturerProductForm from 'src/modules/ManufacturerProducts/CreateManufacturerProductForm'
import { INITIAL_PRODUCT_VALUES } from 'src/modules/ManufacturerProducts/module'
import { createProductApiRequest } from 'src/services/requests/Products'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'

type Props = {
    id: string,
    onSuccess?: () => void
}

const CreateManufactureProduct = ({ id,onSuccess }: Props) => {

    const [isLoading, setisLoading] = useState<boolean>(false)

    const handleCreatePoduct = catchAsync(async (formdata: any) => {
        setisLoading(true)
        let postData = {
            ...formdata,
            manufacturer_id: id,
            current_quantity: formdata?.total_quantity
        }
        return await createProductApiRequest(postData)
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            onSuccess && onSuccess()
            toast.success("Product created successfully")
        }
        setisLoading(false)
    }, () => { setisLoading(false) })


    return (
        <ScrollArea className='h-[80vh]'>
            <CreateManufacturerProductForm initValues={INITIAL_PRODUCT_VALUES} onSubmit={handleCreatePoduct} />
        </ScrollArea>
    )
}

export default CreateManufactureProduct