import { useMemo } from 'react'
import { columns } from 'src/modules/ProjectEnquiry/AllLandEnquiry/components/columns'
import { DataTable } from 'src/modules/ProjectEnquiry/AllLandEnquiry/components/data-table'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponseData } from './core/QueryResponseProvider'

type Props = {}

const Main = () => {
  const apiResp = useQueryResponseData()
  console.log({apiResp});
  
  const tableData = useMemo(() => apiResp.map((d: any, indx: number) => {
    return {
      srNo: indx + 1,
      ...d,
    }
  }), [apiResp])

   return (
      <div className='p-4'>
          <DataTable columns={columns} data={tableData || []} />
      </div>
    )
}

const LandEnquiryMain = (props: Props) => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <Main />
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export default LandEnquiryMain