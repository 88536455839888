import { Link } from 'react-router-dom'
import { checkDecimal, cn, componentType, EXTRACT_NUMBERS_REGEX, FormField } from 'src/helpers'
import * as modules from 'src/modules/Projects/modules'
import DocumentViwer from 'src/shadcn/components/custom/DocumentViwer'
import { Label, Separator } from 'src/shadcn/components/ui'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import { ClassNameValue } from 'tailwind-merge'
import { FORM_SEQUENCE, removeFIelds, SITE_DETAILS_FORM_FIELDS, SiteAttachments } from './modules'

const { initialContactDetails, initialLocationDetails, initialPowerConsumptionData, initialProjectData, initialProjectFinanceData } = modules

type Props = {
    data: any,
    classNames?: ClassNameValue
}


const RenderViewComponent = ({ value, type, formField }: { type: componentType, value: string | number | undefined, formField: FormField }) => {

    if (formField.dataKey === "termPlan") {
        if (value && Number(value) > 10) {
            value = Number((value).toString())/12 + " Years"
        }
        else value = value + " Years"
    }

    return <div className='flex flex-col border-b'>
        <Label className='mb-1 text-xs  text-gray-500'>{formField.label}</Label>
        {formField.componentProps?.description ? <div className='text-xs text-gray-500'>{formField.componentProps.description}</div> : null}
        <div className='text-sm flex space-x-1 items-center'>
            {formField.componentProps?.inputPrefixIcon ? formField.componentProps?.inputPrefixIcon : null}
            <div className='font-semibold mb-1  '>
                {
                    value ? <>
                        {(['input', 'inputicon', 'options', 'date_picker', "textarea"].includes(type)) && <div className=' '>{formField?.componentProps?.numberFormatted ? value && checkDecimal((value).toString().trim().replace(EXTRACT_NUMBERS_REGEX, "") as string, true) : value}</div>}
                        {type === "file_picker" && <Link to={value as string || "#"} target='_blank' ><div className='  text-blue-400 underline underline-offset-2 cursor-pointer'>View</div></Link>}
                    </> : "-"
                }
            </div>
            {formField.componentProps?.inputSuffixIcon ? formField.componentProps?.inputSuffixIcon : null}
        </div>
    </div>
}



const ReviewSiteDetailsForm = ({ data, classNames }: Props) => {

    return (
        <div className=' '>
            <ScrollArea className={cn('h-[60vh] px-4 bg-gray-50', classNames)}>
                {
                    Object.entries(FORM_SEQUENCE).map(([key, v]) => {
                        return <div>
                            <div className=' uppercase text-primary text-sm mt-4 mb-2'>{key}</div>
                            <Separator className='my-2' />
                            <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                                {
                                    Object.keys(v).filter((v: any) => !removeFIelds.includes(v)).map((k: any) => {
                                        const formfield: FormField | undefined = SITE_DETAILS_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                                        if (Object.keys(SiteAttachments).includes(k)) {
                                            const attachment = data[formfield?.dataKey as keyof object]
                                            return formfield && <div className={cn('flex flex-col ', { "col-span-3": Array.isArray(attachment) })}>
                                                <Label className='mb-1 uppercase text-xs  text-gray-500'>{formfield.label}</Label>
                                                <div>
                                                    {Array.isArray(attachment) && attachment.every((url: any) => typeof url === "string") ? <div className='flex flex-wrap'>{attachment.map((url: string) => <div className='mx-2'> <DocumentViwer url={url} /></div>)}</div> : null}
                                                    {typeof attachment === "string" ? <div className='mx-2'><DocumentViwer url={attachment} /></div> : null}
                                                </div>
                                            </div>
                                        }
                                        else {
                                            return formfield && <RenderViewComponent key={formfield?.dataKey} formField={formfield} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                                        }
                                    })
                                }
                            </div>
                        </div>
                    })
                }
                {/* <div className=' uppercase text-primary text-sm mt-4 mb-2'>Project Data</div>
                <Separator className='my-2' />
                <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                    {
                        Object.keys(initialProjectData).map((k: any) => {
                            const formfield: FormField | undefined = SITE_DETAILS_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                            return formfield && <RenderViewComponent key={formfield?.dataKey} label={formfield?.label} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                        })
                    }
                </div>
                <div className=' uppercase text-primary text-sm mt-4 mb-2'>Contact Data</div>
                <Separator className='my-2' />
                <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                    {
                        Object.keys(initialContactDetails).map((k: any) => {
                            const formfield: FormField | undefined = SITE_DETAILS_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                            return formfield && <RenderViewComponent key={formfield?.dataKey} label={formfield?.label} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                        })
                    }
                </div>
                <div className=' uppercase text-primary text-sm mt-4 mb-2'>Location Data</div>
                <Separator className='my-2' />
                <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                    {
                        Object.keys(initialLocationDetails).map((k: any) => {
                            const formfield: FormField | undefined = SITE_DETAILS_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                            return formfield && <RenderViewComponent key={formfield?.dataKey} label={formfield?.label} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                        })
                    }
                </div>
                <div className=' uppercase text-primary text-sm mt-4 mb-2'>Attachments</div>
                <Separator className='my-2' />
                <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                    {
                        Object.keys(SiteAttachments).map((k: any) => {
                            const formfield: FormField | undefined = SITE_DETAILS_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                            const attachment = data[formfield?.dataKey as keyof object]
                            return formfield && <div className='flex flex-col'>
                                <Label className='mb-1 uppercase text-xs  text-gray-500'>{formfield.label}</Label>
                                <div>
                                    {Array.isArray(attachment) && attachment.every((url: any) => typeof url === "string") ? <div className='flex flex-wrap'>{attachment.map((url: string) => <div className='mx-2'> <DocumentViwer url={url} /></div>)}</div> : null}
                                    {typeof attachment === "string" ? <DocumentViwer url={attachment} /> : null}
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className=' uppercase text-primary text-sm mt-4 mb-2'>Site Data</div>
                <Separator className='my-2' />
                <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                    {
                        Object.keys(InitSiteData).map((k: any) => {
                            const formfield: FormField | undefined = SITE_DETAILS_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                            return formfield && <RenderViewComponent key={formfield?.dataKey} label={formfield?.label} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                        })
                    }
                </div>
                <div className=' uppercase text-primary text-sm mt-4 mb-2'>Power Consumption Data</div>
                <Separator className='my-2' />
                <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                    {
                        Object.keys(initialPowerConsumptionData).map((k: any) => {
                            const formfield: FormField | undefined = SITE_DETAILS_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                            return formfield && <RenderViewComponent key={formfield?.dataKey} label={formfield?.label} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                        })
                    }
                </div>
                <div className=' uppercase text-primary text-sm mt-4 mb-2'>Finance Data</div>
                <Separator className='my-2' />
                <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                    {
                        Object.keys(initialProjectFinanceData).map((k: any) => {
                            const formfield: FormField | undefined = SITE_DETAILS_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                            return formfield && <RenderViewComponent key={formfield?.dataKey} label={formfield?.label} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                        })
                    }
                </div> */}

            </ScrollArea>
        </div>

    )
}

export default ReviewSiteDetailsForm