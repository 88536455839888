import { InfoIcon } from "lucide-react";
import { ChangeEvent, useMemo, useState } from "react";
import { NumberFormatBase, NumericFormat } from "react-number-format";
import { checkDecimal, cn, optionTypes } from "src/helpers";
import { ClassNameValue } from "tailwind-merge";
import { Input, Label, Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "../../ui";
import { NumberInput } from "./NumberInput";

type Props = {
  formik: any;
  label: string;
  nameKey: string;
  placeholder?: string;
  lableClass?: string;
  defaultValue?: string;
  inputMode?:
  | "search"
  | "text"
  | "email"
  | "tel"
  | "url"
  | "none"
  | "numeric"
  | "decimal"
  | undefined;
  value?: string;
  required?: boolean;
  type?: string;
  disabled?: boolean;
  caseSensetiveLabel?: boolean;
  maxLength?: number;
  length?: number;
  regex?: any;
  blockletters?: boolean;
  onchange?: (e: any) => void;
  readonly?: boolean;
  prefix?: string;
  onBlur?: (e: any) => void;
  sufix?: boolean;
  showCharacterLength?: boolean,
  error_className?: ClassNameValue,
  showInfoIcon?: boolean
  infoContent?: string
  numberFormatted?: boolean
  labelStyle?: ClassNameValue
  description?: string,
  options?: optionTypes[],
  optionsPlaceholder?: string,
  onKeyDown?: (e: any) => void
};

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // escape special characters
}

function MyCustomNumberFormat(props: any) {
  const format = (numStr: any) => {
    if (numStr === "") return "";

    // Convert the string to a number for formatting
    const numberValue = Number(numStr);

    // Check if the number has decimal places or not
    const hasDecimal = numStr.includes('.');

    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      // Conditionally set maximumFractionDigits based on input
      minimumFractionDigits: hasDecimal ? 2 : 0, // Show 2 digits if decimal exists
      maximumFractionDigits: 2
    }).format(numberValue);
  };

  return <NumberFormatBase {...props} format={format} />;
}

const FormikInput = ({
  formik,
  placeholder,
  nameKey,
  label,
  value,
  type,
  inputMode,
  disabled,
  caseSensetiveLabel = false,
  maxLength,
  length,
  regex,
  blockletters,
  readonly,
  prefix,
  onchange,
  lableClass,
  required,
  onBlur,
  sufix,
  showCharacterLength,
  error_className,
  showInfoIcon,
  infoContent,
  numberFormatted,
  labelStyle,
  description,
  options,
  optionsPlaceholder,
  onKeyDown
}: Props) => {

  const [actualValue, unit] = useMemo(() => {
    if (options && options.length > 0 && value) {
      let getvalue = options.find((o: optionTypes) => value.includes(o.value))?.value
      // console.log({ getvalue });
      if (getvalue) {
        // console.log({ data: value?.split(getvalue).map((d: any) => d.trim()) });
        return [value?.split(getvalue).map((d: any) => d.trim())[0], getvalue]
      }
      return [value, undefined]
    }
    return [value, undefined]
  }, [value, options])

  const [selectedOption, setSelectedOption] = useState<any>(undefined)

  // console.log({required});


  // console.log({value});


  // console.log({ unit,selectedOption });
  // className={cn('flex items-center border px-2 rounded-lg focus-within:ring-1 focus-visible:ring-1 ring-primary ',{ "border border-destructive" : formik.touched[nameKey] && formik.errors[nameKey] })}

  return (
    <div className={cn("flex flex-col ")}>
      {label ? <div className="flex justify-between ">
        <div className="flex justify-between mt-2">
          <Label className={cn(`text-xs`, labelStyle)}>
            {label} {required ? <span className="text-red-600 font-semibold">*</span> : null}
          </Label>
          <div>
            {maxLength && showCharacterLength && <div className="text-xs text-end text-gray-500 mt-1">{value?.length || 0}/{maxLength}</div>}
          </div>
        </div>
        {showInfoIcon ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger onClick={(event: any) => event.preventDefault()} className="">
                <InfoIcon className="w-[16px] h-[16px] text-gray-500 " />
              </TooltipTrigger>
              <TooltipContent>
                <div className="w-[200px]">{infoContent}</div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : null}
      </div> : null}
      {description && <div className="text-xs text-gray-500">{description}</div>}
      <div className="mt-2 ">
        <div className={cn('flex items-center border px-2 rounded-lg focus-within:ring-1 focus-visible:ring-1 ring-primary ', { "border border-destructive": formik.touched[nameKey] && formik.errors[nameKey], "bg-slate-200": readonly })}>
          {prefix}
          {type === "number" || (type === "text" && numberFormatted) ?
            <NumericFormat
              name={nameKey}
              onBlur={onBlur}
              disabled={disabled || false}
              thousandsGroupStyle={numberFormatted ? "lakh" : "none"}
              thousandSeparator={numberFormatted ? "," : ""}
              customInput={NumberInput}
              value={value || ""}
              readOnly={readonly}
              placeholder={placeholder || label}
              maxLength={maxLength || length || 225}
              decimalScale={numberFormatted ? 2 : 50}
              onKeyDown={onKeyDown}
              onValueChange={(e: any) => {
                let NewValue = e.value ? checkDecimal(e.value) : null
                if (options) {
                  onchange && onchange(NewValue + " " + (selectedOption || unit || "") || "")
                }
                else onchange && onchange(NewValue || "")
              }}
            />
            :
            <Input
              name={nameKey}
              onBlur={onBlur}
              qa-data={nameKey}
              disabled={disabled || false}
              type={type || "text"}
              inputMode={inputMode}
              placeholder={placeholder || label}
              maxLength={maxLength || length || 225}
              className={cn("border-0 focus-visible:ring-0 focus-visible:ring-ring", { uppercase: formik.values[nameKey] && blockletters })}
              readOnly={readonly}
              value={value}
              onKeyDown={onKeyDown}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                let newVal = null
                if (regex && value) {
                  let cleanedValue = e.target.value.replace(regex, "");
                  newVal = cleanedValue
                }
                else if (type === "email") {
                  newVal = e.target.value.toLowerCase();
                }
                else {
                  newVal = e.target.value;
                }
                if (options) {
                  onchange && onchange(newVal + "" + (selectedOption || unit || "") || "")
                }
                else onchange && onchange(newVal)
              }}
            />
          }
          {options && options.length > 0 ? <div>
            <Select value={selectedOption || unit} onValueChange={(e: any) => {
              console.log({ e });
              let newunit = e === "clear" ? "" : e
              setSelectedOption(newunit)
              const regexPattern = options.map((data: optionTypes) => data.value).join("|");
              const dynamicRegex = new RegExp(regexPattern, "g");
              onchange && onchange(value?.replace(dynamicRegex, "") + " " + newunit || "")
            }}  >
              <SelectTrigger onClear={() => {
                const regexPattern = options.map((data: optionTypes) => data.value).join("|");
                const dynamicRegex = new RegExp(regexPattern, "g");
                onchange && onchange(value?.replace(dynamicRegex, "") || "")
                // onchange && onchange(value?.replace(/Sq.ft.|m|ft/g, "") || "")
              }} className="p-1 h-6 bg-gray-200 border border-gray-800 text-black border-none focus:border-none" caretSortIconClassName="text-black opacity-100" >
                <div className=' flex justify-between items-center w-full '>
                  <div>{options?.find((option: optionTypes) => (option.value === unit) || (option.value === selectedOption))?.label || <div className='text-gray-400' >{optionsPlaceholder || "Select"}</div>} </div>
                </div>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {options?.map(({ label, value }: optionTypes) => {
                    return <SelectItem value={value}>{label}</SelectItem>
                  })}
                </SelectGroup>
              </SelectContent>
            </Select>
            {/* <Tabs value={unit} className="">
              <TabsList>
                {
                  options && options.map((d: optionTypes) => {
                    return <TabsTrigger className="text-xs" onClick={() => {
                      setSelectedOption(d.value)
                      let regx = new RegExp("/" + options.map((d: optionTypes) => d.value).join("|") + "/g")
                      onchange && onchange(value?.replace(/Sq.ft.|m|ft/g, "") + "" + d.value || "")
                    }} value={d.value}>{d.label}</TabsTrigger>
                  })
                }
              </TabsList>
            </Tabs> */}
          </div> : null}
          {sufix}
        </div>
      </div>
      {
        formik.touched[nameKey] && formik.errors[nameKey] && (
          <div className={cn("text-xs font-medium text-destructive", error_className)}>
            {formik.errors[nameKey]}
          </div>
        )
      }
    </div >
  );
};

export default FormikInput;
