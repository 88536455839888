import { DataTable } from 'src/modules/Referral/Tabel/Component/data-table'
import { view_columns } from 'src/modules/Referral/Tabel/Component/view_columns'

const RefralViewTable = () => {
  return (
    <div className='w-full space-y-4'>
<div className='text-lg font-semibold text-gray'>Referral Details</div>
        <div>
        <DataTable data={ []} columns={view_columns} isLoading={false}/>
        </div>
    </div>
  )
}

export default RefralViewTable