import { ColumnDef } from "@tanstack/react-table";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";


export const columns: ColumnDef<Task>[] = [
  {
    accessorKey: "srNo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sr.No. (#)" />
    ),
  },
  {
    accessorKey: "phonenumber",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Phone Number" />
    ),
  },
  {
    accessorKey: "location",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Location" />
    ),
  },
  {
    accessorKey: "discom",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Discom " />
    ),
  },
  {
    accessorKey: "area_unit",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Area (Sq.Ft.)" />
    ),
  },
  {
    accessorKey: "roof_area",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Roof Area (Sq.Ft.)" />
    ),
  },


  // {
  //   id: "Actions",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Actions" />
  //   ),
  //   cell({ row: { original } }) {
  //     return <Actions row={original} />
  //   },
  // },

];
