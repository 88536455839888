
import { useMemo } from 'react'
import { columns } from 'src/modules/Referral/Tabel/Component/columns'
import { DataTable } from 'src/modules/Referral/Tabel/Component/data-table'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponseData, useQueryResponseLoading } from './core/QueryResponseProvider'

type Props = {}


const Main = () => {

  const apiResp = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const tableData = useMemo(() => {
    if (apiResp && Array.isArray(apiResp) && apiResp.length > 0) {
      return apiResp.map((d: any, indx: number) => {
        return ({
          ...d,
          srNo: (indx + 1)
        })
      })
    }
    else return []
  }, [apiResp])

  return <>
    <DataTable data={tableData} columns={columns} isLoading={isLoading} />
  </>
}

const RefralProjectMain = (props: Props) => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <Main />
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export default RefralProjectMain;
