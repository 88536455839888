import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import PaymentMainPage from './All/PaymentMainPage'
import PaymentViewPageMain from './PaymentViewPageMain'


type Props = {}

const PaymentsRoutepage = (props: Props) => {
  const PROJECTS_ROUTES = APP_ROUTES.payment.subRoutes

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<PaymentMainPage/>} />
        {/* <Route path={PROJECTS_ROUTES.create.route} element={<ManufacturerCreateMain />} /> */}
        <Route path={PROJECTS_ROUTES.view.route + "/:id"} element={<PaymentViewPageMain/>} />
        {/* <Route path={PROJECTS_ROUTES.view.route + "/:id"} element={<ManufacturerViewMain />} /> */}
        <Route path={"*"} element={<Navigate to={"/" + PROJECTS_ROUTES.all.fullRoute} />} />
      </Route>
    </Routes>
  )
}

export default PaymentsRoutepage