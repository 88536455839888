import { ColumnDef } from "@tanstack/react-table";
import { tableSchema, Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import Actions from "./Actions";
import { APPROVAL_REJECT_OPTIONS, checkDecimal, convertToCamelCase, ENUM_APPROVE_REJECT, FormField } from "src/helpers";
import { Badge } from "src/shadcn/components/ui";
import { PROJECT_RIGHTS_FORM_FIELDS } from "../../modules";
import DocumentViwer from "src/shadcn/components/custom/DocumentViwer";


const dataCols = Object.keys(tableSchema.shape).filter((d: any) => !["_id", "id", "srNo"].includes(d)).map((key) => {

  let componentProps = PROJECT_RIGHTS_FORM_FIELDS.find((formfiled: FormField) => formfiled.dataKey === key)
  if (componentProps && componentProps.componentProps?.type === "number") {
    if (componentProps.componentProps.numberFormatted) {
      return ({
        accessorKey: `${key}`,
        header: ({ column }: any) => (
          <DataTableColumnHeader column={column} title={convertToCamelCase(key)} />
        ),
        cell({ row: { original } }: any) {
          return <div>{checkDecimal(original[key], true)}</div>
        },
      })
    }
  }
  else if (componentProps && componentProps.componentType === "file_picker" && !componentProps.componentProps?.fileSelectionType ) {
    return ({
      accessorKey: `${key}`,
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title={convertToCamelCase(key)} />
      ),
      cell({ row: { original } }: any) {
        return <DocumentViwer url={original[key]} contentType="" title={convertToCamelCase(key) + " Document"} />
      },
    })
  }

  return ({
    accessorKey: `${key}`,
    header: ({ column }: any) => (
      <DataTableColumnHeader column={column} title={convertToCamelCase(key)} />
    ),
  })
})



export const columns: ColumnDef<Task>[] = [
  {
    accessorKey: "srNo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sr.No. (#)" />
    ),
  },
  ...dataCols,
  {
    id: "Actions",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actions" />
    ),
    cell({ row: { original } }) {
      return <Actions row={original} />
    },
  },
];


console.log({ dataCols });
