import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROLES } from 'src/helpers';
import { useAuth } from 'src/modules/Auth/Core';
import AdminDashboardSummary from 'src/modules/Dashboards/AdminDashboardSummary';
import EPCContractorDashboardSummary from 'src/modules/Dashboards/EPCContractorDashboardSummary';
import ManufacturerDashboard from 'src/modules/Dashboards/ManufacturerDashboard';
import { EPC_CONTRACTORS_ROUTES } from 'src/navigations/modules';
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area';
import AdminProjectHistory from './AdminProjectHistory';
import DailyOverviewData from './DailyOverviewData';
import EPCProjectHistory from './EPCProjectHistory';


type Props = {}

const DashboardMain = (props: Props) => {

  const { currentUser } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser?.role === ROLES.EPC_CONTRACTOR && !currentUser?.profileDetails) {
      navigate("/" + EPC_CONTRACTORS_ROUTES.profile.subRoutes.create.fullRoute)
    }
    return () => { }
  }, [currentUser])


  console.log({ role: currentUser?.role });



  return (
    <div className='p-4 space-y-2'>
      <ScrollArea className='h-[85vh]'>
        <DailyOverviewData />
        {currentUser?.role === ROLES.ADMIN_USER ? <AdminDashboardSummary /> : null}
        {currentUser?.role === "EPC_CONTRACTOR" ? <EPCContractorDashboardSummary /> : null}
        {currentUser?.role === ROLES.MANUFACTURER ? <ManufacturerDashboard /> : null}
        <div className=''>
          {/* <InvestmentAnalysis /> */}
          <div>
            {currentUser?.role === ROLES.ADMIN_USER ? <AdminProjectHistory /> : null}
            {currentUser?.role === "EPC_CONTRACTOR" ? <EPCProjectHistory /> : null}
          </div>
        </div>
      </ScrollArea>
    </div>
  )
}

export default DashboardMain