import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import App from "src/App";
import { ROLES } from "src/helpers";
import { useAuth } from "src/modules/Auth/Core";
import AppRoutes from "./AppRoutes";
import AuthRoutes from "./AuthRoutes";
import EPSRoutes from "./EPSRoutes";
import ManufacturerRoutes from "./ManufacturerRoutes";
import { APP_ROUTES, MAIN_ROUTES } from "./modules";

type Props = {};

const MainRoutes = (props: Props) => {

  const { currentUser } = useAuth()
  console.log({ currentUser });

  // const currentUser =  true


  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />} >
          {
            currentUser ?
              <>
                {
                  currentUser?.role === ROLES.ADMIN_USER ? <>
                    <Route path={"/*"} element={<AppRoutes />} />
                    <Route path='*' element={<Navigate to={APP_ROUTES.dashboard.route} />} />
                  </> : null
                }
                {
                  currentUser?.role === ROLES.EPC_CONTRACTOR ? <>
                    <Route path={"/*"} element={<EPSRoutes />} />
                    <Route path='*' element={<Navigate to={APP_ROUTES.dashboard.route} />} />
                  </> : null
                }
                {
                  currentUser?.role === ROLES.MANUFACTURER ? <>
                    <Route path={"/*"} element={<ManufacturerRoutes />} />
                    <Route path='*' element={<Navigate to={APP_ROUTES.dashboard.route} />} />
                  </> : null
                }
              </>
              :
              <>
                <Route path={MAIN_ROUTES.auth + "/*"} element={<AuthRoutes />} />
                <Route path='*' element={<Navigate to={MAIN_ROUTES.auth} />} />
              </>
          }
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default MainRoutes;
