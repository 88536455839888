import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Value } from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { catchAsync, IS_SUCCESS_RESPONSE, stringifyObjectToRequestQuery } from 'src/helpers';
import { getWebsiteCountRequestApi } from 'src/services/requests/Dashboard';

type Props = {}

const DailyOverviewData = (props: Props) => {
    const [value, onChange] = useState<Value>([dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()]);
    const [data, setdata] = useState<any>(0)

    console.log({ value });

    useEffect(() => {
        handleFetchDailyCount([dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()])
        return () => { }
    }, [])


    const handleFetchDailyCount = catchAsync(async (value: any) => {
        return await getWebsiteCountRequestApi(stringifyObjectToRequestQuery({
            startdate: dayjs(value[0]).format('DD/MM/YYYY'),
            enddate: dayjs(value[1]).format('DD/MM/YYYY'),
        }))
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            setdata(result?.data)
        }
    }, () => { })

    return (
        <div>
            <div className='grid grid-cols-3 gap-4'>
                <div className='border rounded-xl p-4'>
                    <div className='flex justify-between'>
                        <div>Website Visit Count</div>
                        <DateRangePicker onChange={(e: any) => {
                            onChange(e)
                            handleFetchDailyCount(e)
                        }} value={value} calendarIcon={false} />
                    </div>
                    <div className='text-4xl font-bold'>
                        {data}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DailyOverviewData