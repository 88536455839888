import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import RefralProjectMain from './All/RefralProjectMain'
import RefralViewMain from './RefralView/RefralViewMain'


type Props = {}

const RefralRoutePage = (props: Props) => {
  const PROJECTS_ROUTES = APP_ROUTES.referral.subRoutes

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<RefralProjectMain/>} />
        {/* <Route path={PROJECTS_ROUTES.create.route} element={<ManufacturerCreateMain />} /> */}
        <Route path={PROJECTS_ROUTES.view.route + "/:id"} element={<RefralViewMain/>} />
        {/* <Route path={PROJECTS_ROUTES.view.route + "/:id"} element={<ManufacturerViewMain />} /> */}
        <Route path={"*"} element={<Navigate to={"/" + PROJECTS_ROUTES.all.fullRoute} />} />
      </Route>
    </Routes>
  )
}

export default RefralRoutePage