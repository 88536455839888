import { Eye } from 'lucide-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { APP_ROUTES } from 'src/navigations/modules'
import { useQueryResponse } from 'src/pages/Orders/AllOrders/core/QueryResponseProvider'
import { getAllCouponsByUserRequestApi } from 'src/services/requests/coupons'
import { updateTransactionRequestApi } from 'src/services/requests/Transactions'
import ToolTip from 'src/shadcn/components/custom/ToolTip'
import { Button } from 'src/shadcn/components/ui'

type Props = {
    row: any
}

const Actions = ({ row }: Props) => {

    const navigate = useNavigate()
    const { currentUser } = useAuth()
    const [coupons, setcoupons] = useState([])
    const { refetch } = useQueryResponse()

    const findCouponAgainstUser = catchAsync(async () => {
        return getAllCouponsByUserRequestApi(row?.user_id)
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            let coupon = result?.data && result?.data.find((d: any) => d?.couponcode === "NEW20" && d?.status === "active")
            handleCreatePayment(coupon)
        }
    }, () => { })

    const handleCreatePayment = catchAsync(async (coupon: any) => {
        let postData = {
            "card_type": "debit",
            "order_id": row._id,
            "card_number_masked": "65456548946",
            "bank_transaction_id": "XHBJHGBJJ32423424234",
            "total_card_payment": row.total_price,
            "payment_status": "success",
            "coupon_id": coupon ? coupon._id : ""
        }
        return updateTransactionRequestApi(row?.transaction_id, postData)
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            refetch()
        }
    }, () => { })

    return (
        <div className='flex space-x-2'>
            <ToolTip tooltipContent='View project'>
                <Button size={"sm"} variant={'primaryIconBtn'} onClick={() => navigate("/" + APP_ROUTES.orders.subRoutes.view.fullRoute + "/" + row._id)} >
                    <Eye size="16" />
                </Button>
            </ToolTip>
            {
                row?.transaction?.payment_status === "pending" ? <ToolTip tooltipContent='View project'>
                    <Button size={"sm"} variant={'outlinePrimary'} onClick={() => findCouponAgainstUser()} >
                        Update payment
                    </Button>
                </ToolTip> : null
            }

            {/* <ToolTip tooltipContent='Edit project'>
                <Button  size={"sm"} variant={'primaryIconBtn'} onClick={() => navigate("/" + APP_ROUTES.orders.subRoutes..fullRoute + "/" + row._id)} >
                    <Pencil size="16" />
                </Button>
            </ToolTip> */}
        </div>
    )
}

export default Actions