
type Props = {}

const PulseLoader = (props: Props) => {
    return (
        <div className="flex space-x-2">
            <div className="w-2 h-2 bg-white rounded-full animate-pulse" style={{ animationDelay: "0.1s" }}></div>
            <div className="w-2 h-2 bg-white rounded-full animate-pulse" style={{ animationDelay: "0.2s" }}></div>
            <div className="w-2 h-2 bg-white rounded-full animate-pulse" style={{ animationDelay: "0.3s" }}></div>
        </div>
    )
}

export default PulseLoader