import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { initSalesExecutive } from 'src/modules/EpcContractors/SalesExecutives/modules'
import SalesExecutiveForm from 'src/modules/EpcContractors/SalesExecutives/SalesExecutiveForm'
import { EPC_CONTRACTORS_ROUTES } from 'src/navigations/modules'
import { ApiResponse } from 'src/services/consts/api_const'
import { createSalesExecutiveApiRequest } from 'src/services/requests/SalesExecutve'


type Props = {}

const EpcContractorAgnetsCreateMain = (props: Props) => {
  const [formData, setformData] = useState(undefined)
  const [isLoading, setisLoading] = useState(false)
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  const handleCreateSalesExecutive = catchAsync(async (formData: any) => {
    setisLoading(true)
    delete formData["confirmPassword"]
    return await formData && currentUser && createSalesExecutiveApiRequest({ ...formData, role: "Sales Representative", epc_contractors_id: currentUser?.id })
  }, (result: ApiResponse) => {
    console.log({ result })
    if (IS_SUCCESS_RESPONSE(result)) {
      navigate("/" + EPC_CONTRACTORS_ROUTES.sales_executive.subRoutes.all.fullRoute)
      toast.success("User Created Successfully")
    }
    setisLoading(false)
  }, () => {
    setisLoading(false)
  })

  return (
    <div>
      <SalesExecutiveForm initial_values={initSalesExecutive} submitBtnTitle='Submit' isLoadingForm={isLoading} onSubmit={(e: any) => handleCreateSalesExecutive(e)} />
    </div>
  )
}

export default EpcContractorAgnetsCreateMain