import { useNavigate } from "react-router-dom";
import { useAuth } from "src/modules/Auth/Core";
import { columns } from "src/modules/Wallets/Tabel/Component/columns";
import { DataTable } from "src/modules/Wallets/Tabel/Component/data-table";

type Props = {};

const WalletMain = (props: Props) => {

  const activities = [
    {
      activity_id: "act12345",
      user_id: "user56789",
      wallet_id: "wallet123",
      coupon_id: "coupon789",
      order_id: "order456",
      activity_type: "purchase",
      amount: 100.0,
      description: "Purchased items using wallet",
      created_at: "2024-11-22T10:30:00Z",
      transactionType: "debit",
      firstName: "John",
      lastName: "Doe",
    },
    {
      activity_id: "act12346",
      user_id: "user56790",
      wallet_id: "wallet124",
      coupon_id: "coupon790",
      order_id: "order457",
      activity_type: "refund",
      amount: 50.0,
      description: "Refund processed to wallet",
      created_at: "2024-11-22T11:00:00Z",
      transactionType: "credit",
      firstName: "Jane",
      lastName: "Smith",
    },
    {
      activity_id: "act12347",
      user_id: "user56791",
      wallet_id: "wallet125",
      coupon_id: "coupon791",
      order_id: "order458",
      activity_type: "cashback",
      amount: 20.0,
      description: "Cashback credited",
      created_at: "2024-11-22T11:15:00Z",
      transactionType: "credit",
      firstName: "Alice",
      lastName: "Brown",
    },
    {
      activity_id: "act12348",
      user_id: "user56792",
      wallet_id: "wallet126",
      coupon_id: "coupon792",
      order_id: "order459",
      activity_type: "purchase",
      amount: 120.0,
      description: "Wallet used for payment",
      created_at: "2024-11-22T11:30:00Z",
      transactionType: "debit",
      firstName: "Bob",
      lastName: "Johnson",
    },
    {
      activity_id: "act12349",
      user_id: "user56793",
      wallet_id: "wallet127",
      coupon_id: "coupon793",
      order_id: "order460",
      activity_type: "reward",
      amount: 10.0,
      description: "Reward credited for activity",
      created_at: "2024-11-22T12:00:00Z",
      transactionType: "credit",
      firstName: "Emma",
      lastName: "Davis",
    },
    {
      activity_id: "act12350",
      user_id: "user56794",
      wallet_id: "wallet128",
      coupon_id: "coupon794",
      order_id: "order461",
      activity_type: "redemption",
      amount: 80.0,
      description: "Coupon redeemed",
      created_at: "2024-11-22T12:30:00Z",
      transactionType: "debit",
      firstName: "Liam",
      lastName: "Miller",
    },
    {
      activity_id: "act12351",
      user_id: "user56795",
      wallet_id: "wallet129",
      coupon_id: "coupon795",
      order_id: "order462",
      activity_type: "bonus",
      amount: 15.0,
      description: "Bonus amount credited",
      created_at: "2024-11-22T13:00:00Z",
      transactionType: "credit",
      firstName: "Noah",
      lastName: "Wilson",
    },
    {
      activity_id: "act12352",
      user_id: "user56796",
      wallet_id: "wallet130",
      coupon_id: "coupon796",
      order_id: "order463",
      activity_type: "purchase",
      amount: 75.0,
      description: "Wallet used for purchase",
      created_at: "2024-11-22T13:30:00Z",
      transactionType: "debit",
      firstName: "Olivia",
      lastName: "Taylor",
    },
    {
      activity_id: "act12353",
      user_id: "user56797",
      wallet_id: "wallet131",
      coupon_id: "coupon797",
      order_id: "order464",
      activity_type: "cashback",
      amount: 25.0,
      description: "Cashback credited to wallet",
      created_at: "2024-11-22T14:00:00Z",
      transactionType: "credit",
      firstName: "Ava",
      lastName: "Anderson",
    },
    {
      activity_id: "act12354",
      user_id: "user56798",
      wallet_id: "wallet132",
      coupon_id: "coupon798",
      order_id: "order465",
      activity_type: "refund",
      amount: 100.0,
      description: "Refund processed successfully",
      created_at: "2024-11-22T14:30:00Z",
      transactionType: "credit",
      firstName: "William",
      lastName: "Thomas",
    },
  ];
  
  
  return (
    <div className="m-4 space-y-2 ">
      <div className="flex justify-between">
        <span className="text-primary">Wallets</span>
        {/* <Button onClick={() => navigate("/" + APP_ROUTES.manufacturers.subRoutes.create.fullRoute)} className='space-x-1'><span><Plus size={16} /></span> <span>Create </span> </Button> */}
      </div>
      <DataTable data={activities || []} columns={columns} isLoading={false}/>
    </div>
  );
};

export default WalletMain;
