import { componentType, FormField } from 'src/helpers'
import { Label, Separator } from 'src/shadcn/components/ui'
import { initSalesExecutive, SalesExecutiveFormFields } from './modules'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import { Link } from 'react-router-dom'
import { Video } from 'reactjs-media'
import DocumentViwer from 'src/shadcn/components/custom/DocumentViwer'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'


type Props = {
    data: any
}


const RenderViewComponent = ({ label, value, type }: { label: string, type: componentType, value: string | number | undefined }) => {
    return <div className='flex flex-col border-b'>
        <Label className='mb-1 uppercase text-xs  text-gray-500'>{label}</Label>
        <div className='font-semibold mb-1  '>
            {
                value ? <>
                    {(['input', 'inputicon', 'options', 'date_picker', "textarea"].includes(type)) && <div className=' '>{value}</div>}
                    {type === "file_picker" && <Link to={value as string || "#"} target='_blank' ><div className='  text-blue-400 underline underline-offset-2 cursor-pointer'>View</div></Link>}
                </> : "-"
            }
        </div>
    </div>
}

const SalesExectiveReviewForm = ({ data }: Props) => {

    return (
        <div className=' '>
            <ScrollArea className='h-[60vh] px-4 bg-gray-50'>
                <div className=' uppercase text-primary text-sm mt-4 mb-2'>Sales Executive Details</div>
                <Separator className='my-2' />
                <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                    {
                        Object.keys(initSalesExecutive).map((k: any) => {
                            const formfield: FormField | undefined = SalesExecutiveFormFields.find((filed: FormField) => filed.dataKey === k) || undefined
                            return formfield && <RenderViewComponent key={formfield?.dataKey} label={formfield?.label} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                        })
                    }
                </div>
            </ScrollArea>
        </div>

    )
}

export default SalesExectiveReviewForm