import RefralViewTable from "./RefralViewTable";

const RefralViewMain = () => {
  const data = [
    {
      names: "Username",
      value: "Parag Barole",
    },
    {
      names: "RegesterAt",
      value: "22-11-2024",
    },
    {
      names: "Rewards",
      value: "ABC",
    },
    {
      names: "Coupen Code",
      value: "ABC",
    },
    {
      names: "Wallet Amount",
      value: "ABC",
    },
  ];
  return (
    <div className="w-full px-4 space-y-4 text-sm">
      <div className="mt-4 text-lg font-semibold text-gray-700">Details</div>
      <div className="grid grid-cols-4 gap-x-4 bg-gray-50 ">
        {data?.map(({ names, value }: any, index: number) => (
          <div
            className="text-black flex flex-col border-b mt-2"
            key={`${names}-${index}`}
          >
            <div className="font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70 mb-2 uppercase text-xs text-gray-500">
              {names} :
            </div>
            <div className=" font-semibold mb-1">{value}</div>
          </div>
        ))}
      </div>
      <RefralViewTable/>
    </div>
  );
};

export default RefralViewMain;
