import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import CreateManufacturerProductForm from 'src/modules/ManufacturerProducts/CreateManufacturerProductForm'
import { INITIAL_PRODUCT_VALUES } from 'src/modules/ManufacturerProducts/module'
import { createProductApiRequest } from 'src/services/requests/Products'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'

type Props = {}

const CreateProductPage = (props: Props) => {

  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const { id } = useParams()
  const [isLoading, setisLoading] = useState<boolean>(false)

  
  const handleCreatePoduct = catchAsync(async (formdata: any) => {
    setisLoading(true)
    let postData = {
      ...formdata,
      manufacturer_id: currentUser?.id,
      current_quantity: formdata?.total_quantity
    }
    return await createProductApiRequest(postData)
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      navigate(-1)
    }
    setisLoading(false)
  }, () => { setisLoading(false) })


  return (
    <div className='p-4'>
      <CreateManufacturerProductForm initValues={INITIAL_PRODUCT_VALUES} onSubmit={(e: any) => handleCreatePoduct(e)} />
      <LoadingDialog isOpen={isLoading} message='Creating Product'  />
    </div>
  )
}

export default CreateProductPage