import { ColumnDef } from "@tanstack/react-table";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";


export const columns: ColumnDef<Task>[] = [
  {
    accessorKey: "srNo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sr.No. (#)" />
    ),
  },
  {
    accessorKey: "phonenumber",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Phone Number" />
    ),
  },

  {
    accessorKey: "energyUnits",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Units (kW)" />
    ),
  },
  {
    accessorKey: "capacity",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Capacity (kW)" />
    ),
  },
  {
    accessorKey: "ppaSigned",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="PPA signed" />
    ),
  },
  {
    accessorKey: "ppaRate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="PPA Rate" />
    ),
  },

  // {
  //   id: "Actions",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Actions" />
  //   ),
  //   cell({ row: { original } }) {
  //     return <Actions row={original} />
  //   },
  // },

];
