import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "src/shadcn/components/custom";
import { Referral } from "../data/schema";

export const view_columns: ColumnDef<Referral>[] = [
  {
    accessorKey: "srNo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sr.No. (#)" />
    ),
  },
  {
    accessorKey: "firstName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Username" />
    ),
  },
  {
    accessorKey: "register_id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Register Id" />
    ),
  },
  {
    accessorKey: "rewarded",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Rewarded" />
    ),
  },
  {
    accessorKey: "rewarded_at",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Date" />
    ),
  },
  {
    accessorKey: "Staus",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
  },

//   {
//     id: "Actions",
//     header: ({ column }) => (
//       <DataTableColumnHeader column={column} title="Actions" />
//     ),
//     cell({ row: { original } }) {
//       return <Actions row={original} />
//     },
//   },
];
