import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { MANUFACTURER_ROUTES } from 'src/navigations/modules'
import EpcContractorCreateMain from './ManufacturerCreateMain'
import EpcContractorUpdateMain from './ManufacturerUpdateMain'
import EpcContractorViewMain from './ManufacturerViewMain'

type Props = {}

const ManufacturerProfileRoutes = (props: Props) => {
    const PROJECTS_ROUTES = MANUFACTURER_ROUTES.profile.subRoutes

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route index element={<EpcContractorViewMain />} />
                <Route path={PROJECTS_ROUTES.create.route} element={<EpcContractorCreateMain />} />
                <Route path={PROJECTS_ROUTES.edit.route + "/:id"} element={<EpcContractorUpdateMain />} />
                <Route path={PROJECTS_ROUTES.view.route + "/:id"} element={<EpcContractorViewMain />} />
                <Route path={"*"} element={<Navigate to={"/" + PROJECTS_ROUTES.view.fullRoute} />} />
            </Route>
        </Routes>
    )
}

export default ManufacturerProfileRoutes