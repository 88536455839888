import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import AllSalesExecutivesMain from './AllSalesExecutivesMain'
import CreateSalesExecutive from './CreateSalesExecutive'
import EditSalesExecutive from './EditSalesExecutive'
import ViewSalesExecutive from './ViewSalesExecutive'


type Props = {}

const SalesExecutivesRoute = (props: Props) => {

    const ROUTES = APP_ROUTES.sales_executive.subRoutes.users.subRoutes

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route index element={<AllSalesExecutivesMain />} />
                <Route path={ROUTES.all.route} element={<AllSalesExecutivesMain />} />
                <Route path={ROUTES.create.route} element={<CreateSalesExecutive />} />
                <Route path={ROUTES.edit.route + "/:id"} element={<EditSalesExecutive />} />
                <Route path={ROUTES.view.route + "/:id"} element={<ViewSalesExecutive />} />
                <Route path={"*"} element={<Navigate to={"/" + APP_ROUTES.sales_executive.fullRoute} />} />
            </Route>
        </Routes>
    )
}

export default SalesExecutivesRoute