import { AxiosResponse } from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE, ROLES } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { PROJECT_FORM_INITIAL_VALUES } from 'src/modules/Projects/modules'
import ReviewProjectForm from 'src/modules/Projects/ReviewProjectForm'
import { APP_ROUTES } from 'src/navigations/modules'
import { approveProjectByVoterApiRequest, getProjectsDetailsByIDApiRequest } from 'src/services/requests/Projects'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}

const ViewProjectMain = (props: Props) => {

  const [projectDetails, setprojectDetails] = useState(PROJECT_FORM_INITIAL_VALUES)
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const { id } = useParams()
  const [fetchingProjectDetails, setfetchingProjectDetails] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [commentField,setCommentField] = useState<{
    comment: string,
    isError: boolean,
    message: string
  }>({
    comment: "",
    isError: false,
    message: ""
  })
  const [projectData, setprojectData] = useState<any>({})

  useEffect(() => {
    if (id) handleFetchProjectDetails()
    else navigate("/" + APP_ROUTES.projects.subRoutes.all.fullRoute)
    return () => { }
  }, [])


  const handleFetchProjectDetails = catchAsync(async () => { 
    setfetchingProjectDetails(true)
    return id && await getProjectsDetailsByIDApiRequest(id) 
  }, (result: AxiosResponse) => {
    console.log({ getProjectDetailsByID: result });
    setprojectData(({...result.data,pricePerKwh:result.data?.pricePerKwh}))
    setfetchingProjectDetails(false)
  },()=>{
    setfetchingProjectDetails(false)
  })

  const handleProjectStatusSubmit = catchAsync(async (status: string) => {
    setisLoading(true)
    if (currentUser?.role && currentUser.role === ROLES.STAKEHOLDER) {
      return await approveProjectByVoterApiRequest({
        vote: status,
        project_id: projectData?.project_id,
        userid: currentUser.id,
      })
    }
  }, (result: any) => {
    setisLoading(false)
    if (IS_SUCCESS_RESPONSE(200)) {
      handleFetchProjectDetails()
      navigate(-1)
    }
  }, () => {
    setisLoading(false)
  })

  const isDisabled = useMemo(() => {
     if (currentUser?.role && currentUser?.role && currentUser.role === ROLES.STAKEHOLDER) {
      const findStackHolder = projectData.stakeholders_details?.find((s: any) => s.userid === currentUser.id)
      if (findStackHolder) {
        if (["Yes", "No", "approved", "rejected"].includes(findStackHolder.evaluation_status)) {
          return true
        }
      }
    }
    return false

  }, [currentUser, ROLES, projectData])

  
  return (
    <div className='m-4'>
      {
        isDisabled && <div className='bg-yellow-50 border-yellow-600 p-2 text-sm font-semibold'>
          You already performed.
        </div>
      }
      <ReviewProjectForm data={projectData} />
      {/* {
        currentUser?.role && currentUser.role === ROLES.EVALUATOR && !projectData.evaluation_details?.evaluation_status ?
          <div className='flex flex-col space-y-2'>
            <Label className='uppercase font-semibold text-sm my-2'>Remark</Label>
            <Textarea placeholder='Enter remark here' rows={2} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setCommentField({
              ...commentField,comment:e.target.value,isError:false,message:""
            })} />
            <div className='text-destructive'>{commentField.isError ? commentField.message : null}</div>
          </div> : null
      } */}
      <div className='flex space-x-2 justify-center my-4'>
        <RoundedButton className='my-2' onClick={() => navigate(-1)} variant={'secondary'}>Back</RoundedButton>
        {currentUser?.role && [ROLES.STAKEHOLDER].includes(currentUser?.role) ? <div className='flex space-x-2'>
          <RoundedButton disabled={isDisabled} isLoading={isLoading} className='my-2' variant={'secondary'} onClick={() => {
            if (currentUser.role && currentUser.role === ROLES.STAKEHOLDER) {
              handleProjectStatusSubmit("No")
            }
          }} >Reject</RoundedButton>
          <RoundedButton disabled={isDisabled} isLoading={isLoading} className='my-2' onClick={() => {
            // if (currentUser.role && currentUser.role === ROLES.EVALUATOR) {
            //   if(!commentField.comment){
            //     setCommentField({
            //       ...commentField,
            //       isError:true,
            //       message:"Comment is required"
            //     })
            //   }
            //   else handleProjectStatusSubmit("approved")
            // }
            // else 
            if (currentUser.role && currentUser.role === ROLES.STAKEHOLDER) {
              handleProjectStatusSubmit("Yes")
            }
          }} >Approve</RoundedButton>
        </div> : null}
        <LoadingDialog isOpen={fetchingProjectDetails} />

      </div>
    </div >
  )
}

export default ViewProjectMain