import { IndianRupee } from "lucide-react";
import { FormField, NAME_REGEX } from "src/helpers";
import { APP_ROUTES, MAIN_ROUTES } from "src/navigations/modules";
import { ADMIN_BASE_URL } from "src/services/consts/api_const";

export const ORDER_DETAILS_FORM_FIELDS: FormField[] = [
  {
    label: 'Order ID',
    dataKey: 'orderUUId',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Order ID',
      maxLength: 50,
      required: true,
      regex: /[^a-zA-Z0-9-]+/g // Assuming Order ID can contain alphanumeric characters and hyphens
    },
  },
  {
    label: 'Username',
    dataKey: 'username',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Username',
      maxLength: 150,
      required: true,
      regex: NAME_REGEX
    },
  },
  {
    label: 'User Email',
    dataKey: 'userEmail',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Username',
      maxLength: 150,
      required: true,
      regex: NAME_REGEX
    },
  },
  {
    label: 'Order Date',
    dataKey: 'createdAt',
    componentType: 'date_picker',
    componentProps: {
      placeholder: 'Select Order Date',
      required: true,
      disablePreviousDates: new Date().toString() // Prevent selection of past dates
    },
  },
  {
    label: 'Order Amount',
    dataKey: 'total_price',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Order Amount',
      inputPrefixIcon: <IndianRupee size={16} />, // Assuming Indian Rupee symbol
      required: true
    },
  },

  {
    label: 'Payment Status',
    dataKey: 'orderAmount',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Order Amount',

      required: true
    },
  },
  {
    label: 'Order Status',
    dataKey: 'orderAmount',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Order Amount',

      required: true
    },
  },


]

export interface OrderForm {
  orderId: string;
  orderUUId: string
  orderDate: string;
  createdAt: string;
  total_price: number;
  username: string;
  userEmail: string;
  transactionId: string;
  serviceAndProducts: string;
  orderStatus: string,
  paymentStatus: string
}

export const INITIAL_ORDER_FORM: OrderForm = {
  orderId: '',
  orderUUId: "",
  orderDate: "",
  createdAt: "",
  total_price: 0,
  username: '',
  userEmail: "",
  transactionId: '',
  serviceAndProducts: '',
  orderStatus: "",
  paymentStatus: ""
};

export interface CouponForm {
  couponId: string;
  couponCode: string;
  activityStatus: string;
  date: string;
  discountedAmount: number;
}

export const INITIAL_COUPON_FORM: CouponForm = {
  couponId: '',
  couponCode: '',
  activityStatus: 'Active',
  date: "",
  discountedAmount: 0,
};

export const COUPON_FORM_FIELDS: FormField[] = [
  {
    label: 'Coupon ID',
    dataKey: 'couponId',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Coupon ID',
      maxLength: 50,
      required: true,
    },
  },
  {
    label: 'Coupon Code',
    dataKey: 'couponCode',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Coupon Code',
      maxLength: 50,
      required: true,
    },
  },
  {
    label: 'Activity Status',
    dataKey: 'activityStatus',
    componentType: 'input',
    componentProps: {
      required: true,
    },
  },
  {
    label: 'Date',
    dataKey: 'date',
    componentType: 'input',
    componentProps: {
      placeholder: 'Select Date',
      required: true,
    },
  },
  {
    label: 'Discounted Amount',
    dataKey: 'discountedAmount',
    componentType: 'input',
    componentProps: {
      type: "text",
      numberFormatted: true,
      placeholder: 'Discounted Amount',
      inputPrefixIcon: <IndianRupee size={16} />,
      required: true
    },
  },
];

export interface TransactionForm {
  transactionId: string;
  orderId: string;
  couponOrWallet: string;
  finalStatus: string;
  date: string;
  value: number;
}

export const INITIAL_TRANSACTION_FORM: TransactionForm = {
  transactionId: '',
  orderId: '',
  couponOrWallet: '',
  finalStatus: 'Pending',
  date: "",
  value: 0,
};

export const TRANSACTION_FORM_FIELDS: FormField[] = [
  {
    label: 'Transaction ID',
    dataKey: 'transactionId',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Transaction ID',
      maxLength: 50,
      required: true,

    },
  },
  {
    label: 'Order ID',
    dataKey: 'orderId',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Order ID',
      maxLength: 50,
      required: true,

    },
  },
  {
    label: 'Coupon/Wallet',
    dataKey: 'couponOrWallet',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Coupon/Wallet',
      maxLength: 50,
      required: true,

    },
  },
  {
    label: 'Final Status',
    dataKey: 'finalStatus',
    componentType: 'input',
    componentProps: {
      required: true,
    },
  },
  {
    label: 'Date',
    dataKey: 'date',
    componentType: 'input',
    componentProps: {
      placeholder: 'Select Date',
      required: true,
    },
  }
];


export interface WalletTransactionForm {
  walletReferenceId: string;
  orderId: string;
  walletAmount: number;
  activityStatus: string;
  date: string;
}

export const INITIAL_WALLET_TRANSACTION_FORM: WalletTransactionForm = {
  walletReferenceId: '',
  orderId: '',
  walletAmount: 0,
  activityStatus: 'Pending',
  date: "",
};

export const WALLET_TRANSACTION_FORM_FIELDS: FormField[] = [
  {
    label: 'Wallet Reference ID',
    dataKey: 'walletReferenceId',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Wallet Reference ID',
      maxLength: 50,
      required: true,
    },
  },
  {
    label: 'Order ID',
    dataKey: 'orderId',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Order ID',
      maxLength: 50,
      required: true,
    },
  },
  {
    label: 'Wallet Amount',
    dataKey: 'walletAmount',
    componentType: 'input',
    componentProps: {
      type: "text",
      numberFormatted: true,
      placeholder: 'Wallet Amount',
      inputPrefixIcon: <IndianRupee size={16} />,
      required: true
    },
  },
  {
    label: 'Activity Status',
    dataKey: 'activityStatus',
    componentType: 'input',
    componentProps: {
      required: true,
    },
  },
  {
    label: 'Date',
    dataKey: 'date',
    componentType: 'date_picker',
    componentProps: {
      placeholder: 'Select Date',
      required: true,
      disablePreviousDates: new Date().toString() // Prevent selection of past dates
    },
  },
];


export const SERVICE_DETAILS_FORM_FIELDS: FormField[] = [
  {
    label: 'Service ID',
    dataKey: 'serviceUUId',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Order ID',
      maxLength: 50,
      required: true,
      regex: /[^a-zA-Z0-9-]+/g // Assuming Order ID can contain alphanumeric characters and hyphens
    },
  },
  {
    label: 'Project ID',
    dataKey: 'serviceUUId',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Order ID',
      maxLength: 50,
      required: true,
      regex: /[^a-zA-Z0-9-]+/g // Assuming Order ID can contain alphanumeric characters and hyphens
    },
  },
  {
    label: 'Service Type',
    dataKey: 'projectID',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Username',
      maxLength: 150,
      required: true,
      regex: NAME_REGEX
    },
  },

  {
    label: 'Service Amount',
    dataKey: 'servicePrice',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Order Amount',
      inputPrefixIcon: <IndianRupee size={16} />, // Assuming Indian Rupee symbol
      required: true
    },
  },

]

export interface ServiceForm {
  _id: string;
  serviceUUId: string
  serviceType: string | null;
  servicePrice: string;
  projectID: string;
}

export const INITIAL_SERVICE_FORM: ServiceForm = {
  _id: '',
  serviceUUId: "",
  serviceType: "",
  servicePrice: "",
  projectID: ""
};


export const SERVICE_FORM_LINKS = [
  {
    dataKey: "projectID",
    linkTitle: "View Project Details",
    link: APP_ROUTES.projects.subRoutes.view,
    linkParamsDataKey: "projectID",
    linkQuery: ""
  }
]
