import OrderDetails from 'src/modules/Orders/OrderDetails/OrderDetails'

const OrderDetailsMain = () => {
  return (
    <div className='w-full space-y-4'>
        <OrderDetails/>
    </div>
  )
}

export default OrderDetailsMain