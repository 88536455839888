import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync } from 'src/helpers'
import EnquiryReviewForm from 'src/modules/EnquiryRequests/EnquiryReviewForm'
import { APP_ROUTES } from 'src/navigations/modules'
import { getEnquiryRequestDetailByIDApiRequest } from 'src/services/requests/EnquiryRequests'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}

const ViewEnquiryRequestMain = (props: Props) => {

  const navigate = useNavigate()
  const { id } = useParams()
  const [fetchingProjectDetails, setfetchingProjectDetails] = useState(false)

  const [projectData, setprojectData] = useState<any>({})

  useEffect(() => {
    if (id) handleFetchDetails()
    else navigate("/" + APP_ROUTES.enquiryRequests.subRoutes.all.fullRoute)
    return () => { }
  }, [])


  const handleFetchDetails = catchAsync(async () => { 
    setfetchingProjectDetails(true)
    return id && await getEnquiryRequestDetailByIDApiRequest(id) 
  }, (result: AxiosResponse) => {
    console.log({ getProjectDetailsByID: result });
    setprojectData(({...result.data,pricePerKwh:result.data?.pricePerKwh}))
    setfetchingProjectDetails(false)
  },()=>{
    setfetchingProjectDetails(false)
  })


  
  return (
    <div className='m-4'>
      <EnquiryReviewForm data={projectData} />
      <div className='flex space-x-2 justify-center my-4'>
        <RoundedButton className='my-2' onClick={() => navigate(-1)} variant={'secondary'}>Back</RoundedButton>
        <LoadingDialog isOpen={fetchingProjectDetails} />

      </div>
    </div >
  )
}

export default ViewEnquiryRequestMain