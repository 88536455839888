

export const MAIN_ROUTES = {
    welcome: "welcome",
    auth: "auth",
    login: "login",
    signup: "signup",
    forgot_password: "forgot-password",
    verify_otp: "verify-otp",
    reset_password: "reset-password",
    auth_login: "/auth/login",
    auth_signup: "/auth/signup",
    auth_forgot_password: "/auth/forgot-password",
    auth_verify_otp: "/auth/verify-otp",
    auth_reset_password: "/auth/reset-password",
}

export const APP_ROUTES = {
    dashboard: {
        route: "dashboard",
        routeType: "static",
        fullRoute: "dashboard",
        breadcrumbTitle: "Dashboard",
        breadcrumbPath: "Dashboard /",
        subRoutes: undefined
    },
    epc_contractors: {
        route: "epc-contractors",
        routeType: "static",
        fullRoute: "epc-contractors",
        breadcrumbTitle: "EPC-Contractors",
        breadcrumbPath: "EPC-Contractors /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "epc-contractors/create",
                breadcrumbTitle: "Create EPC Contractor",
                breadcrumbPath: "EPC-contractors / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "epc-contractors/edit",
                breadcrumbTitle: "Edit EPC Contractor",
                breadcrumbPath: "EPC-contractors / edit",
            },
            view: {
                route: "/view-details",
                routeType: "dynamic",
                fullRoute: "epc-contractors/view-details",
                breadcrumbTitle: "View EPC Contractor Details",
                breadcrumbPath: "EPC-contractors / view",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "epc-contractors/",
                breadcrumbTitle: "EPC Contractors",
                breadcrumbPath: "EPC-contractors /",
            }
        }
    },
    manufacturers: {
        route: "manufacturers",
        routeType: "static",
        fullRoute: "manufacturers",
        breadcrumbTitle: "Manufacturers",
        breadcrumbPath: "Manufacturers /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "manufacturers/create",
                breadcrumbTitle: "Create Manufacturer",
                breadcrumbPath: "Manufacturer / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "manufacturers/edit",
                breadcrumbTitle: "Manufacturer Project",
                breadcrumbPath: "Manufacturer / edit",
            },
            view: {
                route: "/view-details",
                routeType: "dynamic",
                fullRoute: "manufacturers/view-details",
                breadcrumbTitle: "View Manufacturer Details",
                breadcrumbPath: "Manufacturer / view",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "manufacturers/",
                breadcrumbTitle: "Manufacturers",
                breadcrumbPath: "Manufacturer /",
            }
        }
    },
    projects: {
        route: "projects",
        routeType: "static",
        fullRoute: "projects",
        breadcrumbTitle: "Projects",
        breadcrumbPath: "Projects /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "projects/create",
                breadcrumbTitle: "Create Project",
                breadcrumbPath: "Projects / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "projects/edit",
                breadcrumbTitle: "Edit Project",
                breadcrumbPath: "Projects / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "projects/view",
                breadcrumbTitle: "View Project Details",
                breadcrumbPath: "Projects / view",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "projects/",
                breadcrumbTitle: "Projects",
                breadcrumbPath: "Projects /",
            }
        }
    },
    sales_executive: {
        route: "sales-executive",
        routeType: "static",
        fullRoute: "sales-executive",
        breadcrumbTitle: "Sales Executive",
        breadcrumbPath: "Sales Executive /",
        subRoutes: {
            users: {
                route: "user",
                routeType: "static",
                fullRoute: "sales-executive/user",
                breadcrumbTitle: "Sales Executives Users",
                breadcrumbPath: "Sales Executive / Users",
                subRoutes: {
                    create: {
                        route: "create",
                        routeType: "static",
                        fullRoute: "sales-executive/user/create",
                        breadcrumbTitle: "Create Sales Executive",
                        breadcrumbPath: "Sales Executive / Create",
                    },
                    edit: {
                        route: "/edit",
                        routeType: "dynamic",
                        fullRoute: "sales-executive/user/edit",
                        breadcrumbTitle: "Edit Sales Executive",
                        breadcrumbPath: "Sales Executive / Edit",
                    },
                    view: {
                        route: "/view",
                        routeType: "dynamic",
                        fullRoute: "sales-executive/user/view",
                        breadcrumbTitle: "View Sales Executive",
                        breadcrumbPath: "Sales Executive / View",
                    },
                    all: {
                        route: "/",
                        routeType: "static",
                        fullRoute: "sales-executive/user",
                        breadcrumbTitle: "Sales Executives",
                        breadcrumbPath: "Sales Executive / ",
                    },
                }
            },
            projects: {
                route: "projects",
                routeType: "static",
                fullRoute: "sales-executive/projects",
                breadcrumbTitle: "Sales Executives Projects",
                breadcrumbPath: "Sales Executive / Projects",
                subRoutes: {
                    create: {
                        route: "/create",
                        routeType: "static",
                        fullRoute: "sales-executive/projects/create",
                        breadcrumbTitle: "Create Project",
                        breadcrumbPath: "Sales Executive / Projects / Create",
                    },
                    edit: {
                        route: "/edit",
                        routeType: "dynamic",
                        fullRoute: "sales-executive/projects/edit",
                        breadcrumbTitle: "Edit Project",
                        breadcrumbPath: "Sales Executive / Projects / Edit",
                    },
                    view: {
                        route: "/view",
                        routeType: "dynamic",
                        fullRoute: "sales-executive/projects/view",
                        breadcrumbTitle: "View Project Details",
                        breadcrumbPath: "Sales Executive / Projects / View",
                    },
                    all: {
                        route: "/",
                        routeType: "static",
                        fullRoute: "sales-executive/projects",
                        breadcrumbTitle: "Surveyors Projects",
                        breadcrumbPath: "Sales Executive / Projects /",
                    }
                }
            },
            reports: {
                route: "reports",
                routeType: "static",
                fullRoute: "sales-executive/reports",
                breadcrumbTitle: "Sales Executives Reports",
                breadcrumbPath: "Sales Executive / Reports",
                subRoutes: {
                    all: {
                        route: "/",
                        routeType: "static",
                        fullRoute: "sales-executive/reports",
                        breadcrumbTitle: "Surveyors reports",
                        breadcrumbPath: "Sales Executive / Reports /",
                    }
                }
            }
        }
    },
    investors: {
        route: "investors",
        routeType: "static",
        fullRoute: "investors",
        breadcrumbTitle: "Investor",
        breadcrumbPath: "Investor /",
        subRoutes: {
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "investors/view",
                breadcrumbTitle: "View Investor",
                breadcrumbPath: "Investor / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "investors/",
                breadcrumbTitle: "Investors",
                breadcrumbPath: "Investor / ",
            },
        }
    },
    profile: {
        route: "profile",
        routeType: "static",
        fullRoute: "profile",
        breadcrumbTitle: "Profile",
        breadcrumbPath: "Profile /",
        subRoutes: undefined
    },
    orders: {
        route: "order",
        routeType: "static",
        fullRoute: "order",
        breadcrumbTitle: "Order",
        breadcrumbPath: "Order /",
        subRoutes: {
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "order/view",
                breadcrumbTitle: "View Order Details",
                breadcrumbPath: "Order / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "order/",
                breadcrumbTitle: "Order",
                breadcrumbPath: "Order / ",
            },
        }
    },
    landOnboard: {
        route: "land-onbording",
        routeType: "static",
        fullRoute: "land-onbording",
        breadcrumbTitle: "Land Onbording Requests",
        breadcrumbPath: "Land Onbording Requests /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "land-onbording/create",
                breadcrumbTitle: "Create Land Onbording Request",
                breadcrumbPath: "Land Onbording Request / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "land-onbording/edit",
                breadcrumbTitle: "Edit Project",
                breadcrumbPath: "Land Onbording Request / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "land-onbording/view",
                breadcrumbTitle: "View Land Onbording Request Details",
                breadcrumbPath: "Land Onbording Request  / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "land-onbording/",
                breadcrumbTitle: "Land Onbording Request ",
                breadcrumbPath: "Land Onbording Request  / ",
            },
        }
    },
    rooftop: {
        route: "rooftop",
        routeType: "static",
        fullRoute: "rooftop",
        breadcrumbTitle: "Roof-Top Requests",
        breadcrumbPath: "Roof-Top Requests /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "rooftop/create",
                breadcrumbTitle: "Create Roof-Top Request",
                breadcrumbPath: "Roof-Top Request / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "rooftop/edit",
                breadcrumbTitle: "Edit Project",
                breadcrumbPath: "Roof-Top Request / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "rooftop/view",
                breadcrumbTitle: "View Roof-Top Request Details",
                breadcrumbPath: "Roof-Top Request  / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "rooftop/",
                breadcrumbTitle: "Roof-Top Request ",
                breadcrumbPath: "Roof-Top Request  / ",
            },
        }
    },
    newConstructedProjects: {
        route: "new-constructed-projects",
        routeType: "static",
        fullRoute: "new-constructed-projects",
        breadcrumbTitle: "New Constructed Projects Requests",
        breadcrumbPath: "New Constructed Projects Requests /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "new-constructed-projects/create",
                breadcrumbTitle: "Create New Constructed Projects Request",
                breadcrumbPath: "New Constructed Projects Request / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "new-constructed-projects/edit",
                breadcrumbTitle: "Edit Project",
                breadcrumbPath: "New Constructed Projects Request / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "new-constructed-projects/view",
                breadcrumbTitle: "View New Constructed Projects Request Details",
                breadcrumbPath: "New Constructed Projects Request  / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "new-constructed-projects/",
                breadcrumbTitle: "New Constructed Projects Request ",
                breadcrumbPath: "New Constructed Projects Request  / ",
            },
        }
    },
    projectRights: {
        route: "project-rights",
        routeType: "static",
        fullRoute: "project-rights",
        breadcrumbTitle: "Project Rights Requests",
        breadcrumbPath: "Project Rights Requests /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "project-rights/create",
                breadcrumbTitle: "Create Project Rights Request",
                breadcrumbPath: "Project Rights Request / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "project-rights/edit",
                breadcrumbTitle: "Edit Project",
                breadcrumbPath: "Project Rights Request / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "project-rights/view",
                breadcrumbTitle: "View Project Rights Request Details",
                breadcrumbPath: "Project Rights Request  / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "project-rights/",
                breadcrumbTitle: "Project Rights Request ",
                breadcrumbPath: "Project Rights Request  / ",
            },
        }
    },
    sellExistingProjects: {
        route: "sell-existing-projects",
        routeType: "static",
        fullRoute: "sell-existing-projects",
        breadcrumbTitle: "Sell Exiting Projects Requests",
        breadcrumbPath: "Sell Exiting Projects Requests /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "sell-existing-projects/create",
                breadcrumbTitle: "Create Sell Exiting Projects Request",
                breadcrumbPath: "Sell Exiting Projects Request / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "sell-existing-projects/edit",
                breadcrumbTitle: "Edit Project",
                breadcrumbPath: "Sell Exiting Projects Request / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "sell-existing-projects/view",
                breadcrumbTitle: "View Sell Exiting Projects Request Details",
                breadcrumbPath: "Sell Exiting Projects Request  / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "sell-existing-projects/",
                breadcrumbTitle: "Sell Exiting Projects Request ",
                breadcrumbPath: "Sell Exiting Projects Request  / ",
            },
        }
    },
    changePassword: {
        route: "changePassword",
        routeType: "static",
        fullRoute: "changePassword",
        breadcrumbTitle: "Change Password",
        breadcrumbPath: "Change Password /",
        subRoutes: undefined
    },
    quotationRequest: {
        route: "quotation",
        routeType: "static",
        fullRoute: "quotation",
        breadcrumbTitle: "Quotation",
        breadcrumbPath: "Quotation /",
        subRoutes: {
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "quotation/view",
                breadcrumbTitle: "View Quotation Details",
                breadcrumbPath: "Quotation / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "quotation/",
                breadcrumbTitle: "Quotation",
                breadcrumbPath: "Quotation / ",
            },
        }
    },
    enquiryRequests: {
        route: "enquiry-requests",
        routeType: "static",
        fullRoute: "enquiry-requests",
        breadcrumbTitle: "Enquiry Requests",
        breadcrumbPath: "Enquiry Requests /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "enquiry-requests/create",
                breadcrumbTitle: "Create Enquiry Request",
                breadcrumbPath: "Enquiry Request / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "enquiry-requests/edit",
                breadcrumbTitle: "Edit Enquiry",
                breadcrumbPath: "Enquiry Request / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "enquiry-requests/view",
                breadcrumbTitle: "View Enquiry Request Details",
                breadcrumbPath: "Enquiry Request  / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "enquiry-requests/",
                breadcrumbTitle: "Enquiry Request ",
                breadcrumbPath: "Enquiry Request  / ",
            },
        }
    },
    referAndEarnRequests: {
        route: "refer-and-earn-requests",
        routeType: "static",
        fullRoute: "refer-and-earn-requests",
        breadcrumbTitle: "Refer And Earn Requests",
        breadcrumbPath: "Refer And Earn Requests /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "refer-and-earn-requests/create",
                breadcrumbTitle: "Create Refer And Earn Request",
                breadcrumbPath: "Refer And Earn Request / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "refer-and-earn-requests/edit",
                breadcrumbTitle: "Edit Refer And Earn",
                breadcrumbPath: "Refer And Earn Request / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "refer-and-earn-requests/view",
                breadcrumbTitle: "View Refer And Earn Request Details",
                breadcrumbPath: "Refer And Earn Request  / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "refer-and-earn-requests/",
                breadcrumbTitle: "Refer And Earn Request ",
                breadcrumbPath: "Refer And Earn Request  / ",
            },
        }
    },
    p2pRequests: {
        route: "p2p-requests",
        routeType: "static",
        fullRoute: "p2p-requests",
        breadcrumbTitle: "P2P Requests",
        breadcrumbPath: "P2P Requests /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "p2p-requests/create",
                breadcrumbTitle: "Create P2P Request",
                breadcrumbPath: "P2P Request / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "p2p-requests/edit",
                breadcrumbTitle: "Edit P2P",
                breadcrumbPath: "P2P Request / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "p2p-requests/view",
                breadcrumbTitle: "View P2P Request Details",
                breadcrumbPath: "P2P Request  / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "p2p-requests/",
                breadcrumbTitle: "P2P Request ",
                breadcrumbPath: "P2P Request  / ",
            },
        }
    },
    referral: {
        route: "referral",
        routeType: "static",
        fullRoute: "referral",
        breadcrumbTitle: "Referral Requests",
        breadcrumbPath: "Referral Requests /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: " referral/create",
                breadcrumbTitle: "Create  Referral Request",
                breadcrumbPath: "Referral Request / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: " referral/edit",
                breadcrumbTitle: "Edit Referral",
                breadcrumbPath: "Referral Request / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "referral/view",
                breadcrumbTitle: "View Referral Request Details",
                breadcrumbPath: "Referral Request  / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "referral/",
                breadcrumbTitle: "Referral Request ",
                breadcrumbPath: "Referral Request/ ",
            },
        }
    },
    wallets: {
        route: "wallets",
        routeType: "static",
        fullRoute: "wallets",
        breadcrumbTitle: "Wallets Requests",
        breadcrumbPath: "Wallets Requests /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: " wallets/create",
                breadcrumbTitle: "Create  Wallets Request",
                breadcrumbPath: " Wallets / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "wallets/edit",
                breadcrumbTitle: "Edit Wallets",
                breadcrumbPath: "Wallets Request / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "wallets/view",
                breadcrumbTitle: "View Wallets Request Details",
                breadcrumbPath: "Wallets Request  / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "wallets/",
                breadcrumbTitle: "Wallets Request ",
                breadcrumbPath: "Wallets Request/ ",
            },
        }
    },

    projectEnquiry: {
        route: "project-enquiry",
        routeType: "static",
        fullRoute: "project-enquiry",
        breadcrumbTitle: "Project Enquiry",
        breadcrumbPath: "Project Enquiry /",
        subRoutes: {
            roofTopEnquiry: {
                route: "/roofTop-enquiry",
                routeType: "static",
                fullRoute: "project-enquiry/roofTop-enquiry",
                breadcrumbTitle: "Rooftop Enquiry Request",
                breadcrumbPath: "Project Enquiry / RoofTop Enquiry",
            },
            landEnquiry: {
                route: "/land-enquiry",
                routeType: "static",
                fullRoute: "project-enquiry/land-enquiry",
                breadcrumbTitle: "Land Enquiry Request",
                breadcrumbPath: "Project Enquiry / Land Enquiry",
            },
            newConstructionEnquiry: {
                route: "/new-construction-enquiry",
                routeType: "static",
                fullRoute: "project-enquiry/new-construction-enquiry",
                breadcrumbTitle: "New-construction Enquiry Request",
                breadcrumbPath: "Project Enquiry / New-construction Enquiry",
            },
            sellProjectEnquiry: {
                route: "/sell-project-enquiry",
                routeType: "static",
                fullRoute: "project-enquiry/sell-project-enquiry",
                breadcrumbTitle: "Sell Project Enquiry Request",
                breadcrumbPath: "Project Enquiry / Sell Project Enquiry",
            },
            dashboard: {
                route: "/dashboard",
                routeType: "static",
                fullRoute: "project-enquiry/dashboard",
                breadcrumbTitle: "Dashboard",
                breadcrumbPath: "Project Enquiry / Dashboard",
            },
            scheduleCallsRequests: {
                route: "/schedule-call-requests",
                routeType: "static",
                fullRoute: "project-enquiry/schedule-call-requests",
                breadcrumbTitle: "Schedule Call Requests",
                breadcrumbPath: "Project Enquiry / Schedule Call Requests",
            },
            projectInterestRequests: {
                route: "/project-interest-requests",
                routeType: "dynamic",
                fullRoute: "project-enquiry/project-interest-requests",
                breadcrumbTitle: "Project Interest Requests",
                breadcrumbPath: "Project Enquiry / Project Interest Requests",
            },
            servicesRequest: {
                route: "/services-requests",
                routeType: "dynamic",
                fullRoute: "project-enquiry/services-requests",
                breadcrumbTitle: "Services Requests",
                breadcrumbPath: "Project Enquiry / Services Requests",
            },
        }
    },

    coupen: {
        route: "coupen",
        routeType: "static",
        fullRoute: "coupen",
        breadcrumbTitle: "Coupen Requests",
        breadcrumbPath: "Coupen Requests /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "coupen/create",
                breadcrumbTitle: "Create  Coupen Request",
                breadcrumbPath: "Coupen Coupen / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "coupen/edit",
                breadcrumbTitle: "Edit Coupen",
                breadcrumbPath: "Coupen Request / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "coupen/view",
                breadcrumbTitle: "View Coupen Request Details",
                breadcrumbPath: "Coupen Request  / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "coupen/",
                breadcrumbTitle: "Coupen Request ",
                breadcrumbPath: "Coupen Request/ ",
            },
        }
    },

    payment: {
        route: "payment",
        routeType: "static",
        fullRoute: "payment",
        breadcrumbTitle: "Payment Requests",
        breadcrumbPath: "Payment Requests /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "payment/create",
                breadcrumbTitle: "Create  Payment Request",
                breadcrumbPath: " Payment / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "payment/edit",
                breadcrumbTitle: "Edit Payment",
                breadcrumbPath: "Payment Request / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "payment/view",
                breadcrumbTitle: "View Payment Request Details",
                breadcrumbPath: "Payment Request  / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "payment/",
                breadcrumbTitle: "Payment Request ",
                breadcrumbPath: "Payment Request/ ",
            },
        }
    },

}

export const EPC_CONTRACTORS_ROUTES = {
    dashboard: {
        route: "dashboard",
        routeType: "static",
        fullRoute: "dashboard",
        breadcrumbTitle: "Dashboard",
        breadcrumbPath: "Dashboard /",
        subRoutes: undefined
    },
    projects: {
        route: "projects",
        routeType: "static",
        fullRoute: "projects",
        breadcrumbTitle: "Projects",
        breadcrumbPath: "Projects /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "projects/create",
                breadcrumbTitle: "Create Project",
                breadcrumbPath: "Projects / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "projects/edit",
                breadcrumbTitle: "Edit Project",
                breadcrumbPath: "Projects / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "projects/view",
                breadcrumbTitle: "View Project Details",
                breadcrumbPath: "Projects / view",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "projects/",
                breadcrumbTitle: "Projects",
                breadcrumbPath: "Projects /",
            }
        }
    },
    sales_executive: {
        route: "sales-executive",
        routeType: "static",
        fullRoute: "sales-executive",
        breadcrumbTitle: "Sales Executive",
        breadcrumbPath: "Sales Executive /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "sales-executive/create",
                breadcrumbTitle: "Create Sales Executive",
                breadcrumbPath: "Sales Executive / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "sales-executive/edit",
                breadcrumbTitle: "Edit Sales Executive",
                breadcrumbPath: "Sales Executive / Edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "sales-executive/view",
                breadcrumbTitle: "View Sales Executive",
                breadcrumbPath: "Sales Executive / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "sales-executive",
                breadcrumbTitle: "Sales Executives",
                breadcrumbPath: "Sales Executive / ",
            },
        }
    },
    profile: {
        route: "profile",
        routeType: "static",
        fullRoute: "profile",
        breadcrumbTitle: "Profile",
        breadcrumbPath: "Profile /",
        subRoutes: {
            create: {
                route: "create",
                routeType: "static",
                fullRoute: "profile/create",
                breadcrumbTitle: "Complete Profile",
                breadcrumbPath: "EPC Contractor / Complete Profile",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "profile/edit",
                breadcrumbTitle: "Edit EPC Contractor",
                breadcrumbPath: "EPC Contractor / Edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "profile/view",
                breadcrumbTitle: "View EPC Contractor",
                breadcrumbPath: "EPC Contractor / View",
            },

            all: {
                route: "/",
                routeType: "static",
                fullRoute: "profile",
                breadcrumbTitle: "EPC Contractors",
                breadcrumbPath: "EPC Contractor / ",
            },
        }
    },
    changePassword: {
        route: "changePassword",
        routeType: "static",
        fullRoute: "changePassword",
        breadcrumbTitle: "Change Password",
        breadcrumbPath: "Change Password /",
        subRoutes: undefined
    },
}

export const MANUFACTURER_ROUTES = {
    dashboard: {
        route: "dashboard",
        routeType: "static",
        fullRoute: "dashboard",
        breadcrumbTitle: "Dashboard",
        breadcrumbPath: "Dashboard /",
        subRoutes: undefined
    },
    products: {
        route: "products",
        routeType: "static",
        fullRoute: "products",
        breadcrumbTitle: "Products",
        breadcrumbPath: "Products /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "products/create",
                breadcrumbTitle: "Create Product",
                breadcrumbPath: "Product / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "products/edit",
                breadcrumbTitle: "Edit Product",
                breadcrumbPath: "Product / Edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "products/view",
                breadcrumbTitle: "View Product Details",
                breadcrumbPath: "Product / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "products",
                breadcrumbTitle: "Products",
                breadcrumbPath: "Products /",
            }
        }
    },
    profile: {
        route: "profile",
        routeType: "static",
        fullRoute: "profile",
        breadcrumbTitle: "Profile",
        breadcrumbPath: "Profile /",
        subRoutes: {
            create: {
                route: "create",
                routeType: "static",
                fullRoute: "profile/create",
                breadcrumbTitle: "Complete Profile",
                breadcrumbPath: "Manufacturer / Complete Profile",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "profile/edit",
                breadcrumbTitle: "Edit Manufacturer",
                breadcrumbPath: "Manufacturer / Edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "profile/view",
                breadcrumbTitle: "View Manufacturer",
                breadcrumbPath: "Manufacturer / View",
            },

            all: {
                route: "/",
                routeType: "static",
                fullRoute: "profile",
                breadcrumbTitle: "Manufacturers",
                breadcrumbPath: "Manufacturer / ",
            },
        }
    },
    changePassword: {
        route: "changePassword",
        routeType: "static",
        fullRoute: "changePassword",
        breadcrumbTitle: "Change Password",
        breadcrumbPath: "Change Password /",
        subRoutes: undefined
    },
}