import { useMainContext } from './MainContext';

type Props = {

}

const RenderSelectedValues = ({ }: Props) => {

    const { selectedValues } = useMainContext()

    return (
        <div className='flex flex-wrap flex-1'>
            {selectedValues && selectedValues.icons ? <span>{selectedValues.icons}</span> : null}
            {selectedValues && selectedValues.value ? <span>{selectedValues.label}</span> : null}
        </div>
    )
}

export default RenderSelectedValues