import { STATUS } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { Badge } from 'src/shadcn/components/ui'

type Props = {
    row: any
}

const ProjectStatus = ({ row }: Props) => {

    const { currentUser } = useAuth()

    // const status: ISTATUS = useMemo(() => {

    //     const findCurrentStakHolder = row.status
    //     if (findCurrentStakHolder) {
    //         if (findCurrentStakHolder.toLowerCase() === "approved" || findCurrentStakHolder.toLowerCase() === "Yes") {
    //             return "approved"
    //         }
    //         else if (findCurrentStakHolder.toLowerCase() === "reject" || findCurrentStakHolder.toLowerCase() === "No") {
    //             return "rejected"
    //         }
    //     }

    //     return "pending"
    // }, [currentUser, row, ROLES])


    return (
        <div>
            {row.status === STATUS.Approved ? <Badge variant={'outline_success'}>{STATUS.Approved}</Badge> : null}
            {row.status === STATUS.Rejected ? <Badge variant={'outline_destructive'}>{STATUS.Rejected}</Badge> : null}
            {row.status === STATUS.New ? <Badge variant={'secondary'}>Pending</Badge> : null}
        </div>
    )
}

export default ProjectStatus