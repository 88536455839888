import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { initQuotationRequest } from 'src/modules/QuotationRequests/modules'
import ViewQuationDetails from 'src/modules/QuotationRequests/ViewQuationDetails'
import { getQuotationRequestDetailByIDApiRequest } from 'src/services/requests/QuotationRequests'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}

const QuotationDetailPage = (props: Props) => {

    const [productData, setproductData] = useState(initQuotationRequest)
    const navigate = useNavigate()

    const { id } = useParams()
    const [isLoading, setisLoading] = useState<boolean>(false)

    useEffect(() => {
        if (id) {
            handleFetchPoductDeatilsById()
        }
        return () => { }
    }, [id])


    const handleFetchPoductDeatilsById = catchAsync(async () => {
        setisLoading(true)
        return id && await getQuotationRequestDetailByIDApiRequest(id)
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            setproductData(result.data)
        }
        setisLoading(false)
    }, () => { setisLoading(false) })

    return (
        <div className='p-4'>
            <ViewQuationDetails data={productData} />
            <div className='flex justify-center p-8 space-x-2'>
                <RoundedButton onClick={() => navigate(-1)} variant={'secondary'}>Back</RoundedButton>
            </div>
            <LoadingDialog isOpen={isLoading} message='Fetching details' />
        </div>
    )
}

export default QuotationDetailPage