import { useFormik } from 'formik'
import { ImageUp } from 'lucide-react'
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { catchAsync, checkAnyFileToBeUpload, FormField } from 'src/helpers'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import { toast } from 'src/shadcn/components/ui/use-toast'
import { INITIAL_PRODUCT_VALUES, PRODUCT_FORM_FIELDS, PRODUCT_TYPE_OPTIONS_MAPPING, productSchema, TECHNOLOGY_TYPE_OPTIONS_MAPPING } from './module'

type Props = {
  initValues: any,
  onSubmit: (e: any) => void
}

const CreateManufacturerProductForm = ({ initValues, onSubmit }: Props) => {

  const productImageFileRef = useRef<HTMLInputElement>(null)
  const [imageData, setimageData] = useState<any>(null)
  const [uploadingFiles, setUploadingFiles] = useState<boolean>(false)
  const navigation = useNavigate()


  const formik = useFormik({
    initialValues: INITIAL_PRODUCT_VALUES,
    validationSchema: productSchema,
    onSubmit(values, formikHelpers) {
      handleCheckFilesToBeUpload()
    },
  })

  useEffect(() => {
    if (initValues) {
      Object.keys(INITIAL_PRODUCT_VALUES).map((key: any) => {
        formik.setFieldValue(key, Object.keys(initValues).includes(key) ? initValues[key] : 0 || null)
      })
    }
    return () => { }
  }, [initValues])




  const handleCheckFilesToBeUpload = catchAsync(async () => {
    setUploadingFiles(true)
    return await checkAnyFileToBeUpload(formik.values)
  }, (result: any) => {
    console.log({ result });
    if (result[1]) {
      formik.setValues(result[0])
      onSubmit(result[0])
    }
    setUploadingFiles(false)

  })


  const handleFormSubmit = () => {
    if (formik.isValid) {
      formik.handleSubmit()
    }
    else {
      Object.entries(formik.errors).map(([key, value]) => {
        formik.setFieldError(key, value)
        formik.setFieldTouched(key, true)
      })
      toast({
        variant: "destructive",
        title: "Form Validation Failed",
        description: "Please enter valid data"
      })
    }
  }

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    // console.log({ files: event.target.files });
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setimageData(e.target?.result as string);
      };
      reader.readAsDataURL(file);
      formik.setFieldValue("productImage", file);
    }
    else {
      setimageData("");
      formik.setFieldValue("productImage", "");
    }
    // form.setValue("employee_profile_photo", file);
  };

  console.log({ error: formik.errors, values: formik.values, touched: formik.touched });

  const M_PRODUCT_TYPE_OPTIONS = useMemo(() => {
    if (formik.values.category) {
      return PRODUCT_TYPE_OPTIONS_MAPPING[formik.values.category as keyof object]
    }
    return []
  }, [formik.values.category])

  const M_TECHNOLOGY_TYPE_OPTIONS = useMemo(() => {
    if (formik.values.category) {
      return TECHNOLOGY_TYPE_OPTIONS_MAPPING[formik.values.category as keyof object]
    }
    return []
  }, [formik.values.category])

  return (
    <div>
      <form onSubmit={(e: any) => {
        e.preventDefault()
        handleFormSubmit()
      }}>
        <ScrollArea className='h-[75vh]'>
          <div className='grid grid-cols-6'>
            <div className='col-span-4 grid grid-cols-3 gap-4'>
              {
                Object.keys(INITIAL_PRODUCT_VALUES).map((k: any) => {
                  let formfield: any = PRODUCT_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined

                  if (formfield && formfield) {
                    if (formfield && formfield?.dataKey === "productType") {
                      formfield = {
                        ...formfield,
                        componentProps: {
                          ...formfield.componentProps,
                          options: M_PRODUCT_TYPE_OPTIONS
                        }
                      }
                    }

                    else if (formfield && formfield?.dataKey === "technology") {
                      formfield = {
                        ...formfield,
                        componentProps: {
                          ...formfield.componentProps,
                          options: M_TECHNOLOGY_TYPE_OPTIONS
                        }
                      }
                    }

                    if (formfield?.dataKey === "description") {
                      return <div className='col-span-3'>
                        {formfield && <RenderFormComponents key={`create-product-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield?.dataKey, e)} />}
                      </div>
                    }
                    return formfield && <RenderFormComponents key={`create-product-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield?.dataKey, e)} />
                  }
                  return <></>
                })
              }
            </div>
            <div className='col-span-2 p-4'>
              <input hidden ref={productImageFileRef} type='file' name={'productImage'} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue("productImage", e.target.files && e.target.files.length > 0 ? e.target.files[0] : null)
                handleImageUpload(e)
              }} />
              {
                formik.values.productImage ?
                  <img src={imageData || formik.values.productImage} className='mx-auto cursor-pointer rounded-xl ' onClick={() => {
                    productImageFileRef.current?.click()
                  }} style={{ width: "300px", height: "300px" }} />
                  : <div className='space-y-2'>
                    <div className='w-[300px] h-[300px] bg-gray-100 mx-auto rounded-xl'>
                      <button onClick={() => {
                        productImageFileRef.current?.click()
                      }} type='button' className='w-full h-full'>
                        <div className='w-full h-full flex justify-center items-center'>
                          <div className='flex flex-col items-center space-y-2'>
                            <ImageUp size={32} className='text-gray-400' />
                            <span className='text-sm text-gray-600'> Upload Product Image</span>
                          </div>
                        </div>
                      </button>
                    </div>
                    {formik.errors.productImage && formik.touched.productImage ? <div className=' text-destructive text-xs text-center'>{formik.errors.productImage}</div> : null}
                  </div>
              }
              {/* <Separator className='my-4' />
              <div className='space-y-2 px-4'>
                <div className='text-lg font-semibold '>Add-On Products</div>
                <div className='w-full border border-gray-400 rounded-xl p-2'>
                  <div className='flex w-full h-[100px] justify-center items-center bg-gray-100'>
                    <span>No Products</span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </ScrollArea>
        <div className='flex justify-center p-8 space-x-2'>
          <RoundedButton type='button' onClick={() => navigation(-1)} variant={'secondary'}>Cancel</RoundedButton>
          <RoundedButton>Submit</RoundedButton>
        </div>
      </form>
      <LoadingDialog isOpen={uploadingFiles} message='Uploading Files' />
    </div>
  )
}

export default CreateManufacturerProductForm