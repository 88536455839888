import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "src/shadcn/components/custom";
import { Referral } from "../data/schema";
import Actions from "./Actions";

export const columns: ColumnDef<Referral>[] = [
  {
    accessorKey: "srNo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sr.No. (#)" />
    ),
  },
  {
    accessorKey: "userName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Username" />
    ),
  },
  {
    accessorKey: "referralCode",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Referral Code" />
    ),
  },
  {
    accessorKey: "referral_counts",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Referral Count" />
    ),
  },
  {
    accessorKey: "wallet_amt",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Wallet Amount (₹)" />
    ),
  },

  {
    id: "Actions",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actions" />
    ),
    cell({ row: { original } }) {
      return <Actions row={original} />
    },
  },
];
