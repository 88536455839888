import { AxiosResponse } from "axios";
import { axiosInstance } from "src/services/axiosSetup";
import * as API from "src/services/consts/Coupons"


export function getAllCouponsRequestApi(query: string): Promise<any> {
    query = query ? "?" + query : ""
    return axiosInstance.get(`${API.GET_ALL_COUPONS}${query}`).then((d: AxiosResponse) => d.data);
}

export function getAllCouponsByUserRequestApi(id: string): Promise<any> {
    return axiosInstance.get(`${API.GET_COUPONS_BY_USER_ID}/${id}`).then((d: AxiosResponse) => d.data);
}
