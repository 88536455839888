import { FormField, PROJECT_CATEGORY_OPTIONS, SYSTEM_TYPE_OPTIONS } from 'src/helpers';
import { Attachments, ContactDetails, createNewProjectvalidationSchema, initialAttachments, initialContactDetails, initialLocationDetails, initialPowerConsumptionData, initialProjectData, initialProjectFinanceData, initialSiteData, LocationDetails, PowerConsumptionData, PROJECT_FORM_FIELDS, ProjectData, ProjectFinanceData, SiteData } from 'src/modules/Projects/modules';
import * as Yup from 'yup';


export interface ISiteAttachments extends Attachments {
  billFrontImage: string | File | undefined;
  billBackImage: string | File | undefined;
}

export const SiteAttachments: ISiteAttachments = {
  imagesAttachments: [],
  videoAttachments: [],
  headerImage: '',
  agreement: '',
  // aadharFrontDocument: '',
  // aadharBackDocument: '',
  // panDocument: '',
  billFrontImage: undefined,
  billBackImage: undefined
}

export interface ISiteData extends SiteData {
}

export const InitSiteData = {
  propertyType: '',
  buildingType: '',
  typeOfSpace: '',
  freeAreaAvailableOnRoofTop: '',
  freeAreaAvailableOnGround: '',
  tilt: '',
  shadingAnalysis: '',
  roofMaterial: '',
  roofAgeAndCondition: '',
  temperatureRange: '',
  solarIrradiation: '',
  heightOfPremises: "",
  // annualConsumption: "",
}

export interface ISiteDetails extends ProjectData, ISiteData, PowerConsumptionData, ContactDetails, LocationDetails, ProjectFinanceData, ISiteAttachments { }

export const initSiteDetails: ISiteDetails = {
  ...initialProjectData,
  ...initialSiteData,
  ...initialPowerConsumptionData,
  ...initialContactDetails,
  ...initialLocationDetails,
  ...initialProjectFinanceData,
  ...initialAttachments,
  ...SiteAttachments,
  ...initialSiteData,
  country: "India"
};

export const ISiteDataValidationSchema = Yup.object().shape({
  videoAttachments: Yup.mixed().test('fileType', 'Only video files are allowed', (value: any) => {
    if (value && value instanceof File) {
      // console.log({ videoAttachments: value });
      const fileType = value.type;
      // console.log({ fileType });
      return fileType.includes("video/");
    }
    else if (value && typeof value === "string") {
      return true
    }
  })
    .test('fileSize', 'File size must be less than 5MB', (value: any) => {
      if (value && value instanceof File) {
        const fileSize = value.size;
        return fileSize <= 5 * 1024 * 1024; // 5MB
      }
      else if (value && typeof value === "string") {
        return true
      }
    }).optional()
});


export const SideDetailsValidationSchema = createNewProjectvalidationSchema.concat(ISiteDataValidationSchema)

export const attachmentsFileds = ["imagesAttachments", "billBackImage", "billFrontImage", "imagesAttachment", "videoAttachment"]
export const imageFileds = ["imagesAttachments", "billBackImage", "billFrontImage", "imagesAttachment"]
export const videoFileld = ["videoAttachment"]
const defaultReadOnlyFIelds = ["requiredInvestment", "offtakerOwnershipPercentage", "tokenValidity", "payBack"]
const removeReadOnlyFIelds = [...Object.keys(initialProjectFinanceData)].filter((x: any) => !["tokenValidity", "offtakerOwnershipPercentage"].includes(x))
export const removeFIelds = ["tokenIssued"]
export const removeDecimalPlacesForIndianFormatedString = [""]

export const FORM_SEQUENCE = {
  "Power Consumption Data": initialPowerConsumptionData,
  "Project Data": initialProjectData,
  "Contact Details": initialContactDetails,
  "Location Details": initialLocationDetails,
  "Documents": SiteAttachments,
  "Site Details": initialSiteData,
  "Finance Data": initialProjectFinanceData
}

export const SITE_DETAILS_FORM_FIELDS: FormField[] = [
  {
    label: 'Project Category',
    dataKey: 'projectCategory',
    componentType: 'options',
    componentProps: {
      options: PROJECT_CATEGORY_OPTIONS,
      required: true
    },
  },
  {
    label: 'Bill Back Image',
    dataKey: 'billBackImage',
    componentType: 'file_picker',
    componentProps: {
      fileSelectionType: "multi",
      placeholder: 'Select image',
      required: true
    },
  },
  {
    label: 'Bill Front Image',
    dataKey: 'billFrontImage',
    componentType: 'file_picker',
    componentProps: {
      fileSelectionType: "multi",
      placeholder: 'Select image',
      required: true
    },
  },
  {
    label: 'System Type',
    dataKey: 'systemType',
    componentType: 'options',
    componentProps: {
      options: SYSTEM_TYPE_OPTIONS,
      required: true
    },
  },

  ...PROJECT_FORM_FIELDS.filter((fields: FormField) => !removeFIelds.includes(fields.dataKey)).map((fileds: FormField) => {
    if (defaultReadOnlyFIelds.includes(fileds.dataKey)) {
      return {
        ...fileds,
        componentProps: {
          ...fileds.componentProps,
          readonly: true
        }
      }
    }
    else if (removeReadOnlyFIelds.includes(fileds.dataKey)) {
      return {
        ...fileds,
        componentProps: {
          ...fileds.componentProps,
          readonly: false
        }
      }
    }
    else return fileds
  })
];



