import { ADMIN_BASE_URL, BASE_URL } from "../api_const";


export const ORDERS_BASE_URL = ADMIN_BASE_URL + "/orders"
export const ALL_ORDERS = ORDERS_BASE_URL 
export const ORDER_DETAILS = BASE_URL + "/order"
// export const CREATE_ORDER = ORDERS_BASE_URL + "/create"
// export const UPDATE_ORDER = ORDERS_BASE_URL + "/update"
export const GET_DETAILS_ORDER_BY_ID = ORDERS_BASE_URL + "/details"
export const ORDERS_SUMMARY = ORDERS_BASE_URL + "/summary"
