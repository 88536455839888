import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import React from 'react';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import ReactDOM from 'react-dom/client';
import 'react-photo-view/dist/react-photo-view.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import './index.css';
import { AuthProvider } from './modules/Auth/Core';
import Navigation from "./navigations";
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import { Toaster as SonarToaster } from './shadcn/components/ui/sonner';
import { Toaster } from './shadcn/components/ui/toaster';

import '@smastrom/react-rating/style.css';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Navigation />
          <Toaster />
          <SonarToaster duration={2000} position='top-center' richColors theme='light' />
        </QueryClientProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
