import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { fetchOrderDetailByIDApiRequest } from 'src/services/requests/Orders'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import DetailsView from './Component/DetailsView'
import ServiceDetails from './Component/ServiceDetails'
import { INITIAL_ORDER_FORM, INITIAL_SERVICE_FORM, INITIAL_TRANSACTION_FORM } from './modules'

type Props = {}

const OrderDetails = (props: Props) => {

  const { id } = useParams()
  const [orderDetails, setOrderDetails] = useState<any>(INITIAL_ORDER_FORM)
  const [transactionDetails, setTransactionDetails] = useState<any>(INITIAL_TRANSACTION_FORM)
  const [projectDetails, setProjectDetails] = useState<any>(INITIAL_SERVICE_FORM)
  const [isLoading, setisLoading] = useState<boolean>(false)

  useEffect(() => {
    if (id) {
      handleFetchProduucts()
    }
    return () => { }
  }, [id])


  const handleFetchProduucts = catchAsync(async () => {
    setisLoading(true)
    return fetchOrderDetailByIDApiRequest(id)
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      setisLoading(false)
      let data = result?.data[0]
      setOrderDetails(data)
      setProjectDetails({
        ...projectDetails,
        projectID: data?.project_id
      })
    }
  }, () => { setisLoading(false) })

  const handleFetchTransactionDetails = catchAsync(async () => {
    setisLoading(true)
    return fetchOrderDetailByIDApiRequest(id)
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      setisLoading(false)
      setOrderDetails(result?.data)
    }
  }, () => { setisLoading(false) })


  const handleFetchServiceDetails = catchAsync(async () => {
    setisLoading(true)
    return fetchOrderDetailByIDApiRequest(id)
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      setisLoading(false)
      setOrderDetails(result?.data)
    }
  }, () => { setisLoading(false) })



  return (
    <ScrollArea className='h-[80vh]'  >
      <DetailsView data={orderDetails} />
      {/* <TransactionDetails data={INITIAL_TRANSACTION_FORM} /> */}
      <ServiceDetails data={projectDetails} />
      {/* <CoupenDetails data={INITIAL_COUPON_FORM} />
      <WalletsDetails data={INITIAL_WALLET_TRANSACTION_FORM} /> */}
    </ScrollArea>
  )
}

export default OrderDetails