import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { catchAsync, IS_SUCCESS_RESPONSE, ROLES, successTheme } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import EPCProfileForm from 'src/modules/EpcContractors/EpcProfile/EPCProfileForm'
import { INIT_EPC_CONTRACTOR_PROFILE } from 'src/modules/EpcContractors/EpcProfile/modules'
import ServicesFrm from 'src/modules/EpcContractors/EpcProfile/ServicesFrm'
import { createUserRequestApi } from 'src/services/requests/Auth'
import { epcCreateProfileApiRequest } from 'src/services/requests/EPC/profile'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import { Separator, Tabs, TabsContent, TabsList, TabsTrigger } from 'src/shadcn/components/ui'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'

type Props = {}

const EpcContractorCreateMain = (props: Props) => {

    const [isLoading, setisLoading] = useState<boolean>(false)
    const [initFormDate, setinitFormDate] = useState(INIT_EPC_CONTRACTOR_PROFILE)
    const [resultData, setresultData] = useState<any>()
    const [allServices, setallServices] = useState<any>([])
    const [currentActiveTab, setcurrentActiveTab] = useState<string>("complete_profile")
    const navigate = useNavigate()
    const { currentUser } = useAuth()

    useEffect(() => {
        if (currentUser?.email && currentUser?.role === ROLES.EPC_CONTRACTOR) {
            setinitFormDate({
                ...initFormDate,
                contractorEmail: currentUser?.email
            })
        }

        return () => { }
    }, [])



    const handleCreateUser = catchAsync(async (formdata: any) => {
        setisLoading(true)
        return await createUserRequestApi({
            email: formdata?.contractorEmail,
            role: ROLES.EPC_CONTRACTOR
        })
    }, (result: any, formdata: any) => {

        if (result?.status === 201) {
            console.log({ formdata });
            handleCreateProfile({ ...formdata, epc_contractor_id: result?._id })
            setresultData({
                ...resultData,
                epc_id: result?._id,
            })
        }
        setisLoading(false)
    }, () => { setisLoading(false) })


    const handleCreateProfile = catchAsync(async (formdata: any) => {
        setisLoading(true)
        // console.log({ formdata });
        return formdata && await epcCreateProfileApiRequest({
            ...formdata,
            services: [],
            epc_contractor_id: currentUser?.id
        })
    }, (result: any) => {
        // console.log({ result });
        if (IS_SUCCESS_RESPONSE(result)) {
            setresultData({
                _id: result?.mongoId,
                epc_id: result?._id,
            })
            toast.success("Profile created.", { style: successTheme })
            setcurrentActiveTab("add_services")
            // navigate("/" + EPC_CONTRACTORS_ROUTES.dashboard.fullRoute)
        }
        setisLoading(false)
    }, () => {
        setisLoading(false)
    })

    // console.log({ initFormDate });


    return (
        <div className='p-4'>
            <ScrollArea className='h-[90vh]'>

                <Tabs value={currentActiveTab} onValueChange={(e: any) => setcurrentActiveTab(e)} className="">
                    <TabsList>
                        <TabsTrigger disabled={resultData?._id && resultData?.epc_id} value="complete_profile">Profile</TabsTrigger>
                        <TabsTrigger
                            disabled={!resultData?._id && !resultData?.epc_id}
                            value="add_services">Services</TabsTrigger>
                    </TabsList>
                    <TabsContent value="complete_profile">
                        <EPCProfileForm disableForm={resultData?._id} initial_values={initFormDate} submitBtnTitle='Save' onSubmit={(formdata: any) => {
                            if (currentUser?.role === ROLES.ADMIN_USER) {
                                handleCreateUser(formdata)
                            }
                            else {
                                handleCreateProfile(formdata)
                            }

                        }} />
                    </TabsContent>
                    <TabsContent value="add_services">
                        <ServicesFrm id={resultData?._id} epc_id={resultData?.epc_id} />
                    </TabsContent>
                </Tabs>

                <Separator />
                {/* <div className='uppercase text-md font-bold text-primary mt-4 '>Services </div> */}

                {/* <div className='flex justify-center space-x-2 my-2'>
                    <RoundedButton type='button' disabled={false} variant={'secondary'} onClick={() => navigate(-1)} >Back</RoundedButton>
                    <RoundedButton onClick={() => navigate("/" + EPC_CONTRACTORS_ROUTES.profile.subRoutes.view.fullRoute + "/" + currentUser?.id)} >Save</RoundedButton>
                </div> */}
            </ScrollArea>
            <LoadingDialog isOpen={isLoading} message="Profile updating..." />
        </div>
    )
}

export default EpcContractorCreateMain