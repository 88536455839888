import { Navigate, Route, Routes } from 'react-router-dom'
import { IRoutes, ROUTES } from 'src/helpers/routes'
import AppLayoutMain from 'src/modules/Layouts/App/AppLayoutMain'
import DashboardMain from 'src/pages/Dashboard/DashboardMain'
import SalesExecutiveRoutes from 'src/pages/EpcContractors/Agents/EPCAgentsRoutes'
import EPCProfileRoutes from 'src/pages/EpcContractors/Profile/EPCProfileRoutes'
import EpcProjectsRoute from 'src/pages/EpcContractors/Projects/EpcProjectsRoute'
import { EPC_CONTRACTORS_ROUTES } from './modules'

type Props = {}

type RenderRoutesProps = {
  route: IRoutes,
  role: string
}

const EPSRoutes = (props: Props) => {

  return (
    <Routes>
      <Route element={<AppLayoutMain />} >
        <Route index element={<Navigate to={ROUTES.dashboard.path} />} />
        <Route path={EPC_CONTRACTORS_ROUTES.dashboard.route} element={<DashboardMain />} />
        <Route path={EPC_CONTRACTORS_ROUTES.projects.route + "/*"} element={<EpcProjectsRoute />} />
        <Route path={EPC_CONTRACTORS_ROUTES.sales_executive.route + "/*"} element={<SalesExecutiveRoutes />} />
        <Route path={EPC_CONTRACTORS_ROUTES.profile.route+ "/*"} element={<EPCProfileRoutes />} />
        {/* {
          Object.entries(ROUTES).map(([key, value], index) => {
            return <RenderRoutes route={value as any} role={userRole} />
          })
        } */}
      </Route>
    </Routes>
  )
}

export default EPSRoutes