
const PaymentViewPageMain = () => {
    const data = [
        {
          names: "Transaction ID",
          value: "txn123456",
        },
        {
          names: "Order ID",
          value: "ord987654",
        },
        {
          names: "Coupon/Wallet",
          value: "100",
        },
        {
          names: "Card Payment",
          value: "400",
        },
        {
          names: "Total Payment",
          value: "500",
        },
        {
          names: "Payment Status",
          value: "Success",
        },
        {
          names: "Final Status",
          value: "Approved",
        },
        {
          names: "Date",
          value: "22-11-2024",
        },
      ];
      

  return (
    <div className="w-full px-4 space-y-4">
      <div className="">Details</div>
      <div className="w-full grid grid-cols-4 gap-x-5 gap-y-10">
        {data?.map(({ names, value }: any, index: number) => (
          <div className="text-black" key={`${names}-${index}`}>
            <div className="text-sm font-normal text-black">{names} :</div>
            <div className="text-lg font-semibold ">{value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentViewPageMain;
