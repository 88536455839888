import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import InvestorDetails from 'src/modules/investors/InvestorDetails/InvestorDetails'
import { getInvestorsDetailsByIDRequestApi } from 'src/services/requests/investors'
import { IGET_INVESTOR_DETAILS_API_RESPONSE, IINVESTORS_DATA } from 'src/services/requests/investors/modules'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import AllInvestorsProjectsMain from './ProjectsByInvestors'

type Props = {}

const InvestorDetailsMain = (props: Props) => {

  const { id } = useParams()

  const [isLoading, setisLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [investorDetails, setinvestorDetails] = useState<IINVESTORS_DATA>({
    _id: "",
    email: "",
    password: "",
    role: "",
    firstName: "",
    lastName: "",
    abouteMe: "",
    mobileNumber: "",
  })

  useEffect(() => {
    if (id) handleGetInvestorDetails()
    else navigate(-1)
    return () => { }
  }, [id])


  const handleGetInvestorDetails = catchAsync(async () => {
    return await getInvestorsDetailsByIDRequestApi(id)
  }, (result: IGET_INVESTOR_DETAILS_API_RESPONSE) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      setinvestorDetails(result.data)
    }
  }, () => { })

  return (
    <div className='p-4 space-y-4'>
      <div className='space-y-2'>
        <div className='text-primary text-lg uppercase '>Investor Details</div>
        <InvestorDetails data={investorDetails} />
      </div>

      <div className='space-y-2'>
        <AllInvestorsProjectsMain investorID={id} />
      </div>
      <LoadingDialog isOpen={isLoading} message='Feting Investors Details...' />
    </div>
  )
}

export default InvestorDetailsMain