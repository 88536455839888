import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { rooftop_init } from 'src/modules/Services/Rooftop/modules'
import RooftopForm from 'src/modules/Services/Rooftop/RooftopForm'
import { ApiResponse } from 'src/services/consts/api_const'
import { getRooftopDetailByIDApiRequest, updateRooftopDetailApiRequest } from 'src/services/requests/Rooftop'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'

type Props = {}

const RooftopUpdatePage = (props: Props) => {
    const [formData, setformData] = useState(rooftop_init)
    const [fetchingUser, setfetchingUser] = useState(false)
    const [updatingUser, setupdatingUser] = useState(false)
    const { id } = useParams()
    const { currentUser } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (!id) navigate(-1)
        else handleFetchLandRegistrationRequestById()
        return () => { }
    }, [id])


    const handleUpdateLandRegistrationRequest = catchAsync(async (frmData: any) => {
        setupdatingUser(true)
        return await formData && currentUser && updateRooftopDetailApiRequest({ ...frmData, _id: id })
    }, (result: ApiResponse) => {
        console.log({ result })
        if (IS_SUCCESS_RESPONSE(result)) {
            navigate(-1)
        }
        setupdatingUser(false)
    }, () => {
        setupdatingUser(false)
    })

    const handleFetchLandRegistrationRequestById = catchAsync(async () => {
        if (id) {
            setfetchingUser(true)
            return await getRooftopDetailByIDApiRequest(id)
        }
    }, (result: ApiResponse) => {
        console.log({ result })
        if (IS_SUCCESS_RESPONSE(result)) {
            setformData(result.data)
        }
        setfetchingUser(false)
    }, () => {
        setfetchingUser(false)
    })


    console.log({formData});
    

    return (
        <div>
            <RooftopForm initial_values={formData} submitBtnTitle='Submit' isLoadingForm={updatingUser || fetchingUser} onSubmit={(e: any) => handleUpdateLandRegistrationRequest(e)} />
            <LoadingDialog isOpen={fetchingUser} message='Fetching Rooftop Details' />
            <LoadingDialog isOpen={updatingUser} message='Updating Rooftop Details' />
        </div>
    )
}

export default RooftopUpdatePage