import { ColumnDef } from "@tanstack/react-table";
import { ENUM_APPROVE_REJECT } from "src/helpers";
import { Badge } from "src/shadcn/components/ui";
import { Task } from "../data/schema";
import Actions from "./Actions";
import { DataTableColumnHeader } from "./data-table-column-header";

export const columns: ColumnDef<Task>[] = [
  {
    accessorKey: "srNo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sr.No. (#)" />
    ),
  },
  {
    accessorKey: "firstName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="First Name" />
    ),
    cell({ row: { original: { firstName, lastName } } }) {
      return <>{firstName} {lastName}</>
    },
  },
  {
    accessorKey: "phone",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Phone" />
    ),
  },
  {
    accessorKey: "email",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Email" />
    ),
  },
  // {
  //   accessorKey: "description",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Description" />
  //   ),
  // },
  // {
  //   accessorKey: "locationName",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Location Name" />
  //   ),
  // },
  // {
  //   accessorKey: "address",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Address" />
  //   ),
  // },
  // {
  //   accessorKey: "nearestCity",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Nearest City" />
  //   ),
  // },
  // {
  //   accessorKey: "distanceFromNearestCity",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Distance from Nearest City" />
  //   ),
  // },
  // {
  //   accessorKey: "directionFromNearestCity",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Direction from Nearest City" />
  //   ),
  // },
  // {
  //   accessorKey: "nearestAirport",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Nearest Airport" />
  //   ),
  // },
  // {
  //   accessorKey: "nearestBusStand",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Nearest Bus Stand" />
  //   ),
  // },
  // {
  //   accessorKey: "nearestRailwayStation",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Nearest Railway Station" />
  //   ),
  // },
  // {
  //   accessorKey: "latitude",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Latitude" />
  //   ),
  // },
  // {
  //   accessorKey: "longitude",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Longitude" />
  //   ),
  // },
  // {
  //   accessorKey: "ownershipStatus",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Ownership Status" />
  //   ),
  // },
  // {
  //   accessorKey: "acquisitionStatus",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Acquisition Status" />
  //   ),
  // },
  // {
  //   accessorKey: "expectedCompletionDate",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Expected Completion Date" />
  //   ),
  // },
  // {
  //   accessorKey: "substationName",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Substation Name" />
  //   ),
  // },
  // {
  //   accessorKey: "distanceFromSubstation",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Distance from Substation" />
  //   ),
  // },
  // {
  //   accessorKey: "currentStatus",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Current Status" />
  //   ),
  // },
  // {
  //   accessorKey: "distanceFromProjectSite",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Distance from Project Site" />
  //   ),
  // },
  // {
  //   accessorKey: "completionTimeline",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Completion Timeline" />
  //   ),
  // },
  // {
  //   accessorKey: "voltageLevel",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Voltage Level" />
  //   ),
  // },
  // {
  //   accessorKey: "totalRequiredLand",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Total Required Land" />
  //   ),
  // },
  // {
  //   accessorKey: "acquiredLand",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Total Acquired Land" />
  //   ),
  // },
  // {
  //   accessorKey: "balanceLand",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Balance Land" />
  //   ),
  // },
  // {
  //   accessorKey: "_712Report",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="7/12 Report" />
  //   ),
  // },
  // {
  //   accessorKey: "saleDeedLeaseDeed",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Sale Deed/Lease Deed" />
  //   ),
  // },
  // {
  //   accessorKey: "landUseConversionCertificate",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Land Use Conversion Certificate" />
  //   ),
  // },
  // {
  //   accessorKey: "remark",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Remark" />
  //   ),
  // },
  {
    accessorKey: "approvalReject",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Approval/Reject" />
    ),
    cell({ row: { original: { approvalReject } } }) {
      if(approvalReject === ENUM_APPROVE_REJECT.Approved){
        return <Badge variant={'outline_success'} >Approved</Badge>
      }
      else if(approvalReject === ENUM_APPROVE_REJECT.Rejected){
        return <Badge variant={'outline_destructive'} >Rejected</Badge>
      }
    },
  },
  {
    id: "Actions",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actions" />
    ),
    cell({ row: { original } }) {
      return <Actions row={original} />
    },
  },
];
