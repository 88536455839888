import { useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import { ROLES } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { EPC_CONTRACTORS_ROUTES } from 'src/navigations/modules'
import EpcContractorAgnetsCreateMain from './EpcContractorAgnetsCreateMain'
import EpcContractorAgnetsEditMain from './EpcContractorAgnetsEditMain'
import EpcContractorAgnetsMain from './EpcContractorAgnetsMain'
import EpcContractorAgnetsViewMain from './EpcContractorAgnetsViewMain'

type Props = {}

const EPCAgentsRoutes = (props: Props) => {
    const PROJECTS_ROUTES = EPC_CONTRACTORS_ROUTES.sales_executive.subRoutes

    const { currentUser } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (currentUser?.role === ROLES.EPC_CONTRACTOR && !currentUser?.profileDetails) {
            navigate("/" + EPC_CONTRACTORS_ROUTES.profile.subRoutes.create.fullRoute)
        }
        return () => { }
    }, [currentUser])


    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route index element={<EpcContractorAgnetsMain />} />
                <Route path={PROJECTS_ROUTES.all.route} element={<EpcContractorAgnetsMain />} />
                <Route path={PROJECTS_ROUTES.create.route} element={<EpcContractorAgnetsCreateMain />} />
                <Route path={PROJECTS_ROUTES.view.route + "/:id"} element={<EpcContractorAgnetsViewMain />} />
                <Route path={PROJECTS_ROUTES.edit.route + "/:id"} element={<EpcContractorAgnetsEditMain />} />
                <Route path={"*"} element={<Navigate to={"/" + PROJECTS_ROUTES.all.route} />} />
            </Route>
        </Routes>
    )
}

export default EPCAgentsRoutes