import { ImageUp } from 'lucide-react'
import { Link } from 'react-router-dom'
import { checkDecimal, componentType, EXTRACT_NUMBERS_REGEX, FormField } from 'src/helpers'
import { Label } from 'src/shadcn/components/ui'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import { IManufacturerProducts, INITIAL_PRODUCT_VALUES, PRODUCT_FORM_FIELDS } from './module'

type Props = {
    data: IManufacturerProducts
}

const RenderViewComponent = ({ value, type, formField }: { type: componentType, value: string | number | undefined, formField: FormField }) => {

    if (formField.dataKey === "termPlan") {
        if (value && Number(value) > 10) {
            value = Number((value).toString()) / 12 + " Years"
        }
        else value = value + " Years"
    }

    return <div className='flex flex-col border-b'>
        <Label className='mb-1 text-xs  text-gray-500'>{formField.label}</Label>
        {formField.componentProps?.description ? <div className='text-xs text-gray-500'>{formField.componentProps.description}</div> : null}
        <div className='text-sm flex space-x-1 items-center'>
            {formField.componentProps?.inputPrefixIcon ? formField.componentProps?.inputPrefixIcon : null}
            <div className='font-semibold mb-1  '>
                {
                    value ? <>
                        {(['input', 'inputicon', 'options', 'date_picker', "textarea"].includes(type)) && <div className=' '>
                            {formField?.componentProps?.numberFormatted ? value && checkDecimal((value).toString().replace(EXTRACT_NUMBERS_REGEX, ""), true) : value?.toString().trim()}
                        </div>}
                        {type === "file_picker" && <Link to={value as string || "#"} target='_blank' ><div className='  text-blue-400 underline underline-offset-2 cursor-pointer'>View</div></Link>}
                    </> : "-"
                }
            </div>
            {formField.componentProps?.inputSuffixIcon ? " " + formField.componentProps?.inputSuffixIcon : null}
        </div>
    </div>
}


const ViewProductDetails = ({ data }: Props) => {
    return (
        <div>

            <ScrollArea className='h-[75vh]'>
                <div className='grid grid-cols-6'>
                    <div className='col-span-4 grid grid-cols-3 gap-4'>
                        {
                            Object.keys(INITIAL_PRODUCT_VALUES).map((k: any) => {
                                let formfield: any = PRODUCT_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined

                                if (formfield && formfield) {
                                    if (formfield?.dataKey === "description") {
                                        return <div className='col-span-3'>
                                            {<RenderViewComponent key={formfield?.dataKey} formField={formfield} value={data[formfield?.dataKey as keyof object]} type={formfield?.componentType} />}
                                        </div>
                                    }
                                    return formfield && <RenderViewComponent key={formfield?.dataKey} formField={formfield} value={data[formfield?.dataKey as keyof object]} type={formfield?.componentType} />
                                }
                                return <></>
                            })
                        }
                    </div>
                    <div className='col-span-2 p-4'>
                        {
                            data.productImage ?
                                <img src={data.productImage} style={{ width: "300px", height: "300px" }} />
                                : <div className='space-y-2'>
                                    <div className='w-[300px] h-[300px] bg-gray-100 mx-auto rounded-xl'>
                                        <button className='w-full h-full'>
                                            <div className='w-full h-full flex justify-center items-center'>
                                                <div className='flex flex-col items-center space-y-2'>
                                                    <ImageUp size={32} className='text-gray-400' />
                                                </div>
                                            </div>
                                        </button>
                                    </div>

                                </div>
                        }
                    </div>
                </div>
            </ScrollArea>
      
        </div>
    )
}

export default ViewProductDetails