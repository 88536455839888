import { Route, Routes } from 'react-router-dom'
import AuthLayoutMain from 'src/modules/Layouts/Auth/AuthLayoutMain'
import ForgotPasswordMain from 'src/pages/Auth/ForgotPasswordMain'
import LoginMain from 'src/pages/Auth/LoginMain'
import ResetPasswordMain from 'src/pages/Auth/ResetPasswordMain'
import SignupMain from 'src/pages/Auth/SignupMain'
import VerifyOtpMain from 'src/pages/Auth/VerifyOtpMain'
import { MAIN_ROUTES } from './modules'

type Props = {}

const AuthRoutes = (props: Props) => {
  return (
    <Routes>
      <Route element={<AuthLayoutMain />} >
        <Route index element={<LoginMain />}  />
        <Route path={MAIN_ROUTES.login} element={<LoginMain />} />
        <Route path={MAIN_ROUTES.signup} element={<SignupMain />} />
        <Route path={MAIN_ROUTES.forgot_password} element={<ForgotPasswordMain />} />
        <Route path={MAIN_ROUTES.verify_otp} element={<VerifyOtpMain />} />
        <Route path={MAIN_ROUTES.reset_password} element={<ResetPasswordMain />} />
      </Route>
    </Routes>
  )
}

export default AuthRoutes