import { useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { catchAsync, checkAnyFileToBeUpload, checkDecimal, cn, CONNECTION_TYPE_OPTIONS, FormField, NUMBER_REGEX, optionTypes } from 'src/helpers'
import { getProjectCalculationApiRequest } from 'src/services/requests/Projects'
import { IProjectCalculationResponse } from 'src/services/requests/Projects/module'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Separator } from 'src/shadcn/components/ui'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import { toast } from 'src/shadcn/components/ui/use-toast'
import * as modules from './modules'

const { FORM_SEQUENCE, initSiteDetails, SideDetailsValidationSchema, SITE_DETAILS_FORM_FIELDS, SiteAttachments } = modules

type Props = {
    initial_values: any,
    submitBtnTitle: string,
    onSubmit: (e: any) => void
    isLoadingForm?: boolean
}

const SiteDetailsForm = ({ initial_values, onSubmit, submitBtnTitle, isLoadingForm }: Props) => {


    const navigate = useNavigate()
    const [isLoading, setisLoading] = useState(false)
    const [isLoadingProjectCalculation, setisLoadingProjectCalculation] = useState(false)
    const [calculateResponse, setcalculateResponse] = useState<any>({})

    const formik = useFormik<modules.ISiteDetails>({
        initialValues: { ...initial_values, agreement: null },
        validationSchema: SideDetailsValidationSchema,
        onSubmit(values, formikHelpers) {
            handleSubmit()
        },
    })

    useEffect(() => {
        if (initial_values) {
            handleResetForm()
        }
        return () => { }
    }, [initial_values])


    const handleResetForm = () => {
        Object.keys(initSiteDetails).filter((x: any) => !["unitConsumptionPerDay"].includes(x)).map((key: any) => {
            if (key === "unitRequiredPerMonth") {
                let monthlyUnits = (Number(initial_values["annualConsumption"]) / 12).toFixed(0)
                monthlyUnits = checkDecimal(monthlyUnits).toString()
                formik.setFieldValue(key, monthlyUnits || 0)
                let dailyConsumption = (parseFloat(monthlyUnits) / 30).toFixed(0)
                formik.setFieldValue("unitConsumptionPerDay", dailyConsumption)
                // handleProjectCalculation(monthlyUnits)
            }
            else if (key === "termPlan" && initial_values[key]) {
                if (initial_values[key as any]) {
                    let termPlan = initial_values[key as any].replace(NUMBER_REGEX, "")
                    if (termPlan > 10) {
                        formik.setFieldValue(key, Number(termPlan) / 12)
                    }
                    else initial_values[key] && formik.setFieldValue(key, (initial_values[key])?.toString().replace(NUMBER_REGEX, "") || null)
                }
            }
            else if (key === "connectionType" && initial_values[key]) {
                formik.setFieldValue(key, initial_values[key] ? CONNECTION_TYPE_OPTIONS.find((d: optionTypes) => d.value.toLowerCase() === initial_values[key]?.toLowerCase())?.value : null)
            }
            else formik.setFieldValue(key, Object.keys(initial_values).includes(key) ? initial_values[key] : 0 || null)
        })
    }


    const handleSubmit = catchAsync(async () => {
        setisLoading(true)
        return await checkAnyFileToBeUpload(formik.values)
    }, (result: any) => {
        console.log({ result });
        if (result[1]) {
            formik.setValues(result[0])
            onSubmit(result[0])
        }
        setisLoading(false)
    })


    const handleProjectCalculation = catchAsync(async (unitRequiredPerMonth: string) => {
        setisLoadingProjectCalculation(true)
        // if (["annualConsumption", "unitRequiredPerMonth"].some((d: any) => formik.values[d as keyof object] != initial_values[d])) {
            return await getProjectCalculationApiRequest({ unitRequiredPerMonth })
        // }
    }, (result: IProjectCalculationResponse) => {
        // console.log({ result });

        if (result && result?.data) {
            // console.log("here");

            let newresultData: any = {};

            result?.data && Object.entries(result?.data).forEach(([key, value]) => {
                // console.log({key,value});

                let extractNumbers = value && typeof value === "string" ? value?.toString().replace(/[^0-9.]+/g, "") : value;
                newresultData[key as keyof object] = checkDecimal(extractNumbers);
            });

            Object.entries(newresultData).map(([key, value]) => {
                if (key === 'termPlan') {
                    let termPlan = initial_values[key as any] && initial_values[key as any]?.replace(NUMBER_REGEX, "")
                    if (termPlan > 10) {
                        formik.setFieldValue(key, Number(termPlan) / 12)
                    }
                }
                else formik.setFieldValue(key, value)
            })

            // formik.setFieldValue("unitConsumptionPerDay", Number(formik.values.unitRequiredPerMonth)/12)
            formik.setFieldValue("tokenValidity", newresultData.termPlan)
            formik.setFieldValue("totalProjectCost", newresultData.ProjectTotalprice)
            formik.setFieldValue("totalSupply", newresultData.totalSupply)
            formik.setFieldValue("returnPerYearRate", newresultData.intrestRate)
            formik.setFieldValue("interestRate", newresultData.intrestRate)
            formik.setFieldValue("requiredInvestment", newresultData.ProjectTotalprice)
            formik.setFieldValue("returnPerYearAmount", newresultData.interestAmount)
            formik.setFieldValue("pricePerKwh", newresultData.pricePerKWP)
            formik.setFieldValue("country", "India")
            setcalculateResponse(newresultData)
        }
        setisLoadingProjectCalculation(false)
    }, () => {
        setisLoadingProjectCalculation(false)
    })



    const handleFormSubmit = () => {
        if (formik.isValid) {
            formik.handleSubmit()
        }
        else {
            Object.entries(formik.errors).map(([key, value]) => {
                formik.setFieldError(key, value)
                formik.setFieldTouched(key, true)
            })
            toast({
                variant: "destructive",
                title: "All fields are required, ",
                description: "kindly fill all valid data"
            })
        }
    }

    const getLastOtherCost = useMemo(() => {
        if (["projectCost"].every((d: any) => formik.values[d as keyof object] === initial_values[d])) {
            return initial_values?.otherCost ? (Number(initial_values?.projectCost || 0) - Number(initial_values?.otherCost || 0)) : 0
        }
        else return formik.values?.otherCost ? Number(formik.values?.otherCost?.toString().replace(NUMBER_REGEX, "")) : 0

    }, [initial_values, formik.values])


    console.log({ error: formik.errors, values: formik.values, touched: formik.touched });

    return (
        <div>
            <form onSubmit={(e: any) => {
                e.preventDefault()
                handleFormSubmit()
            }}>

                <ScrollArea className='h-[80vh] px-4 bg-gray-50'>
                    {
                        Object.entries(FORM_SEQUENCE).map(([key, v]) => {
                            return <div key={`${key}-section`}>
                                <div className=' uppercase text-primary text-sm mt-4 mb-2'>{key}</div>
                                <Separator className='my-2' />
                                <div className={cn('grid grid-cols-3 gap-x-4 gap-y-2 my-2 ')}>
                                    {
                                        Object.keys(v).filter((v: any) => !modules.removeFIelds.includes(v)).map((k: any) => {
                                            const formfield: FormField | undefined = SITE_DETAILS_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                                            if (formfield?.componentProps?.fileSelectionType === "multi") {
                                                return formfield && <div key={`create-project-form-field-${formfield.dataKey}`} className='col-span-3'>{<RenderFormComponents formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }}
                                                    onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />}</div>
                                            }
                                            if (formfield?.dataKey === "downpaymentByOfftaker") {
                                                return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }}
                                                    onChange={(e: any) => {
                                                        formik.setFieldValue(formfield.dataKey, e)
                                                        // aconsole.log({ e });
                                                        console.log({ e, projectCost: formik.values.totalProjectCost });
                                                        if (e) {
                                                            if (formik.values.totalProjectCost) {
                                                                formik.setFieldValue("offtakerOwnershipPercentage", ((e / Number(formik.values.totalProjectCost)) * 100).toFixed(2))
                                                                formik.setFieldValue("requiredInvestment", (Number(formik.values.totalProjectCost) - Number(e)))
                                                            }
                                                        }
                                                        else {
                                                            formik.setFieldValue("offtakerOwnershipPercentage", 0)
                                                            formik.setFieldValue("requiredInvestment", calculateResponse?.ProjectTotalprice)
                                                        }
                                                        // formik.setFieldValue("offtakerOwnershipPercentage", 0)
                                                    }}
                                                />
                                            }
                                            else if (formfield?.dataKey === "termPlan") {
                                                return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }}
                                                    onChange={(e: any) => {
                                                        formik.setFieldValue(formfield.dataKey, e)
                                                        formik.setFieldValue("payBack", Number(e) * 12)
                                                        formik.setFieldValue("tokenValidity", Number(e) * 12)
                                                    }}
                                                />
                                            }
                                            return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`}
                                                formik={formik}
                                                {...formfield}
                                                value={formik.values[formfield.dataKey as keyof object]}
                                                componentProps={{ ...formfield.componentProps }}
                                                onBlur={(e: any) => {
                                                    if (formfield.dataKey === "annualConsumption") {
                                                        if (e.target.value) {
                                                            let monthlyConsumption = (Number(e.target.value.replace(NUMBER_REGEX, "")) / 12).toFixed(0)
                                                            console.log({ monthlyConsumption });
                                                            formik.setFieldValue("unitRequiredPerMonth", monthlyConsumption)
                                                            e.target.value && handleProjectCalculation(monthlyConsumption.replace(NUMBER_REGEX, ""))
                                                            let dailyConsumption = (parseFloat(monthlyConsumption) / 30).toFixed(0)
                                                            formik.setFieldValue("unitConsumptionPerDay", dailyConsumption)
                                                        }
                                                    }
                                                }}
                                                onChange={(e: any) => {

                                                    if (formfield.dataKey === "pricePerKwh") {
                                                        formik.setFieldValue(formfield.dataKey, e)
                                                        let pricePerKwh = e || 0
                                                        let projectCost = Number((pricePerKwh).toString().replace(NUMBER_REGEX, "") || 0) * (calculateResponse?.projectSize || formik.values.projectSize || 0)
                                                        formik.setFieldValue("projectCost", projectCost)
                                                    }
                                                    else if (formfield.dataKey === "projectCost") {
                                                        formik.setFieldValue(formfield.dataKey, e)
                                                        let projectCost = e ? Number(e.toString().replace(NUMBER_REGEX, "")) : 0
                                                        let electricCost = formik.values.electicalCost ? Number(formik.values.electicalCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                                        let structureCost = formik.values.structureCost ? Number(formik.values.structureCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                                        let otherCost = getLastOtherCost
                                                        let totalProjectCost = projectCost + electricCost + structureCost + otherCost
                                                        formik.setFieldValue("projectCost", projectCost)
                                                        formik.setFieldValue("totalProjectCost", totalProjectCost)
                                                    }

                                                    else if (formfield.dataKey === "electicalCost") {
                                                        formik.setFieldValue(formfield.dataKey, e)
                                                        let projectCost = formik.values.projectCost ? Number(formik.values.projectCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                                        let electricCost = e ? Number(e?.toString().replace(NUMBER_REGEX, "")) : 0
                                                        let structureCost = formik.values.structureCost ? Number(formik.values.structureCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                                        let otherCost = getLastOtherCost
                                                        let totalProjectCost = projectCost + electricCost + structureCost + otherCost
                                                        formik.setFieldValue("totalProjectCost", totalProjectCost)

                                                    }
                                                    else if (formfield.dataKey === "structureCost") {
                                                        formik.setFieldValue(formfield.dataKey, e)
                                                        let projectCost = formik.values.projectCost ? Number(formik.values.projectCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                                        let electricCost = formik.values.electicalCost ? Number(formik.values.electicalCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                                        let structureCost = e ? Number(e?.toString().replace(NUMBER_REGEX, "")) : 0
                                                        let otherCost = getLastOtherCost
                                                        let totalProjectCost = projectCost + electricCost + structureCost + otherCost
                                                        formik.setFieldValue("totalProjectCost", totalProjectCost)
                                                    }
                                                    else if (formfield.dataKey === "otherCost") {
                                                        formik.setFieldValue(formfield.dataKey, e)
                                                        let projectCost = formik.values.projectCost ? Number(formik.values.projectCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                                        let electricCost = formik.values.electicalCost ? Number(formik.values.electicalCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                                        let structureCost = formik.values.structureCost ? Number(formik.values.structureCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                                        let otherCost = e ? Number(e?.toString().replace(NUMBER_REGEX, "")) : 0
                                                        let totalProjectCost = projectCost + electricCost + structureCost + otherCost
                                                        formik.setFieldValue("totalProjectCost", totalProjectCost)
                                                    }
                                                    else formik.setFieldValue(formfield.dataKey, e)

                                                }} />
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }

                </ScrollArea>

                <div className='my-6 flex justify-center space-x-2'>
                    <RoundedButton type='button' disabled={isLoading || isLoadingForm} variant={'secondary'} onClick={() => navigate(-1)} >Back</RoundedButton>
                    <RoundedButton type='button' disabled={isLoading || isLoadingForm} variant={'secondary'} onClick={() => handleResetForm()} >Reset Form</RoundedButton>
                    <RoundedButton type='submit' disabled={isLoading || isLoadingForm}  >{submitBtnTitle}</RoundedButton>
                </div>
            </form>
            <LoadingDialog isOpen={isLoadingProjectCalculation} message='Calculating project cost' />
            <LoadingDialog isOpen={isLoading} message='Uploading files' />
        </div>
    )
}

export default SiteDetailsForm