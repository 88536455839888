import { Plus } from 'lucide-react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROLES } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { columns } from 'src/modules/EnquiryRequests/Table/components/columns'
import { DataTable } from 'src/modules/EnquiryRequests/Table/components/data-table'
import { APP_ROUTES } from 'src/navigations/modules'
import { Button } from 'src/shadcn/components/ui'
import { QueryRequestProvider } from './AllRequests/core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponseData } from './AllRequests/core/QueryResponseProvider'

type Props = {}


const Main = () => {

  const apiResp = useQueryResponseData()
  const tableData = useMemo(() => {
    if (apiResp && Array.isArray(apiResp) && apiResp.length > 0) {
      return apiResp.map((d: any, indx: number) => {
        return ({
          ...d,
          srNo: (indx + 1)
        })
      })
    }
  }, [apiResp])
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  return (
    <div className='m-4 space-y-2'>
      {
        currentUser?.role === ROLES.ADMIN_USER && <div className='flex justify-end'>
          <Button onClick={() => navigate("/" + APP_ROUTES.projects.subRoutes.create.fullRoute)} size={"sm"} className='space-x-2'><span><Plus size={16} /></span><span>Create Project</span> </Button>
        </div>
      }
      <DataTable data={tableData || []} columns={columns} />
    </div >
  )
}

const AllEnquiryRequestMain = (props: Props) => {

  return <QueryRequestProvider>
    <QueryResponseProvider>
      <Main />
    </QueryResponseProvider>
  </QueryRequestProvider>
}

export default AllEnquiryRequestMain