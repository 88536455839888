import { AxiosResponse } from "axios";
import { axiosInstance } from "src/services/axiosSetup";
import * as API from "src/services/consts/Dashboard"


export function getAdminDashboardSummaryRequestApi(): Promise<any> {
    return axiosInstance.get(`${API.ADMIN_SUMMARY_URL}`).then((d: AxiosResponse) => d.data);
}

export function getAdminDashboardProjectsSummaryRequestApi(): Promise<any> {
    return axiosInstance.get(`${API.PROJECTS_SUMMARY_URL}`).then((d: AxiosResponse) => d.data);
}


export function getSalesExecutiveDashboardSummaryRequestApi(): Promise<any> {
    return axiosInstance.get(`${API.SALES_EXECUTIVE_SUMMARY_URL}`).then((d: AxiosResponse) => d.data);
}


export function getEvaluatorDashboardSummaryRequestApi(): Promise<any> {
    return axiosInstance.get(`${API.EVALUATOR_SUMMARY_URL}`).then((d: AxiosResponse) => d.data);
}


export function getStakeholderDashboardSummaryRequestApi(): Promise<any> {
    return axiosInstance.get(`${API.STAKEHOLDER_SUMMARY_URL}`).then((d: AxiosResponse) => d.data);
}



export function getWebsiteCountRequestApi(query: string): Promise<any> {
    return axiosInstance.get(`${API.WEBSITE_COUNT_URL}?${query}`).then((d: AxiosResponse) => d.data);
}

