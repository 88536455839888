import Login from 'src/modules/Auth/Login'

type Props = {}

const LoginMain = (props: Props) => {
    return (
        <Login />
    )
}

export default LoginMain