import ProfileComponent from 'src/modules/Profile/ProfileComponent'

type Props = {}

const ProfileMain = (props: Props) => {
  return (
    <div className='p-4'>
        <ProfileComponent />
    </div>
  )
}

export default ProfileMain