import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import ProjectRightsReviewForm from 'src/modules/Services/ProjectRights/ProjectRightsReviewForm'
import { rooftop_init } from 'src/modules/Services/Rooftop/modules'
import { ApiResponse } from 'src/services/consts/api_const'
import { getProjectRightsDetailApiRequest } from 'src/services/requests/ProjectRights'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}

const ProjectRightsViewPage = (props: Props) => {
  const [formData, setformData] = useState(rooftop_init)
  const [isLoading, setisLoading] = useState(false)
  const { id } = useParams()
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!id) navigate(-1)
    else handleFetchDetailsById()
    return () => { }
  }, [id])

  const handleFetchDetailsById = catchAsync(async () => {
    if (id) {
      setisLoading(true)
      return await getProjectRightsDetailApiRequest(id)
    }
  }, (result: ApiResponse) => {
    console.log({ result })
    if (IS_SUCCESS_RESPONSE(result)) {
      setformData(result.data)
    }
    setisLoading(false)
  }, () => {
    setisLoading(false)
  })

  return (
    <div>
      <ProjectRightsReviewForm data={formData} />
      <div className='py-2 flex justify-center'>
        <RoundedButton variant={'secondary'} onClick={() => navigate(-1)} >Back</RoundedButton>
      </div>
      <LoadingDialog isOpen={isLoading} message='Fetching Details..' />
    </div>
  )
}

export default ProjectRightsViewPage