import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import AllCouponsListPage from './AllCoupons/AllCouponsListPage'


type Props = {}

const CoupensRoutespage = (props: Props) => {
  const PROJECTS_ROUTES = APP_ROUTES.coupen.subRoutes

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<AllCouponsListPage />} />
        <Route path={PROJECTS_ROUTES.all.route} element={<AllCouponsListPage />} />
        {/* <Route path={PROJECTS_ROUTES.create.route} element={<ManufacturerCreateMain />} /> */}
        <Route path={PROJECTS_ROUTES.view.route + "/:id"} element={<>Copens Details</>} />
        {/* <Route path={PROJECTS_ROUTES.view.route + "/:id"} element={<ManufacturerViewMain />} /> */}
        <Route path={"*"} element={<Navigate to={"/" + PROJECTS_ROUTES.all.fullRoute} />} />
      </Route>
    </Routes>
  )
}

export default CoupensRoutespage