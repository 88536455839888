import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import AllSellExistingProjectsRequestsMainPage from './AllRequests/AllSellExistingProjectsRequestsMainPage'
import SellExistingProjectsCreatePage from './SellExistingProjectsCreatePage'
import SellExistingProjectsUpdatePage from './SellExistingProjectsUpdatePage'
import SellExistingProjectsViewPage from './SellExistingProjectsViewPage'

type Props = {}

const SellExistingProjectsAdminRoutes = (props: Props) => {
    const ROUTES = APP_ROUTES.projectRights.subRoutes

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route index element={<AllSellExistingProjectsRequestsMainPage />} />
                <Route path={ROUTES.all.route} element={<AllSellExistingProjectsRequestsMainPage />} />
                <Route path={ROUTES.create.route} element={<SellExistingProjectsCreatePage />} />
                <Route path={ROUTES.edit.route + "/:id"} element={<SellExistingProjectsUpdatePage />} />
                <Route path={ROUTES.view.route + "/:id"} element={<SellExistingProjectsViewPage />} />
                <Route path={"*"} element={<Navigate to={"/" + APP_ROUTES.projectRights.fullRoute} />} />
            </Route>
        </Routes>
    )
}

export default SellExistingProjectsAdminRoutes