import { ColumnDef } from "@tanstack/react-table";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";


export const columns: ColumnDef<Task>[] = [
  {
    accessorKey: "srNo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sr.No. (#)" />
    ),
  },
  {
    accessorKey: "phonenumber",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Phone Number" />
    ),
  },
  {
    accessorKey: "location",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Location" />
    ),
  },
  {
    accessorKey: "land_area",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Area (Sq.Ft.)" />
    ),
  },
  {
    accessorKey: "service_type",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Service Type" />
    ),
  },
  {
    accessorKey: "near_by_station",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Nearest Station" />
    ),
  },

  // {
  //   id: "Actions",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Actions" />
  //   ),
  //   cell({ row: { original } }) {
  //     return <Actions row={original} />
  //   },
  // },

];
