import { Eye } from 'lucide-react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'src/modules/Auth/Core'
import { APP_ROUTES } from 'src/navigations/modules'
import { setQuotationDetails } from 'src/redux/reducers/tempDataSlice'
import ToolTip from 'src/shadcn/components/custom/ToolTip'
import { Button } from 'src/shadcn/components/ui'
import { Task } from '../data/schema'

type Props = {
    row: Task
}

const Actions = ({ row }: Props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { currentUser } = useAuth()

    return (
        <div className='flex space-x-2'>
            <ToolTip tooltipContent='View project'>
                <Button size={"sm"} variant={'primaryIconBtn'} onClick={() => navigate("/" + APP_ROUTES.quotationRequest.subRoutes.view.fullRoute + "/" + row._id)} >
                    <Eye size="16" />
                </Button>
            </ToolTip>
            <Button onClick={() => {
                dispatch(setQuotationDetails({
                    // ...PROJECT_FORM_INITIAL_VALUES,
                    unitRequiredPerMonth: "",
                    contactPersonName: "",
                    contactPersonNumber: "",
                    contactPersonEmail: "",
                    address: "",
                    city: "",
                    state: "",
                    country: "",
                    pinCode: "",
                    geoGraphicalLocation: "",
                    projectCost: ""
                }))
            navigate("/"+ APP_ROUTES.projects.subRoutes.create.fullRoute)
            }} size={'xs'} variant={'outlinePrimary'}>Create Project</Button>
            {/* <ToolTip tooltipContent='Edit project'>
                <Button  size={"sm"} variant={'primaryIconBtn'} onClick={() => navigate("/" + MANUFACTURER_ROUTES.products.subRoutes.edit.fullRoute + "/" + row._id)} >
                    <Pencil size="16" />
                </Button>
            </ToolTip> */}
        </div >
    )
}

export default Actions