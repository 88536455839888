
const WalletDetailPage = () => {
  const data = [
    {
      names: "Wallet Id",
      value: "wallet12345",
    },
    {
      names: "Username",
      value: "Parag Barole",
    },
    {
      names: "Transaction Type",
      value: "Debit",
    },
    {
      names: "Amount",
      value: "500",
    },
    {
      names: "Transaction Date",
      value: "22-11-2024",
    },
    {
      names: "Order ID",
      value: "order67890",
    },
    {
      names: "Activity Type",
      value: "Purchase",
    },
  ];

  return (
    <div className="w-full px-4 space-y-4">
      <div className="">Details</div>
      <div className="w-full grid grid-cols-4 gap-x-5 gap-y-10">
        {data?.map(({ names, value }: any, index: number) => (
          <div className="text-black" key={`${names}-${index}`}>
            <div className="text-sm font-normal text-black">{names} :</div>
            <div className="text-lg font-semibold ">{value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WalletDetailPage;
