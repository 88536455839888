/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import {
  ERROR_CODES,
  PaginationState,
  WithChildren,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  stringifyRequestQuery
} from 'src/helpers'
import { allSellExitingProjectsRequestsApiRequest } from 'src/services/requests/SellExistingProjects'
import { useQueryRequest } from './QueryRequestProvider'

const QueryResponseContext = createResponseContext<any>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children, props, type, postdata }) => {

  const { state } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `ALL-SELL-EXISTING-PROJECTS-REQUESTS-GETALL-${query}`,
    async () => {
      return await allSellExitingProjectsRequestsApiRequest(query);
    },
    {
      cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false,
      refetchOnMount: false,
      // refetchOnReconnect: false,
      // retry: false,
      //  staleTime: 5000,
    }
  )

  let responseData: any = useMemo(() => response, [response])

  let isError = useMemo(() => ERROR_CODES.includes(responseData?.code) ? true : false, [responseData])
  let message = useMemo(() => ERROR_CODES.includes(responseData?.code) ? responseData?.message : '', [responseData])

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, query, isError, message, response }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response } = useQueryResponse()

  if (!response || !response?.payload || !response?.payload?.pagination) {
    return defaultPaginationState
  }

  return response?.payload?.pagination
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination
}

