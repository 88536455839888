import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { checkDecimal, componentType, EXTRACT_NUMBERS_REGEX, FormField } from "src/helpers";
import { APP_ROUTES } from "src/navigations/modules";
import { Label, Separator } from "src/shadcn/components/ui";
import { INITIAL_SERVICE_FORM, SERVICE_DETAILS_FORM_FIELDS } from "../modules";



const RenderViewComponent = ({ value, type, formField }: { type: componentType, value: string | number | undefined, formField: FormField }) => {

  if (formField.dataKey === "termPlan") {
    if (value && Number(value) > 10) {
      value = Number((value).toString()) / 12 + " Years"
    }
    else value = value + " Years"
  }

  let links: any = {}

  // if (SERVICE_FORM_LINKS.map((d) => d.dataKey).includes(formField?.dataKey)) {
  //   links = SERVICE_FORM_LINKS.find((d) => d.dataKey)
  // }

  return <div className='flex flex-col border-b'>
    <Label className='mb-1 text-xs  text-gray-500'>{formField.label}</Label>
    {formField.componentProps?.description ? <div className='text-xs text-gray-500'>{formField.componentProps.description}</div> : null}
    <div className='text-sm flex space-x-1 items-center'>
      {formField.componentProps?.inputPrefixIcon ? formField.componentProps?.inputPrefixIcon : null}
      <div className='font-semibold mb-1  '>
        {
          value && !links ? <>
            {
              (['input', 'inputicon', 'options', "textarea"].includes(type)) &&
              <div className=' '>
                {formField?.componentProps?.numberFormatted ? value && checkDecimal((value).toString().replace(EXTRACT_NUMBERS_REGEX, ""), true) : value?.toString().trim()}
              </div>
            }
            {(['date_picker'].includes(type)) &&
              <div className=''>
                {value && dayjs(value?.toString().trim()).format('DD/MM/YYYY')}
              </div>}
            {type === "file_picker" && <Link to={value as string || "#"} target='_blank' ><div className='  text-blue-400 underline underline-offset-2 cursor-pointer'>View</div></Link>}
          </> : "-"
        }
        {/* {
          value && links ? <>
            <Link to={`/${links?.link}${links?.value ? "/" + links?.value : ""}`} >{links?.linkTitle}</Link>
          </> : "-"
        } */}
      </div>
      {formField.componentProps?.inputSuffixIcon ? " " + formField.componentProps?.inputSuffixIcon : null}
    </div>
  </div>
}


const ServiceDetails = ({ data }: { data: any }) => {

  return (
    <div className="w-full px-4 space-y-4 text-sm">
      <div className=' uppercase text-primary text-sm mt-4 mb-2'>Service Details</div>
      <Separator className='my-2' />
      <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
        {
          Object.keys(INITIAL_SERVICE_FORM).filter(d => d != "projectID").map((k: any) => {
            const formfield: FormField | undefined = SERVICE_DETAILS_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
            return formfield && <RenderViewComponent key={formfield?.dataKey} formField={formfield} value={data ? data[formfield?.dataKey] : ""} type={formfield?.componentType} />
          })
        }
        <div className="flex flex-col space-y-2 border-b">
          <Label className='mb-1 text-xs  text-gray-500'>Project Details</Label>
          <Link to={"/" + APP_ROUTES.projects.subRoutes.view.fullRoute + "/" + data?.projectID}><span className="text-blue-600 underline-offset-2 ">View Project Details</span></Link>
        </div>
      </div>

    </div>
  );
};


export default ServiceDetails;
