import { Eye, EyeOff, KeySquare } from 'lucide-react';
import React, { useState } from 'react';
import { Input } from '../../ui';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
}

const PasswordInputWithIcon = ({ ...rest }: Props) => {

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <div className='flex items-center border px-2 rounded-lg focus-within:ring-1 focus-visible:ring-1 ring-primary '>
            <KeySquare size={16} />
            <Input
                {...rest}
                className={`border-0 focus-visible:ring-0 focus-visible:ring-ring flex-grow ${isPasswordVisible ? 'text-security-none' : 'text-security-disc'
                    }`}
            />
            <button
                type="button"
                onClick={togglePasswordVisibility}
                className="focus:outline-none"
            >
                {isPasswordVisible ? <EyeOff size={16} /> : <Eye size={16} />}
            </button>
        </div>
    )
}

export default PasswordInputWithIcon