import { ColumnDef } from "@tanstack/react-table";
import { Referral } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import Actions from "./Actions";
import { checkDecimal } from "src/helpers";
import dayjs from "dayjs";

export const columns: ColumnDef<Referral>[] = [
  {
    accessorKey: "srNo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sr.No. (#)" />
    ),
  },
  {
    accessorKey: "couponcode",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Coupon Code" />
    ),
  },
  {
    accessorKey: "type",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Type" />
    ),
  },
  {
    accessorKey: "discount_value",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Discount" />
    ),
    cell({ row: { original: { discount_type, discount_value } } }) {
      if (discount_type === "percentage") return discount_value + " %"
      else return "₹ " + checkDecimal((discount_value).toString(), true)
    },
  },

  {
    accessorKey: "expiry_date",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Expiry Date" />
    ),
    cell({ row: { original: { expiry_date } } }) {
      return expiry_date ? dayjs(expiry_date).format('DD/MM/YYYY') : null
    },
  },

  {
    id: "Actions",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actions" />
    ),
    cell({ row: { original } }) {
      return <Actions row={original} />
    },
  },
];
