import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger } from '../ui/select'

export type optionTypes = {
    label: string,
    value: string,

}

type Props = {
    options?: optionTypes[],
    placeholder?: string,
    value: string,
    onChange?: (e: any) => void
}

const Dropdown = ({ options, onChange, value }: Props) => {

    return (
        <Select value={value} onValueChange={(e: any) => {
            let value = e === "clear" ? "" : e
            onChange && onChange(value)
        }}  >
            <SelectTrigger onClear={() => onChange && onChange("")} className="w-[180px]">
                <div className=' flex justify-between items-center w-full'>
                    <div>{options?.find((option: optionTypes) => option.value === value)?.label || <div className='text-gray-400' >Select</div>} </div>
                </div>
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {options?.map(({ label, value }: optionTypes) => {
                        return <SelectItem value={value}>{label}</SelectItem>
                    })}
                </SelectGroup>
                {/* <SelectSeparator />
                <SelectGroup>
                    <SelectItem className='text-xs text-gray-500' value={"clear"}>Clear</SelectItem>
                </SelectGroup> */}
            </SelectContent>
        </Select>
    )
}

export default Dropdown