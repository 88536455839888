import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader
} from "src/shadcn/components/ui";
import RoundedButton from '../RoundedButton';

type Props = {
    isOpen: boolean;
    onSubmit?: () => void;
    onCancelClick?: () => void;
    description?: string;
    title?: string,
    btnTitle?: string
};

const ConfirmDialog = ({ isOpen, onSubmit,onCancelClick, description, title, btnTitle }: Props) => {

    return (
        <Dialog open={isOpen} onOpenChange={() => { }}>
            <DialogContent hideClose>
                <DialogHeader>
                    <DialogDescription>
                        <div className="flex flex-col items-center space-y-2">
                            <div className="flex space-x-2 items-center text-xl font-semibold ">
                                {title}
                            </div>
                            <div>
                                {description}
                            </div>
                            <div className='flex justify-center space-x-2 py-4'>
                                <RoundedButton onClick={onSubmit} >{btnTitle ? btnTitle : "Submit"}</RoundedButton>
                                <RoundedButton variant={'outlinePrimary'} onClick={onCancelClick} >Cancel</RoundedButton>
                            </div>
                        </div>
                    </DialogDescription>
                </DialogHeader>
            </DialogContent>
        </Dialog>

    )
}

export default ConfirmDialog