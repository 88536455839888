import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { catchAsync, IS_SUCCESS_RESPONSE, ROLES, successTheme } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { INIT_EPC_CONTRACTOR_PROFILE } from 'src/modules/EpcContractors/EpcProfile/modules'
import ManufacturerProfileForm from 'src/modules/Manufacturer/ManufacturerProfile/ManufacturerProfileForm'
import { createUserRequestApi } from 'src/services/requests/Auth'
import { createManufacturerProfileApiRequest } from 'src/services/requests/Manufacturers'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import { Separator, Tabs, TabsContent, TabsList, TabsTrigger } from 'src/shadcn/components/ui'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import CreateManufactureProduct from './CreateManufactureProduct'
import ManufacturerAllProducts from './ManufacturerAllProducts/ManufacturerAllProducts'

type Props = {}

const ManufacturerCreateMain = (props: Props) => {

    const [isLoading, setisLoading] = useState<boolean>(false)
    const [initFormDate, setinitFormDate] = useState(INIT_EPC_CONTRACTOR_PROFILE)
    const [resultData, setresultData] = useState<any>()
    const [allServices, setallServices] = useState<any>([])
    const [currentActiveTab, setcurrentActiveTab] = useState<string>("complete_profile")
    const navigate = useNavigate()
    const { currentUser } = useAuth()

    // useEffect(() => {
    //     if (currentUser?.email) {
    //         setinitFormDate({
    //             ...initFormDate,
    //             contractorEmail: currentUser?.email
    //         })
    //     }

    //     return () => { }
    // }, [])

    const handleCreateUser = catchAsync(async (formdata: any) => {
        setisLoading(true)
        return await createUserRequestApi({
            email: formdata?.manufacturerEmail,
            role: ROLES.MANUFACTURER
        })
    }, (result: any, formdata: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            console.log({ formdata });
            handleCreateProfile({ ...formdata, manufacturer_id: result?._id })
            setresultData({
                ...resultData,
                epc_id: result?._id,
            })
        }
        setisLoading(false)
    }, () => { setisLoading(false) })

    const handleCreateProfile = catchAsync(async (formdata: any) => {
        setisLoading(true)
        console.log({ formdata });
        return formdata && await createManufacturerProfileApiRequest({
            ...formdata,
            products: [],
            partnerType: "-",
            product_manufacturer: "-",
            manufacturerLogo: "-"
            // epc_contractor_id: currentUser?.id
        })
    }, (result: any) => {
        console.log({ result });
        if (IS_SUCCESS_RESPONSE(result)) {
            setresultData({
                ...resultData,
                _id: result?._id,
            })
            setcurrentActiveTab("add_services")
            toast.success("Profile created.", { style: successTheme })
            // navigate("/" + EPC_CONTRACTORS_ROUTES.dashboard.fullRoute)
        }
        setisLoading(false)
    }, () => {
        setisLoading(false)
    })

    console.log({ initFormDate });

    return (
        <div className='p-4'>
            <ScrollArea className='h-[90vh]'>
                <Tabs value={currentActiveTab} onValueChange={(e: any) => setcurrentActiveTab(e)} className="">
                    <TabsList>
                        <TabsTrigger disabled={resultData?._id && resultData?.epc_id} value="complete_profile">Profile</TabsTrigger>
                        <TabsTrigger disabled={!resultData?._id && !resultData?.epc_id} value="add_services">Products</TabsTrigger>
                        <TabsTrigger disabled={!resultData?._id && !resultData?.epc_id} value="all_products">All Products</TabsTrigger>
                    </TabsList>
                    <TabsContent value="complete_profile">
                        <ManufacturerProfileForm disableForm={resultData?._id} initial_values={initFormDate} submitBtnTitle='Save' onSubmit={(formdata: any) => handleCreateUser(formdata)} />
                    </TabsContent>
                    <TabsContent value="add_services">
                        <CreateManufactureProduct id={resultData?._id} onSuccess={() => setcurrentActiveTab("all_products")} />
                    </TabsContent>
                    <TabsContent value="all_products">
                        <ManufacturerAllProducts id={resultData?._id} />
                    </TabsContent>
                    
                </Tabs>

                <Separator />
            </ScrollArea>
            <LoadingDialog isOpen={isLoading} message="Profile updating..." />
        </div>
    )
}

export default ManufacturerCreateMain