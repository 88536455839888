import { useState } from 'react'
import { rooftop_init } from 'src/modules/Services/Rooftop/modules'
import RooftopForm from 'src/modules/Services/Rooftop/RooftopForm'

type Props = {}

const RooftopCreatePage = (props: Props) => {

  const [isLoading, setisLoading] = useState<boolean>(false)

  // const handleCreateRooftop = catchAsync((formData:any)=>{
  //   setisLoading(true)
  //   return await 
  // },(result:any)=>{
  //   if(IS_SUCCESS_RESPONSE(result)){
  //     setisLoading(false)
  //   }
  // },()=>{  setisLoading(false) })

  return (
    <RooftopForm isLoadingForm={false} submitBtnTitle='Save' initial_values={rooftop_init} onSubmit={()=>{}} />
  )
}

export default RooftopCreatePage