import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { catchAsync, checkAnyFileToBeUpload, FormField } from 'src/helpers'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { toast } from 'src/shadcn/components/ui/use-toast'
import { epcContractorProfileSchema, INIT_MANUFACTURER_PROFILE, MANUFACTURER_PROFILE_FORM_FIELDS } from './modules'

type Props = {
    initial_values: any,
    submitBtnTitle: string,
    onSubmit: (e: any) => void
    isLoadingForm?: boolean
    disableForm: boolean
}

const ManufacturerProfileForm = ({ initial_values, onSubmit, submitBtnTitle, isLoadingForm, disableForm }: Props) => {

    const [isFileUploading, setisFileUploading] = useState(false)
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: INIT_MANUFACTURER_PROFILE,
        validationSchema: epcContractorProfileSchema,
        onSubmit(values, formikHelpers) {
            handleSubmit()
        },
    })

    console.log({initial_values});
    

    useEffect(() => {
        if (initial_values) {
            Object.keys(INIT_MANUFACTURER_PROFILE).map((key: any) => {
                formik.setFieldValue(key, initial_values[key] || null)
            })
        }
        return () => { }
    }, [initial_values])


    const handleSubmit = catchAsync(async () => {
        setisFileUploading(true)
        let prepareObj: any = { ...formik.values }
        prepareObj = {
            ...prepareObj,
            products: prepareObj.products
        }
        return await checkAnyFileToBeUpload(prepareObj)
    }, (result: any) => {
        console.log({ result });
        if (result[1]) {
            // formik.setValues(result[0])
            onSubmit({ ...result[0] })
            console.log({ result: { ...result[0] } });
        }
        setisFileUploading(false)

    })

    const handleFormSubmit = () => {
        if (formik.isValid) {
            formik.handleSubmit()
        }
        else {
            Object.entries(formik.errors).map(([key, value]) => {
                formik.setFieldError(key, value as any)
                formik.setFieldTouched(key, true)
            })
            toast({
                variant: "destructive",
                title: "Form Validation Failed",
                description: "Please enter valid data"
            })
        }

    }

    // console.log(" Formik : ", { error: formik.errors, values: formik.values });

    return (
        <div>
            <form onSubmit={(e: any) => {
                e.preventDefault()
                handleFormSubmit()
            }}>
                <div className=' px-4 bg-gray-50'>
                    {/* <ScrollArea className=''> */}
                        <div className='grid grid-cols-4 gap-x-4 gap-y-2 my-2 '>
                            {Object.keys(INIT_MANUFACTURER_PROFILE).map((k: string) => {
                                const formfield: FormField | undefined = MANUFACTURER_PROFILE_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                                return formfield && <RenderFormComponents key={`create-manufacturer-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />
                            })}
                        </div>
                    {/* </ScrollArea> */}
                </div>
                <div className='my-6 flex justify-center space-x-2'>
                    <RoundedButton type='button' disabled={false} variant={'secondary'} onClick={() => navigate(-1)} >Back</RoundedButton>
                    <RoundedButton type='submit' disabled={disableForm}  >{submitBtnTitle}</RoundedButton>
                </div>
            </form>
            <LoadingDialog isOpen={isFileUploading} message='File Uploading...' />
        </div>
    )
}

export default ManufacturerProfileForm