import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { MANUFACTURER_ROUTES } from 'src/navigations/modules'
import CreateProductPage from './CreateProductPage'
import ManufacturerAllProducts from './ManufacturerAllProducts/ManufacturerAllProducts'
import UpdateProductDetailsPage from './UpdateProductDetailsPage'
import ViewProductDetailsPage from './ViewProductDetailsPage'

type Props = {}

const ManufacturerProductRoutes = (props: Props) => {
    const ROUTES = MANUFACTURER_ROUTES.products.subRoutes
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route index element={<ManufacturerAllProducts />} />
                <Route path={ROUTES.create.route} element={<CreateProductPage />} />
                <Route path={ROUTES.edit.route + "/:id"} element={<UpdateProductDetailsPage />} />
                <Route path={ROUTES.view.route + "/:id"} element={<ViewProductDetailsPage />} />
                <Route path={"*"} element={<Navigate to={"/" + ROUTES.view.fullRoute} />} />
            </Route>
        </Routes>
    )
}

export default ManufacturerProductRoutes