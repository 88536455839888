import { Outlet } from 'react-router-dom';
import './App.css';
import { AuthInit } from './modules/Auth/Core';
import { TooltipProvider } from './shadcn/components/ui';

function App() {
  return (
    <AuthInit>
      <TooltipProvider>
        <Outlet />
      </TooltipProvider>
    </AuthInit>
  );
}

export default App;
