import { Eye, Pencil } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { ROLES, STATUS } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { APP_ROUTES } from 'src/navigations/modules'
import ToolTip from 'src/shadcn/components/custom/ToolTip'
import { Button } from 'src/shadcn/components/ui'

type Props = {
    row: any
}

const Actions = ({ row }: Props) => {

    const navigate = useNavigate()
    const { currentUser } = useAuth()

    return (
        <div className='flex space-x-2'>
            {
                currentUser?.role && [ ROLES.ADMIN_USER].includes(currentUser.role) ?
                    <ToolTip tooltipContent='Edit project'>
                        <Button disabled={row.status != STATUS.New} size={"sm"} variant={'primaryIconBtn'} onClick={() => navigate("/" + APP_ROUTES.projects.subRoutes.edit.fullRoute + "/" + row._id)} >
                            <Pencil size="16" />
                        </Button>
                    </ToolTip>
                    : null
            }
            <ToolTip  tooltipContent='View project'>
                <Button size={"sm"} variant={'primaryIconBtn'} onClick={() => navigate("/" + APP_ROUTES.projects.subRoutes.view.fullRoute + "/" + row._id)} >
                    <Eye size="16" />
                </Button>
            </ToolTip>
        </div>
    )
}

export default Actions