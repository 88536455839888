import { useMemo } from 'react';
import { FormField } from 'src/helpers';
import FormikDateField from '../DateInpute/FormikDateField';
import FormikMUltiFIleUpload from '../FileUploadInput/FormikMUltiFIleUpload';
import FormikSingleFileUpload from '../FileUploadInput/FormikSingleFileUpload';
import FormikInput from '../InputeFiled/FormikInput';
import FormikPasswordFiled from '../PasswordField/FormikPasswordFiled';
import FormikSelectPicker2 from '../SelectPIcker2/FormikSelectPicker2';
import TextareaFormFiled from '../TextareaFormFiled';

interface Props extends FormField {
    value?: any,
    onChange?: (e: any) => void,
    onBlur?: (e: any) => void,
    onKeyDown?: (e: any) => void,
    formik?: any
}


const RenderFormComponents = ({ label, dataKey, componentType, componentProps, value, onChange, onBlur, formik, onKeyDown }: Props) => {
    // console.log({ componentProps });

    const isHidden = useMemo(() => {
        if (componentProps?.hide) {
            // console.log({ expectedValue : componentProps?.hide?.expectedValue , value : formik.values[componentProps?.hide?.formKey] });
            if (componentProps?.hide?.expectedValue === formik.values[componentProps?.hide?.formKey]) {
                return true
            }
            return false
        }
        else if (componentProps?.conditionalHide) {
            // console.log({ expectedValue : componentProps?.conditionalHide?.expectedValue , value :  formik.values[componentProps?.conditionalHide?.formKey] });
            if (Array.isArray(componentProps?.conditionalHide.expectedValue)) {
                return componentProps.conditionalHide.expectedValue.some((d: any) => d === formik.values[componentProps?.conditionalHide?.formKey as keyof object])
            }
            else if (componentProps?.conditionalHide?.expectedValue === formik.values[componentProps?.conditionalHide?.formKey]) {
                return true
            }
            return false
        }

        return true

    }, [componentProps?.hide, componentProps?.conditionalHide, formik.values,dataKey])

    // console.log({values :formik.values});





    return (
        <>
            {
                componentType === "input" && componentProps?.type && ['email', 'phone', 'text', 'url','number'].includes(componentProps?.type) && isHidden &&
                <FormikInput
                    formik={formik}
                    labelStyle={componentProps?.labelStyle}
                    label={label}
                    nameKey={dataKey}
                    placeholder={componentProps?.placeholder}
                    regex={componentProps?.regex}
                    prefix={componentProps?.inputPrefixIcon}
                    numberFormatted={componentProps?.numberFormatted}
                    sufix={componentProps?.inputSuffixIcon}
                    maxLength={componentProps?.maxLength}
                    value={value}
                    showCharacterLength={componentProps.showCharacterLength}
                    type={componentProps?.type}
                    required={componentProps?.required}
                    onchange={onChange}
                    onBlur={onBlur}
                    disabled={componentProps?.disabled}
                    readonly={componentProps?.readonly}
                    description={componentProps?.description}
                    options={componentProps?.options}
                    optionsPlaceholder={componentProps?.optionsPlaceholder}
                    onKeyDown={onKeyDown}
                />
            }
            {
                componentType === "input" && componentProps?.type && ['password'].includes(componentProps?.type) && isHidden &&
                <FormikPasswordFiled
                    formik={formik}
                    label={label}
                    labelStyle={componentProps?.labelStyle}
                    nameKey={dataKey}
                    placeholder={componentProps?.placeholder}
                    value={value}
                    required={componentProps?.required}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={componentProps?.disabled}
                />
            }
            {
                componentType === "file_picker" && componentProps?.fileSelectionType === 'multi' && isHidden &&
                <FormikMUltiFIleUpload
                    formik={formik}
                    label={label}
                    labelStyle={componentProps?.labelStyle}
                    nameKey={dataKey}
                    value={value}
                    required={componentProps?.required}
                    onChange={onChange} />
            }
            {
                componentType === "file_picker" && !componentProps?.fileSelectionType && isHidden &&
                <FormikSingleFileUpload
                    formik={formik}
                    label={label}
                    labelStyle={componentProps?.labelStyle}
                    nameKey={dataKey}
                    value={value}
                    required={componentProps?.required}
                    onchange={onChange}
                    placeholder={componentProps?.placeholder}
                    disabled={componentProps?.disabled || (componentProps?.conditionalDisabled ? formik.values[componentProps.conditionalDisabled.formKey] === componentProps.conditionalDisabled.expectedValue : false)} />
            }
            {
                componentType === "options" && isHidden && <div>
                    {/* <Label className={cn(' text-xs mb-2', componentProps?.labelStyle)}>{label}  {componentProps?.required ? <span className="text-red-600 font-semibold">*</span> : null} </Label>
                    <div className='mt-2'>
                        <Dropdown
                            options={componentProps?.options}
                            value={value || formik.values[dataKey] || ""}
                            onChange={onChange} /></div>
                    {formik.touched[dataKey] && formik.errors[dataKey] && (
                        <p className={cn("text-xs font-medium text-destructive")}>
                            {formik.errors[dataKey]}
                        </p>
                    )} */}
                    <FormikSelectPicker2
                        options={componentProps?.options || []}
                        required={componentProps?.required}
                        onchange={onChange}
                        formik={formik}
                        disabled={componentProps?.disabled}
                        label={label}
                        nameKey={dataKey}
                        value={value}
                        searchable={componentProps?.optionsSearchable || false}
                    />
                </div>
            }
            {
                componentType === "date_picker" && isHidden && <div>
                    <FormikDateField
                        formik={formik}
                        label={label}
                        labelStyle={componentProps?.labelStyle}
                        nameKey={dataKey}
                        value={value}
                        onchange={onChange}
                        placeholder={componentProps?.placeholder}
                        required={componentProps?.required}
                        minDate={componentProps?.disablePreviousDates}
                        maxDate={componentProps?.disableFeaturesDates}
                        disabled={componentProps?.disabled || (componentProps?.conditionalDisabled ? formik.values[componentProps.conditionalDisabled.formKey] === componentProps.conditionalDisabled.expectedValue : false)} />
                </div>
            }
            {
                componentType === "textarea" && isHidden && <div>
                    <TextareaFormFiled
                        formik={formik}
                        label={label}
                        labelStyle={componentProps?.labelStyle}
                        nameKey={dataKey}
                        value={value}
                        onChange={onChange}
                        placeholder={componentProps?.placeholder}
                        required={componentProps?.required}
                        disabled={componentProps?.disabled || (componentProps?.conditionalDisabled ? formik.values[componentProps.conditionalDisabled.formKey] === componentProps.conditionalDisabled.expectedValue : false)} />
                </div>
            }

        </>
    )
}

export default RenderFormComponents