import { AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { catchAsync, IS_SUCCESS_RESPONSE, successTheme } from 'src/helpers'
import { getTempDataRootState, resetEmailForValidatOtp, resetSession } from 'src/redux/reducers/tempDataSlice'
import { RootState } from 'src/redux/store'
import { resetPasswordRequestApi } from 'src/services/requests/Auth'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { RESET_PASSWORD_FORM_FIELDS, resetpassword_initialValues, ResetPasswordFormValidationSchema } from './modules'

type Props = {}

const ResetPassword = (props: Props) => {

    const [isLoading, setisLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { email_for_validate_otp, session } = useSelector((state: RootState) => getTempDataRootState(state))


    const formik = useFormik({
        initialValues: resetpassword_initialValues,
        validationSchema: ResetPasswordFormValidationSchema,
        onSubmit: (values, { setSubmitting }) => {
            // Handle form submission here
            handleResetPasswordForm()
        },
    });


    const handleResetPasswordForm = catchAsync(
        async () => {
            setisLoading(true)
            return await resetPasswordRequestApi({
                email: email_for_validate_otp,
                password: formik.values.password
            });
        },
        (result: AxiosResponse) => {
            if (IS_SUCCESS_RESPONSE(result)) {
                dispatch(resetEmailForValidatOtp())
                dispatch(resetSession())
                formik.resetForm()
                toast.success("Password reset successful, You can login now.", { style: successTheme })
                navigate("/")
            }
            setisLoading(false)
        },
        () => {
            setisLoading(false)
        }
    );

    return (
        <div className='mx-16 space-y-4'>
            <div className='space-y-2'>
                <div className='uppercase text-xl font-bold text-center text-primary'>Reset Password</div>
                <div className=' text-center text-sm'>Enter new password to log in </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className='space-y-2'>
                    {
                        RESET_PASSWORD_FORM_FIELDS.map((field: any) => {
                            return <RenderFormComponents key={`reset-password-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} />
                        })
                    }
                </div>
                <RoundedButton className='w-full ' onClick={() => { }} >Submit </RoundedButton>
            </form>
        </div>
    )
}

export default ResetPassword