import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { catchAsync } from 'src/helpers';
import { useAuth } from 'src/modules/Auth/Core';
import { dashboard_columns } from 'src/modules/EpcContractors/projects/AllProjects/Table/components/dashboard_columns';
import { DataTable } from 'src/modules/EpcContractors/projects/AllProjects/Table/components/data-table';
import { EPC_CONTRACTORS_ROUTES } from 'src/navigations/modules';
import { epcAllProjectsApiRequest } from 'src/services/requests/EPC/projects';
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area';
type Props = {}

const EPCProjectHistory = (props: Props) => {

    const [isLoading, setisLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const { currentUser } = useAuth()
    const [tableData, settableData] = useState([])

    useEffect(() => {
        handle_get_all_projects()
        return () => { }
    }, [])


    const handle_get_all_projects = catchAsync(
        async () => {
            return currentUser?.id && epcAllProjectsApiRequest(currentUser?.id)
        },
        (result: AxiosResponse) => {
            settableData(result.data)
        }
    );

    return (
        <div>
            <div className='flex justify-between my-2 mx-4'>
                <div className='text-primary uppercase font-bold text-sm'>Project History</div>
                <div className='text-gray-500 text-sm cursor-pointer hover:text-primary'><button onClick={() => navigate("/" + EPC_CONTRACTORS_ROUTES.projects.subRoutes.all.fullRoute)} >View more</button></div>
            </div>
            <div className='w-full' >
                <ScrollArea>
                    <DataTable data={tableData || []} columns={dashboard_columns} />
                </ScrollArea>
            </div>
        </div>
    )
}

export default EPCProjectHistory