import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";


export const columns: ColumnDef<Task>[] = [
  {
    accessorKey: "srNo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sr.No. (#)" />
    ),
  },
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
  },
  {
    accessorKey: "phonenumber",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Phone Number" />
    ),
  },
  {
    accessorKey: "service_type",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Service Type" />
    ),
  },
  {
    accessorKey: "createdAt",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Date" />
    ),
    cell({ row: { original: { createdAt } } }) {
      return <div>{dayjs(createdAt).format('DD/MM/YYYY')}</div>
    },
  },

  // {
  //   id: "Actions",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Actions" />
  //   ),
  //   cell({ row: { original } }) {
  //     return <Actions row={original} />
  //   },
  // },

];
