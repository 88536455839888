import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import AllLandOnbordingRequestsMainPage from './AllRequests/AllLandOnbordingRequestsMainPage'
import LandOnboadingCreatePage from './LandOnboadingCreatePage'
import LandOnboardingUpdatePage from './LandOnboardingUpdatePage'
import LandOnboardingViewPage from './LandOnboardingViewPage'

type Props = {}

const LandOnboardingAdminRoutes = (props: Props) => {
    const ROUTES = APP_ROUTES.landOnboard.subRoutes

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route index element={<AllLandOnbordingRequestsMainPage />} />
                <Route path={ROUTES.all.route} element={<AllLandOnbordingRequestsMainPage />} />
                <Route path={ROUTES.create.route} element={<LandOnboadingCreatePage />} />
                <Route path={ROUTES.edit.route + "/:id"} element={<LandOnboardingUpdatePage />} />
                <Route path={ROUTES.view.route + "/:id"} element={<LandOnboardingViewPage />} />
                <Route path={"*"} element={<Navigate to={"/" + APP_ROUTES.landOnboard.fullRoute} />} />
            </Route>
        </Routes>
    )
}

export default LandOnboardingAdminRoutes