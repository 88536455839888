import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import { Task } from '../data/schema'

type Props = {
    row: Task
}

const ProjectName = ({ row }: Props) => {

    const navigate = useNavigate()

    return (
        <div>
            <button onClick={() => navigate("/" + APP_ROUTES.projects.subRoutes.view.fullRoute + "/" + row._id)} className='hover:text-blue-500 underline underline-offset-2'> {row.projectName}</button>
        </div>
    )
}

export default ProjectName