import { ChangeEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { catchAsync, convertArrayToOptionType, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { useQueryResponse } from 'src/pages/ProjectEnquiry/ScheduleCallRequests/core/QueryResponseProvider'
import { updateScheduleCallRequestApiRequest } from 'src/services/requests/ProjectEnquiry'
import CustomeDialog from 'src/shadcn/components/custom/CustomeDialog'
import Dropdown from 'src/shadcn/components/custom/Dropdown'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import { Button, Label, Textarea } from 'src/shadcn/components/ui'
import { Task } from '../data/schema'

type Props = {
    row: Task
}

const Actions = ({ row }: Props) => {

    const navigate = useNavigate()
    const { currentUser } = useAuth()
    const { refetch } = useQueryResponse()
    const [openDialog, setopenDialog] = useState<boolean>(false)
    const [remark, setremark] = useState<string>('')
    const [remarkError, setRemarkError] = useState<string>('')
    const [status, setstatus] = useState<string>('')
    const [statusError, setStatusError] = useState<string>('')
    const [isLoading, setisLoading] = useState<boolean>(false)

    const handleUpdateRemark = catchAsync(async (status: string) => {
        setisLoading(true)
        return await updateScheduleCallRequestApiRequest({
            "_id": row._id,
            "call_status": status
        })
    }, (result: any) => {
        setisLoading(false)
        if (IS_SUCCESS_RESPONSE(result)) {
            setopenDialog(false)
            refetch()
        }
    }, () => {
        setisLoading(false)
    })

    const handleValidateForm = () => {
        let remarkCheck = false
        let statusCheck = false

        if (remark) {
            if (remark.length <= 250) {
                remarkCheck = true
            }
            else setRemarkError("Max length 250 characters only")
        } else setRemarkError("Remark required")

        if (status) {
            statusCheck = true
        }
        else setStatusError("Select Status")

        return remarkCheck && statusCheck

    }

    return (
        <div className='flex space-x-2'>

            {/* <ToolTip tooltipContent='View project'>
                <Button size={"sm"} variant={'primaryIconBtn'} onClick={() => navigate("/" + APP_ROUTES.projects.subRoutes.view.fullRoute + "/" + row._id)} >
                    <Eye size="16" />
                </Button>
            </ToolTip> */}
            <Button variant={'outlinePrimary'} size={'xs'} onClick={() => setopenDialog(true)} >Comment</Button>
            <CustomeDialog className='md:max-w-sl' isOpen={openDialog} submitBtnTitle='Submit' headerTitle={"Remark"} onCancle={() => setopenDialog(false)}
                onSubmit={() => {
                    if (handleValidateForm()) {
                        handleUpdateRemark(status)
                    }
                }}
            >
                <div className='pb-4'>
                    <Label className='pb-2'>Comment </Label>
                    <Textarea maxLength={250} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                        setRemarkError("")
                        setremark(e.target.value)
                    }} ></Textarea>
                    {remarkError && <p className='text-xs text-destructive '>{remarkError}</p>}
                    <Label className='pb-2'>Status </Label>

                    <Dropdown options={convertArrayToOptionType(["ACCEPT", "REJECTED", "INTERESTED", "NOT-INTERESTED"])} value={status} onChange={(e: any) => {
                        setStatusError("")
                        setstatus(e)
                    }} />
                    {statusError && <p className='text-xs text-destructive '>{statusError}</p>}
                </div>
            </CustomeDialog>
            <LoadingDialog isOpen={isLoading} />

        </div>
    )
}

export default Actions