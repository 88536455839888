import { useFormik } from 'formik'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { catchAsync, checkAnyFileToBeUpload, FormField } from 'src/helpers'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import { IProjectRightsForm, INIT_PROJECT_RIGHTS, PROJECT_RIGHTS_FORM_FIELDS, PROJECT_RIGHTS_VALIDATION_SCHEMA, PROJECT_FORM_STRUCTURE } from './modules'
import { ImageUp } from 'lucide-react'

type Props = {
    initial_values: any,
    submitBtnTitle: string,
    onSubmit: (e: any) => void
    isLoadingForm?: boolean
}

const ProjectRIghtsForm = ({ initial_values, onSubmit, submitBtnTitle, isLoadingForm }: Props) => {

    const navigate = useNavigate()
    const imgUrlFileRef = useRef<HTMLInputElement>(null)
    const [imageData, setimageData] = useState<any>(null)
    const [isLoading, setisLoading] = useState(false)
    const [isLoadingProjectCalculation, setisLoadingProjectCalculation] = useState(false)

    const formik = useFormik<IProjectRightsForm>({
        initialValues: { ...INIT_PROJECT_RIGHTS },
        validationSchema: PROJECT_RIGHTS_VALIDATION_SCHEMA,
        onSubmit(values, formikHelpers) {
            handleSubmit()
        },
    })

    useEffect(() => {
        if (initial_values) {
            Object.keys(INIT_PROJECT_RIGHTS).map((key: any) => {
                formik.setFieldValue(key, initial_values[key] || null)
            })
            // formik.setValues(initial_values)
        }
        return () => { }
    }, [initial_values])


    const handleSubmit = catchAsync(async () => {
        setisLoading(true)
        return await checkAnyFileToBeUpload(formik.values)
    }, (result: any) => {
        console.log({ result });
        if (result[1]) {
            formik.setValues(result[0])
            onSubmit(result[0])
        }
        setisLoading(false)

    })

    const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
        // console.log({ files: event.target.files });
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setimageData(e.target?.result as string);
            };
            reader.readAsDataURL(file);
            formik.setFieldValue("imgUrl", file);
        }
        else {
            setimageData("");
            formik.setFieldValue("imgUrl", "");
        }
        // form.setValue("employee_profile_photo", file);
    };

    console.log({ initial_values, error: formik.errors, values: formik.values, touched: formik.touched });

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className='grid grid-cols-6'>
                    <ScrollArea className='col-span-4 h-[80vh] px-4 bg-gray-50/10'>
                        {
                            Object.entries(PROJECT_FORM_STRUCTURE).map(([key, value]) => {
                                return <div key={`PROJECT_FORM_STRUCTURE-${key}`} className='mb-2' >
                                    <div className='text-primary uppercase'>{key}</div>
                                    <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                                        {
                                            Object.keys(value).map((k: any) => {
                                                const formfield: FormField | undefined = PROJECT_RIGHTS_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                                                if(formfield?.componentProps?.fileSelectionType === "multi"){
                                                    return <div className='col-span-3'>
                                                        {formfield && <RenderFormComponents key={`sales-executive-site-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue((formfield.dataKey).trim(), e)} />}
                                                    </div>
                                                }
                                                return formfield && <RenderFormComponents key={`sales-executive-site-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue((formfield.dataKey).trim(), e)} />
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </ScrollArea>
                    <div className='col-span-2 p-4'>
                        <input hidden ref={imgUrlFileRef} type='file' name={'imgUrl'} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue("imgUrl", e.target.files && e.target.files.length > 0 ? e.target.files[0] : null)
                            handleImageUpload(e)
                        }} />
                        {
                            formik.values.imgUrl ?
                                <img src={imageData || formik.values.imgUrl} className='mx-auto cursor-pointer rounded-xl ' onClick={() => {
                                    imgUrlFileRef.current?.click()
                                }} style={{ width: "300px", height: "300px" }} />
                                : <div className='space-y-2'>
                                    <div className='w-[300px] h-[300px] bg-gray-100 mx-auto rounded-xl'>
                                        <button onClick={() => {
                                            imgUrlFileRef.current?.click()
                                        }} type='button' className='w-full h-full'>
                                            <div className='w-full h-full flex justify-center items-center'>
                                                <div className='flex flex-col items-center space-y-2'>
                                                    <ImageUp size={32} className='text-gray-400' />
                                                    <span className='text-sm text-gray-600'> Upload Land Image</span>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                    {formik.errors.imgUrl && formik.touched.imgUrl ? <div className=' text-destructive text-xs text-center'>{formik.errors.imgUrl}</div> : null}
                                </div>
                        }
                    </div>
                </div>
                <div className='my-6 flex justify-center space-x-2'>
                    <RoundedButton type='button' disabled={isLoading || isLoadingForm} variant={'secondary'} onClick={() => navigate(-1)} >Back</RoundedButton>
                    <RoundedButton type='submit' disabled={isLoading || isLoadingForm}  >{submitBtnTitle}</RoundedButton>
                </div>
            </form>
            <LoadingDialog isOpen={isLoadingProjectCalculation} />
        </div>
    )
}

export default ProjectRIghtsForm