import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState
} from "@tanstack/react-table";
import * as React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/shadcn/components/ui";

import { useNavigate } from "react-router-dom";
import { cn, ROLES } from "src/helpers";
import { useAuth } from "src/modules/Auth/Core";
import { APP_ROUTES } from "src/navigations/modules";
import { DataTablePagination } from "./data-table-pagination";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}

export function DataTable<TData, TValue>({ columns, data }: DataTableProps<TData, TValue>) {

  const [rowSelection, setRowSelection] = React.useState({});
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <div className="space-y-4h-full">
      {/* <ScrollArea className=" h-[80vh]"> */}
      <div className="rounded-md border  h-full  ">
        <Table rootClassName={table.getState().pagination.pageSize > 10 ? "h-[70vh]" : ""} >
          <TableHeader className="sticky top-0 left-0 right-0 bg-slate-100 shadow-sm ">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow  className="min-w-full" key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead  className="w-1/12"  key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => {
                const original: any = row.original;
                return (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className={cn("", { "hover:bg-gray-50 cursor-pointer": currentUser && currentUser?.role === ROLES.ADMIN_USER })}
                    onClick={() => {
                      if (currentUser && currentUser?.role === ROLES.ADMIN_USER) {
                        navigate("/" + APP_ROUTES.epc_contractors.subRoutes.view.fullRoute + "/" + original?.epc_contractor_id)
                      }
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      // console.log("finding penny",cell)
                      return (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
      {/* </ScrollArea> */}
    </div>
  );
}
