import { ColumnDef } from "@tanstack/react-table";
import { Task } from "../data/schema";
import Actions from "./Actions";
import { DataTableColumnHeader } from "./data-table-column-header";



export const columns: ColumnDef<Task>[] = [
  {
    accessorKey: "srNo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sr.No. (#)" />
    ),
  },
  {
    id: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    cell({ row: { original: { firstName, lastName } } }) {
      return <div>{firstName} {lastName}</div>
    },
  },
  {
    accessorKey: "email",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Email" />
    ),
  },
  {
    accessorKey: "role",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Role" />
    ),
  },

  {
    accessorKey: "mobileNumber",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Mobile Number" />
    ),
  },
  {
    accessorKey: "countOfProjects",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Projects (#)" />
    ),
  },
  {
    id: "Actions",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actions" />
    ),
    cell({ row: { original } }) {
      return <Actions row={original} />
    },
  },

];
