import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import LandOnbordForm from 'src/modules/LandOnbaordRequests/LandOnbordForm'
import { INITIAL_LAND_ONBOARD_FORM_DATA } from 'src/modules/LandOnbaordRequests/modules'
import { ApiResponse } from 'src/services/consts/api_const'
import { getLandRegistrationDetailApiRequest, updateLandRegistrationApiRequest } from 'src/services/requests/LandRegistration'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'

type Props = {}

const LandOnboardingUpdatePage = (props: Props) => {
    const [formData, setformData] = useState(INITIAL_LAND_ONBOARD_FORM_DATA)
    const [fetchingUser, setfetchingUser] = useState(false)
    const [updatingUser, setupdatingUser] = useState(false)
    const { id } = useParams()
    const { currentUser } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (!id) navigate(-1)
        else handleFetchLandRegistrationRequestById()
        return () => { }
    }, [id])


    const handleUpdateLandRegistrationRequest = catchAsync(async (frmData: any) => {
        setupdatingUser(true)
        return await formData && currentUser && updateLandRegistrationApiRequest({ ...frmData, _id: id })
    }, (result: ApiResponse) => {
        console.log({ result })
        if (IS_SUCCESS_RESPONSE(result)) {
            navigate(-1)
        }
        setupdatingUser(false)
    }, () => {
        setupdatingUser(false)
    })

    const handleFetchLandRegistrationRequestById = catchAsync(async () => {
        if (id) {
            setfetchingUser(true)
            return await getLandRegistrationDetailApiRequest(id)
        }
    }, (result: ApiResponse) => {
        console.log({ result })
        if (IS_SUCCESS_RESPONSE(result)) {
            setformData(result.data)
        }
        setfetchingUser(false)
    }, () => {
        setfetchingUser(false)
    })


    console.log({formData});
    

    return (
        <div>
            <LandOnbordForm initial_values={formData} submitBtnTitle='Submit' isLoadingForm={updatingUser || fetchingUser} onSubmit={(e: any) => handleUpdateLandRegistrationRequest(e)} />
            <LoadingDialog isOpen={fetchingUser} message='Fetching Land User Details' />
            <LoadingDialog isOpen={updatingUser} message='Updating Land User Details' />
        </div>
    )
}

export default LandOnboardingUpdatePage