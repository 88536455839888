import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { initSalesExecutive } from 'src/modules/SalesExecutive/SalesExecutives/modules'
import SalesExectiveReviewForm from 'src/modules/SalesExecutive/SalesExecutives/SalesExectiveReviewForm'
import { ApiResponse } from 'src/services/consts/api_const'
import { getSalesExecutiveDetailsByIDApiRequest } from 'src/services/requests/SalesExecutve'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}

const ViewSalesExecutive = (props: Props) => {


  const [formData, setformData] = useState(initSalesExecutive)
  const [isLoading, setisLoading] = useState(false)
  const { id } = useParams()
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!id) navigate(-1)
    else handleFetchSalesExecutiveById()
    return () => { }
  }, [id])

  const handleFetchSalesExecutiveById = catchAsync(async () => {
    if (id) {
      setisLoading(true)
      return await getSalesExecutiveDetailsByIDApiRequest(id)
    }
  }, (result: ApiResponse) => {
    console.log({ result })
    if (IS_SUCCESS_RESPONSE(result)) {
      setformData(result.data)
    }
    setisLoading(false)
  }, () => {
    setisLoading(false)
  })

  return (
    <div>
      <SalesExectiveReviewForm data={formData} />
      <div>
        <RoundedButton onClick={() => navigate(-1)} >Back</RoundedButton>
      </div>
    </div>
  )
}

export default ViewSalesExecutive