import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AnalysisTiles from '../Dashboard/AnalysisTiles'
import { EPC_CONTRACTOR_ANALYSIS_DATA } from './modules'

type Props = {}

const EPCContractorDashboardSummary = (props: Props) => {

  const [data, setdata] = useState(EPC_CONTRACTOR_ANALYSIS_DATA)

  const navigate = useNavigate()

  useEffect(() => {
    // handleFetchSummaryDetails()
    return () => { }
  }, [])

  // const handleFetchSummaryDetails = catchAsync(async () => await getAdminDashboardSummaryRequestApi(), (result: IAdminDashboardSummaryResponse) => {
  //   if (IS_SUCCESS_RESPONSE(result)) {
  //     setdata(data.map((d: any) => {
  //       return {
  //         ...d,
  //         value: result.data[d.dataKey as keyof object] || 0
  //       }
  //     }))
  //   }
  // }, () => { })



  return (
    <div className=''>
      <div className='my-2 flex justify-between'>
        <div className='font-semibold text-primary uppercase'>Reports Summary</div>
        {/* <button onClick={() => navigate("/" + APP_ROUTES.sales_executive.subRoutes.reports.subRoutes.all.fullRoute)} className='underline underline-offset-2 text-gray-500 hover:text-primary text-sm cursor-pointer mx-4'>View More</button> */}
      </div>
      <div className={`mt-4 grid gap-4 md:grid-cols-3 lg:grid-cols-3`}>
        {
          EPC_CONTRACTOR_ANALYSIS_DATA.map((d: any) => {
            return <AnalysisTiles {...d} />
          })
        }
      </div>

    </div>
  )
}

export default EPCContractorDashboardSummary